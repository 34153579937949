<template>
  <el-popconfirm 
    title="Вы действительно хотите удалить?" 
    confirm-button-text="Да"
    cancel-button-text="Отменить"
    cancel-button-type="primary"
    width="300px"
    class="delete-district"
    @confirm="fetchRemove"
  >
    <template #reference>
      <el-button 
        :disabled="isDelete"
        :loading="isLoading"
        type="danger" 
        plain
      >
        {{ !isDelete ? 'Удалить' : 'Удалено' }}
      </el-button>
    </template>
  </el-popconfirm>
</template>

<script lang="ts" setup>
import {
  ref, defineProps, PropType,
} from 'vue';
import { ElMessage } from 'element-plus';
import { removeDistrict } from '../requests';

const props = defineProps({
  modelValue: {
    type: Object as PropType<string>,
    required: true,
  },
}); 

const isLoading = ref(false);
const isDelete = ref(false);

const fetchRemove = async () => {
  isLoading.value = true;

  try {
    const result = await removeDistrict({ id: props.modelValue, data: {}, isNew: false });

    if (result.error) {
      ElMessage({
        message: 'Не удалось удалить район',
        type: 'error',
      });

      return;
    }

    isDelete.value = true;

    ElMessage({
      message: 'Район успешно удален',
      type: 'success',
    });
  }
  catch (error) {
    console.error(error);
  }
  finally {
    isLoading.value = false;
  }
};
</script>

<style>
.el-popper {
    color: white !important;
}
</style>
