import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { bannersT } from './index';
import {
  get, create, update, remove, 
} from './requests';
import type { Read } from './types';
import bannersTransition from './components/banners-transition.vue';

export const cardConfig = cardPageController<Read>({
  header: {
    title: bannersT('card.title'),
  },

  getRequest: get,
  updateRequest: update as any,
  createRequest: create as any,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'banners' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route): any => ({ name: 'banners-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          class: 'width-auto',
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                /** Title */
                {
                  type: 'input',
                  name: 'title',
                  label: bannersT('card.form.title.title'),
                  dataType: 'string',
                  hint: bannersT('card.form.title.hint'),
                  props: {
                    readOnly: true,
                  },
                },
                /** Sort */
                {
                  type: 'input',
                  name: 'sort',
                  label: bannersT('card.form.sort.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                /** City */
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: baseT('label.city'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.name,
                  },
                },
                /** Date */
                {
                  type: 'datetime',
                  name: 'date',
                  label: bannersT('card.form.date.title'),
                  dataType: 'date',
                  props: {
                    type: 'datetimerange',
                    startPlaceholder: 'От',
                    endPlaceholder: 'До',
                    format: 'YYYY-MM-DD HH:mm',
                    rangeSeparator: '–',
                    disabled: true,
                  },
                }, 
                /** Transition */
                {
                  type: 'custom',
                  name: 'transition',
                  label: bannersT('card.form.transition.title'),
                  props: {
                    readOnly: true,
                  },
                  component: () => bannersTransition,
                },
                // },
                /** Link */
                {
                  type: 'input',
                  name: 'link',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    hidden: ({ state }) => state.transition === 'app',
                  },
                },
                /** App */

                {
                  type: 'select',
                  name: 'link',
                  options: [],
                  dataType: 'string',
                  props: {
                    hidden: ({ state }) => state.transition !== 'app',
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
                {
                  type: 'dropzone',
                  label: bannersT('card.form.image.title'),
                  name: 'image',
                  props: {
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    readOnly: true,
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                  },
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [],
        },
      ],
    },
  },
});
