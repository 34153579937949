<template>
  <div style="min-width: 160px !important;">
    <el-select
      v-model="model"
      disabled
      :class="className"
      @click.prevent
      @change="onChange"
    >
      <el-option
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
      
<script lang="ts" setup>
import {
  defineProps, PropType, ref, computed, watch,
} from 'vue';
    
import { statuses } from '~/utils/list';
import type { List, CityStatus } from '../types';
import { updateStatus } from '../requests';
    
const props = defineProps({
  row: {
    type: Object as PropType<List>,
    required: true,
  },
});
      
const model = ref(props.row.status.value);
      
const items = ref(statuses);
      
const className = computed(() => (model.value === 'active' ? 'status-green' : 'status-red'));
    
const onChange = async (value: CityStatus) => {
  await updateStatus({ id: props.row.id, data: { status: value }, isNew: false });
};
    
watch(
  () => props.row.status, 
  () => {
    model.value = props.row.status.value;
  },
);
</script>
