import { ft } from '~/utils/feature-translate';

export const OrderRu = {
  titles: {
    table_title: 'Заказы',
    card_title: 'Заказ',
  },
  table: {
    title: 'Заказы',
    not_found: 'Заказы не найдены',
    remove_many: 'Удалить выбранное',
    addNew: 'Добавить',
    export_title: {
      walker: 'Экспорт выгульщиков',
      walking: 'Экспорт выполненных заказов',
    },
    headers: {
      id: 'ID',
      externalId: 'Внешний ID',
      status: 'Статус',
      createdAt: 'Дата создания',
      paymentState: 'Статус оплаты',
      asap: 'Срочность',
      walkDate: 'Дата прогулки',
      walkTime: 'Время прогулки',
      walkCity: 'Город прогулки',
      walkDistrict: 'Район прогулки',
      client: 'Клиент',
      walk: 'Прогулка',
      extra: 'Дополнительно',
      totalCost: 'Итоговая стоимость',
      petBreeds: 'Породы питомцев',
      petAge: 'Возраст питомцев',
      weight: 'Вес',
      walker: 'Выгульщик',
      orderSource: 'Источник заказа',
      mark: 'Оценка',
      markDescription: 'Метки оценки',
    },
  },
  filters: {
    allFilters: 'Все фильтры',
    hideFilters: 'Скрыть фильтры',
    id: 'ID',
    externalId: 'Внешний ID',
    asap: 'Срочная прогулка',
    walkDate: 'Дата прогулки',
    city: 'Город',
    districts: 'Район',
    userName: 'Имя клиента',
    userPhone: 'Телефон клиента',
    paymentStatus: 'Статус оплаты',
    walkingTypes: 'Тип прогулки',
    walkingTypePriceFrom: 'Стоимость прогулки (от)',
    walkingTypePriceTo: 'Стоимость прогулки (до)',
    walkingOptions: 'Дополнительные опции',
    priceFrom: 'Общая стоимость (от)',
    priceTo: 'Общая стоимость (до)',
    petBreeds: 'Порода',
    petAgeFrom: 'Возраст питомца, от (года)',
    petAgeTo: 'Возраст питомца, до (года)',
    weightFrom: 'Вес питомца, от (кг)',
    weightTo: 'Вес питомца, до (кг)',
    walkerPhone: 'Телефон выгульщика',
    walkerName: 'Имя выгульщика',
    ratingFrom: 'Рейтинг (от)',
    ratingTo: 'Рейтинг (до)',
    orderStatus: 'Статус заказа',
    source: 'Источник',
  },
  form: {
    title: 'Заказ',
    fields: {
      externalId: 'Внешний ID',
      walkDate: 'Дата прогулки',
      walkTime: 'Время прогулки',
      urgent: 'Срочная',
      city: 'Город',
      orderNumber: 'Номер заказа',
      status: 'Статус',
      payment: {
        label: 'Способ оплаты',
        placeholder: 'Выбрать',
        card_in_app: 'Картой в приложении',
      },
      paymentState: 'Статус оплаты',
      reason: 'Причина отмены',
      source: {
        label: 'Источник заказа',
        placeholder: 'Выбрать',
        app: 'В приложении',
        bot: 'В боте',
        site: 'На сайте',
        phone: 'По телефону',
        email: 'По e-mail',
      },
      communication: {
        label: 'Способ связи',
        placeholder: 'Выбрать',
        push: 'PUSH-уведомление',
        call: 'Звонок',
      },
      distance: 'Дистанция',
      startPlan: 'Старт по плану',
      finishPlan: 'Финиш по плану',
      walkingTime: 'Время прогулки',
      startPlanFact: 'Старт по факту',
      finishPlanFact: 'Финиш по факту',
      walkingTimeFact: 'Время прогулки по факту',
      commentForClient: 'Комментарий выгульщика для клиента',
      commentForOwn: 'Комментарий выгульщика для своих',
      images: 'Фотоотчет',
      map: 'Чем занимался хвостик на прогулке?',
      after_walks: 'После прогулки',
      reportUrl: 'Ссылка на отчет кинолога',
    },
    orderList: {
      title: 'Услуги',
      fields: {
        walkType: 'Тип прогулки',
        price: 'Стоимость',
        total: 'Итого',
        extra: 'Дополнительно',
        discountsApplied: 'Применены скидки',
        totalCost: 'Cтоимость заказа',
        orderDiscount: 'Скидка на заказ',
        totalPriceWDiscount: 'Итого со скидкой',
        amountDiscount: 'Cкидка',
        comment: 'Комментарий',
      },
    },
    pets: {
      title: 'Питомцы на прогулке',
      fields: {
        name: 'Имя',
        namePlaceholder: 'Жорж',
        breed: 'Порода',
        breedPlaceholder: 'Мопс',
        age: 'Возраст',
        agePlaceholder: '1 г',
        weight: 'Вес',
        weightPlaceholder: '12 кг',
        gender: 'Пол',
        genderPlaceholder: 'Мальчик',
        peculiarities: 'Особенности',
        comment: 'Комментарий',
      },
    },
    payments: {
      title: 'Оплаты',
      fields: {
        number: 'Номер счета',
        amount: 'К оплате',
        status: 'Статус',
        createdAt: 'Счет создан',
        expireAt: 'Счет действителен до',
        paymentLink: 'Ссылка на оплату',
      },
    },
    mark: {
      title: 'Оценка',
      fields: {
        markDescription: 'Метки оценки',
        comment: 'Комментарий',
      },
    },
    walker: {
      title: 'Выгульщик',
      fields: {
        phone: 'Телефон',
        name: 'Имя',
        surname: 'Фамилия',
      },
    },
    address: {
      title: 'Адрес',
      fields: {
        city: 'Город',
        district: 'Район',
        street: 'Улица',
        house: 'Дом',
        porch: 'Подъезд',
        floor: 'Этаж',
        flat: 'Квартира/офис',
        intercom: 'Домофон',
        comment: 'Комментарий',
      },
    },
    client: {
      title: 'Клиент',
      fields: {
        phone: 'Телефон',
        name: 'Имя',
        surname: 'Фамилия',
      },
    },
    contact: {
      title: 'Контакт',
      fields: {
        phone: 'Телефон',
        name: 'Имя',
        surname: 'Фамилия',
      },
    },
  },
  cancel_walking: {
    success_notify: 'Отмена заказа прошла успешно!',
  },
};

export const t = ft(OrderRu, 'orders.');
