import { ft } from '~/utils/feature-translate';

export const BannersRu = {
  sidebar: {
    table_title: 'Баннеры на главной',
    card_title: 'Баннер на главной',
  },
  table: {
    title: 'Баннеры на главной',
    not_found: 'Баннеров на главной не найдены',
    headers: {
      title: 'Название служебное',
      sort: 'Порядок сортировки',
      city: 'Город',
      date: 'Срок действия',
      transition: 'Переход',
    },
  },
  filters: {
    title: 'Название служебное',
    city: 'Город',
    transition: 'Переход',
    date: 'Срок действия',
  },

  card: {
    title: 'Баннер на главной',

    form: {
      title: {
        title: 'Название служебное',
        placeholder: 'Введите название',
        hint: 'Служебное, пользователям не видно.',
      },
      sort: {
        title: 'Порядок сортировки',
        placeholder: 'Введите номер',
      },
      city: {
        title: 'Город',
        placeholder: 'Выберите город',
      },
      date: {
        title: 'Срок действия',
        placeholder: 'Выберите город',
      },
      transition: {
        title: 'Переход',
        link: 'По ссылке',
        app: 'Внутри приложения',
      },
      link: {
        placeholder: 'Введите ссылку',
      },
      app: {
        placeholder: 'Выберите услугу или экран для перехода',
      },
      image: {
        title: 'Баннер',
      },
    },
  },
};

export const t = ft(BannersRu, 'content.banners.');
