import { TableFiltersData } from 'magner/dist/types/configs/pages/table';

export const filterURLBuilder = (data: TableFiltersData, indx: number, prop: string) => {
  const res = [`&filters[${indx}][id]=${prop}`];

  for (const i in data.filters[prop]) {
    const f = `&filters[${indx}][value][${i}]=${data.filters[prop][i]}`;
    res.push(f);
  }

  return res.join('');
};
