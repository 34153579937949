import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { citiesT } from './index';
import {
  get, create, update, 
} from './requests';
import CitiesMap from './components/cities-map.vue';
import type { Read } from './types';

export const cardConfig = cardPageController<Read>({
  header: {
    title: citiesT('card.title'),

    tabs: [
      {
        label: 'Карточка',
        link: (route) => ({ name: 'cities-view', params: { id: route.params.id } }),
        active: true,
      },
      {
        label: 'Рабочие зоны',
        link: (route) => ({ name: 'cities-district-view', params: { id: route.params.id } }),
        active: false,
      },
    ],
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'cities' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route): any => ({ name: 'cities-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          class: 'width-auto',
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** City, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: citiesT('card.form.city.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'select',
                  name: 'status',
                  label: citiesT('card.form.status'),
                  dataType: 'string',
                  options: [],
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
              ],
            },
            /** Telegram */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'telegram',
                  label: citiesT('card.form.telegram.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
            /** Phone */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'supportPhone',
                  label: citiesT('card.form.phone.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {},
          layout: [
            {
              type: 'column',
              title: citiesT('card.form.map'),
              props: {
                span: 24,
                xs: 24,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'id',
                  component: () => CitiesMap,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
