<template>
  <div class="el-col el-col-24 generic-form_block-column el-card is-always-shadow">
    <h2 class="custom-label">{{ user?.existingEntity ? 'Выгульщик' : 'Выгульщик еще не назначен'}} </h2>

    <div class="user-select">
      <div
        class="user-select__blocks"
      >
        <div>
          <p>Телефон</p>
          <MagnerFormInput
            :key="user.existingEntity?.phone || 'phone'"
            :model-value="user.existingEntity?.phone || ''"
            :field="fields.phoneReadonly"
          />
        </div>
        <div>
          <p>Имя</p>
          <MagnerFormInput
            :key="user.existingEntity?.name || 'name'"
            :model-value="user.existingEntity?.name"
            :field="fields.nameReadonly"
          />
        </div>
        <div>
          <p>Фамилия</p>
          <MagnerFormInput
            :key="user.existingEntity?.surname || 'surname'"
            :model-value="user.existingEntity?.surname"
            :field="fields.surnameReadonly"
          />
        </div>
      </div>

      <div v-if="!readOnly">
        <div class="user-select__blocks-flex  user-select__blocks_big-padding">
          <div class="user-select__blocks-subtitle">Автоматическое назначение</div>

          <el-tooltip>
            <template #content>Автоматическое назначение</template>

            <el-icon color="rgba(223, 226, 232, 1)">
              <QuestionFilled />
            </el-icon>
          </el-tooltip>

          <div>
            <el-button type="primary" plain disabled>Разослать выгульщикам</el-button>
          </div>
        </div>

        <div class="user-select__blocks-flex ">
          <div class="user-select__blocks-subtitle">Ручное назначение</div>

          <el-tooltip>
            <template #content>Ручное назначение</template>

            <el-icon color="rgba(223, 226, 232, 1)">
              <QuestionFilled />
            </el-icon>
          </el-tooltip>

          <div>
            <el-button
              :disabled="route.params.id === 'new'"
              type="primary"
              plain
              @click="onManual"
            >
              Подобрать вручную
            </el-button>
          </div>
        </div>

        <div class="user-select__blocks-subtitle user-select__blocks-subtitle--mb">Выбрать из базы</div>

        <div class="user-select__blocks">
          <MagnerFormSelect
            v-if="!readOnly"
            :key="selectKey"
            v-model="existingId"
            :field="{
              props: {
                disabled: isEmptyContacts || field.disabled,
                placeholder: 'Выбрать',
                valueKey: 'id',
                labelFormatter: (option) => `${option.phone} — ${option.name} ${option.surname} ${option.deactivated || ''}`,
                filterable: true,
                remote: true,
                noDataText: 'Ничего не найдено',
                remoteMethod,
                loadRemoteMethodOnFocus: true,
              },
            }"
            @update:model-value="updateExisting"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  reactive,
  computed,
  watch,
  ref,
} from 'vue';
import { MagnerFormSelect, MagnerFormInput } from 'magner';
import { type Client } from 'features/users/clients';
import { walkerSearch, walkerGet } from 'features/users/walkers';
import { QuestionFilled } from '@element-plus/icons-vue';
import { useRoute } from 'vue-router';
import { DictionaryItem } from '~/types';

const props = defineProps({
  form: {
    type: Object as PropType<Record<string, any>>,
    required: true,
  },
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object] as PropType<DictionaryItem>,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);
const route = useRoute();

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
  return props.field.readOnly;
});

const existingId = ref(null);

const user = reactive({
  type: 'existing',
  existingId: props.modelValue?.existingId ? props.modelValue?.existingId : null as string | null,
  existingEntity: props.modelValue?.existingEntity ? props.modelValue?.existingEntity : null as null,
  existingData: props.modelValue?.existingData,
  isManual: false,
  newEntity: { phone: '', name: '', surname: '' },
});

const isEmptyContacts = ref<boolean>(false);
const selectKey = computed(() => props.form.user.existingId + isEmptyContacts.value);

const formatterOptions = (options: any) => {
  if (!user.existingId) {
    return options;
  }

  const hasOption = options.some((item: Client) => item.id === user.existingId);

  if (!hasOption) {
    return [...options, { ...user?.existingEntity, disabled: true, deactivated: '(неактивный)' }];
  }

  return options;
};

const remoteMethod = async (value: string) => {
  const list = await walkerSearch(value);

  return { data: formatterOptions(list?.data || []) };
};

const updateValue = () => {
  emit('update:modelValue', user);
};

const updateExisting = async (id: string) => {
  const fullUser = await walkerGet({ id, isNew: false, data: {} as any });

  if (fullUser?.error) {
    user.existingEntity = null;
    emit('update:modelValue', user);
    return;
  }

  if (Array.isArray(fullUser?.data)) {
    user.existingEntity = fullUser?.data.find((user) => user.id === id);
  }
  else {
    user.existingEntity = fullUser?.data;
  }

  user.isManual = false;
  user.existingId = id;

  // existingId.value = null;

  updateValue();
};

const onManual = () => {
  user.isManual = true;
  emit('update:modelValue', user);

  setTimeout(() => {
    document.querySelector('.walker-manual-table')?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, 100);
};

watch(() => props.modelValue, (newValue, oldValue) => {
  if (oldValue.existingId !== newValue.existingId && oldValue.isManual) {
    existingId.value = null;
  }

  emit('update:modelValue', newValue);
}, { deep: true });

watch(() => user.type, () => {
  user.existingId = null;
  user.existingEntity = null;
  updateValue();
});

watch(() => props.modelValue?.existingEntity, () => {
  user.existingEntity = props.modelValue?.existingEntity ?? null;
}, { deep: true });

const fields = {
  phoneReadonly: {
    props: {
      placeholder: 'Телефон',
      readOnly: true,
    },
  },
  nameReadonly: {
    props: {
      placeholder: 'Иван',
      readOnly: true,
    },
  },
  surnameReadonly: {
    props: {
      placeholder: 'Иванов',
      readOnly: true,
    },
  },
};
</script>

  <style scoped>
  .user-select {
    width: 100%;
  }

  .user-select__blocks {
    display: flex;
    gap: 12px;
    padding-top: 8px;
  }

  .user-select__blocks_big-padding {
    padding-top: 18px;
    padding-top: 24px;
    margin-top: 32px;
    border-top: 1px solid #DFE2E8;
  }

  .user-select__blocks p {
    margin: 0 0 8px;
  }

  .user-select__blocks > div {
    width: 100%;
  }

  sup {
    color: red;
    opacity: .6;
    font-size: 14px;
  }

  .user-select__blocks-subtitle {
    font-size: 20px;
    font-weight: bold;
  }

  .user-select__blocks-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px 0;
    margin-bottom: 24px;
  }

  .user-select__blocks-flex:last-child {
    margin-bottom: 0px;
  }

  .user-select__blocks-flex button {
    margin-bottom: 0;
  }

  .user-select__blocks-flex .el-icon {
    margin-left: 4px;
    margin-right: 10px;
  }

  .user-select__blocks-subtitle--mb  {
    margin-bottom: 12px;
  }
  </style>
