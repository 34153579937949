import { createApp } from 'vue';
import { projectController } from 'magner';
import { createYmaps } from 'vue-yandex-maps';
import manifest from '~/configs/manifest';
import routing from './routing';
import i18n from '~/configs/translation';
import development from '~/configs/development';
import YMap from '~/components/yandex-map.vue';

const yMap = createYmaps({
  apikey: '23e70276-ed11-4097-ae5e-2d0a03b8f5a0',
});

const appMap = createApp(YMap);

appMap.use(yMap);

export default projectController({
  manifest,
  routing,
  i18n,
  development,
});
