<template>
  <div class="address-select">
    <MagnerFormRadio
      v-if="!readOnly"
      v-model="address.type"
      :field="{
        props: { class: 'ml-4' },
        options: [{
          label: 'Новый',
          value: 'new',
        }, {
          label: 'Есть в базе',
          value: 'existing',
        }],
      }"
      @update:model-value="updateVal"
    />

    <div
      v-if="address.type === 'existing'"
      class="address-select__blocks"
    >
      <MagnerFormSelect
        :key="user?.existingId + city + isEmptyUserAddresses + user?.type"
        v-model="address.existingId"
        :field="{
          props: {
            disabled: (user?.existingId && city && isEmptyUserAddresses) || !user?.existingId || user?.type === 'new' || !city || readOnly,
            placeholder: selectPlaceholder,
            valueKey: 'id',
            noDataText: 'Сначала выберите город',
            labelFormatter: (a) => `${a?.city?.name ? `${a?.city?.name}, ` : ''}${a?.district?.slug ? `${a?.district?.slug}, ` : ''}${a?.street}${a?.house ? ', ' + a.house : ''}${a?.office ? ', ' + a.office : ''}`,
            filterable: true,
            remote: true,
            remoteMethod: addressSearch,
          },
        }"
        @update:model-value="updateVal"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  onMounted,
  defineProps,
  defineEmits,
  PropType,
  reactive,
  computed, ref, watch,
} from 'vue';
import { MagnerFormRadio, MagnerFormSelect, } from 'magner';
import { clientSimpleGet } from 'features/users/clients';

const props = defineProps({
  form: {
    type: Object as PropType<Record<string, any>>,
    required: true,
  },
  field: {
    type: Object as PropType<{
      readOnly: any,
    }>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object] as PropType<{}>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const isEmptyUserAddresses = ref<boolean>();

const user = computed(() => props.form.user);
const city = computed(() => props.form.city);

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
  return props.field.readOnly;
});

const selectPlaceholder = computed(() => {
  if ((!user.value?.existingId || user.value?.type === 'new') && !city.value) {
    return 'Выберите город и клиента из базы';
  }

  if (user.value?.type === 'new') {
    return 'Для нового клиента нет адресов в базе';
  }

  if (!user.value?.existingId) {
    return 'Выберите клиента из базы';
  }

  if (!city.value) {
    return 'Выберите город';
  }

  if (user.value?.existingId && user.value?.type !== 'new' && city.value && isEmptyUserAddresses.value) {
    return 'Адресов у выбранного клиента в заданном городе не найдено';
  }

  return 'Выбрать';
});

const address = reactive({
  type: props.modelValue?.type ? props.modelValue.type : 'new' as 'new' | 'existing',
  existingId: props.modelValue?.existingId ? props.modelValue.existingId : null as null | string,
});

const addressSearch = async () => {
  if (user.value.existingId) {
    const clientData = await clientSimpleGet({ id: user.value.existingId, isNew: false, data: {} as any });

    if (clientData.data) {
      if (props.form.city) {
        const addresses = clientData.data.addresses.filter((address) => props.form.city === address.city.id);
        isEmptyUserAddresses.value = !addresses.length;
        return { data: addresses };
      }

      isEmptyUserAddresses.value = !clientData.data.addresses.length;
      return { data: clientData.data.addresses };
    }
  }

  isEmptyUserAddresses.value = true;
  return { data: [] };
};

const updateForm = async () => {
  const form = reactive(props.form);
  
  const list = await addressSearch();
  const value = list.data.find((item) => item.id === address.existingId);
  
  form.district = value?.district;
  form.street = value?.street;
  form.house = value?.house;
  form.entrance = value?.entrance;
  form.floor = value?.floor;
  form.office = value?.office;
  form.doorCode = value?.doorCode;
  form.addressComment = value?.comment;
};

const resetData = () => {
  const form = reactive(props.form);

  form.district = null;
  form.street = null;
  form.house = null;
  form.entrance = null;
  form.floor = null;
  form.office = null;
  form.doorCode = null;
  form.addressComment = null;
};

const updateVal = async () => {
  if (address.type === 'new') {
    resetData();
  }
  else {
    updateForm();
  }

  emit('update:modelValue', address);
};
// Если изменился город, то отчищаем выбранный город из списка
watch(() => city.value, () => {
  address.existingId = null;
  updateVal();
});

// Если пользователь изменил клиента из базы на другого ИЛИ выбрал нового
watch(() => props.form.user.existingId, () => {
  address.existingId = null;
  updateVal();
});

onMounted(() => {
  updateForm();
});
</script>

<style scoped>
.address-select {
  width: 100%;
  margin-bottom: -12px;
}

.address-select__blocks {
  display: flex;
  gap: 12px;
  padding-top: 8px;
  margin-bottom: 4px;
}

.address-select__blocks p {
  margin: 0 0 8px;
}

.address-select__blocks > div {
  width: 100%;
}
</style>
