<template>
  <div class="chat-card">
    <div class="chat-card__header">
      <router-link :to="{ name: 'chats' }" class="chat-card__header-link">
        <el-button>К списку</el-button>
      </router-link>

      <router-link :to="{ name: 'order', params: { id: route.params.id } }" class="chat-card__header-link">
        <el-button>К заказу</el-button>
      </router-link>
    </div>

    <div ref="container" class="chat-card__content">
      <div v-for="group in messageGroup" :key="group.date" class="chat-card__message-content">
        <div class="chat-card__date">
          <span>{{ formatDate(group.date) }}</span>
        </div>

        <div style="display: flex; flex-direction: column-reverse">
          <div
            v-for="item in group.items"
            :key="item.id"
            class="chat-card__message"
            :class="{ 'chat-card__message--me': item.authorId === userId, 'chat-card__message--admin': isAdmin }" 
          >
            <div class="chat-card__image" />

            <div class="chat-card__text-box">
              <div class="chat-card__name">
                {{ item.authorName }} 

                <span 
                  v-if="roleTranslate(item.authorType)" 
                  class="chat-card__role"
                >
                  ({{ roleTranslate(item.authorType) }})
                </span>
              </div>
              <div class="chat-card__text-container">
                <div class="chat-card__text">
                  <div class="chat-card__text-content">{{ item.content }}</div>

                  <span>{{ formatTime(item.createdAt) }}</span>
                </div>
              </div>

              <a 
                v-for="file, fileIndex in item.attachments" 
                :key="fileIndex" 
                :href="file?.downloadUrl" 
                target="_blank"
                class="chat-card__text-container chat-card__text-container--file"
              >
                <div class="chat-card__text">
                  <div class="chat-card__text-content chat-card__text-content--file">
                    <el-icon size="24" class="chat-card__text-content-file-icon">
                      <Document />
                    </el-icon>
                    Приложенный файл {{ fileIndex + 1 }}
                  </div>

                  <span>{{ formatTime(item.createdAt) }}</span>
                </div>
              </a>

              <div v-if="isAdmin" class="chat-card__delete" @click="removeMessage(item.id)">Удалить</div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="loading.list" class="chat-card__message-content chat-card__loading">
        <el-icon color="#409EFC" class="is-loading">
          <Loading />
        </el-icon>
      </div>

      <div v-if="!list.length" class="chat-card__empty">Список сообщений пуст</div>
    </div>

    <div v-if="fileList.length" class="chat-card__files">
      <div v-for="item, i in fileList" :key="i" class="chat-card__files-item">
        <div class="chat-card__files-item-name">
          <el-icon>
            <Document />
          </el-icon>

          {{ (item as any).name }}
        </div>

        <div class="chat-card__files-item-icon">
          <el-icon color="#4e8e2f" size="16">
            <SuccessFilled />
          </el-icon>
          <el-icon color="#4e8e2f" size="16" @click="fileList.splice(i, 1)">
            <CircleClose />
          </el-icon>
        </div>
      </div>
    </div>

    <div class="chat-card__footer">
      <div class="chat-card__file">
        <el-upload
          v-model:file-list="fileList"
          :show-file-list="false"
          accept="image/jpeg,image/png,image/gif,application/pdf,audio/mp3"
          class="upload-demo"
          action="#"
          :auto-upload="false"
          multiple
        >
          <el-icon style="font-size: 24px; cursor: pointer">
            <Paperclip />
          </el-icon>
        </el-upload>
      </div>

      <el-input
        v-model="message"
        :autosize="{ minRows: 1, maxRows: 5 }"
        type="textarea"
        class="chat-card__input"
      />

      <el-button
        type="primary"
        class="chat-card__send"
        :disabled="!message.trim().length && !fileList.length"
        :icon="Right"
        :loading="loading.message"
        circle
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  Paperclip, Right, Document, SuccessFilled, CircleClose, Loading,
} from '@element-plus/icons-vue';

import {
  ref,
  defineProps,
  onMounted,
  computed,
  reactive,
} from 'vue';
import { useRoute } from 'vue-router';
import profileRequest from 'configs/development/profile';
import { EventSourcePolyfill } from 'event-source-polyfill';
import {
  get, create, remove, init, type TypeMessage, type Message, type AuthorType,
} from '../requests';
import { fileUploadMultiple, fileUpload } from '~/features/common/file-request';

import { formatDate, formatTime } from '~/utils/date-transform';

const PAGE_LIMIT = 100;

interface MessageGroup {
  [key: string]: Message[],
}

defineProps({
  modelValue: {
    type: [Object] as any,
    default: null,
  },
});

const route = useRoute();

const container = ref();
const stopRequest = ref(false);
const fileList = ref([]);

/* Data */
const initSocket = ref(await init({ id: route.params.id as string, isNew: false, data: { type: route.query.type } }));
const socket = ref(initSocket.value.data);
const message = ref('');

/* Config */
const loading = reactive({
  message: false,
  list: false,
});

const user = ref(await profileRequest({}));
const userId = computed(() => (user.value.data?.user as { id: string })?.id);
const isAdmin = computed(() => user.value.data?.role === 'ROLE_SUPER_ADMIN');
const roleTranslate = (name: AuthorType) => {
  if (name === 'admin') {
    return 'Администратор';
  }

  if (name === 'walker') {
    return 'Выгульщик';
  }

  if (name === 'client') {
    return 'Клиент';
  }

  return 'false';
};

/* List messages */
// eslint-disable-next-line max-len
const messages = ref(await get({ id: route.params.id as string, isNew: false, data: { page: 1, limit: PAGE_LIMIT, type: route.query.type as TypeMessage } }));
const list = ref(messages.value.data?.elements || []);

const messageGroup = computed(() => {
  const groups = (list.value || []).reduce((acc, item) => {
    const date = item.createdAt?.split(/(?<=\d{4}-\d{2}-\d{2})/)[0];

    if (!acc[date]) {
      acc[date] = [];
    }

    acc[date].push(item);

    return acc;
  }, {} as MessageGroup);

  return Object.keys(groups).map((date) => ({
    date,
    items: groups[date],
  }));
});

const currentPage = computed(() => (list.value.length / PAGE_LIMIT) + 1);

/* Requests */
const request = async () => {
  if (stopRequest.value || loading.list) {
    return;
  }

  loading.list = true;
  try {
    // eslint-disable-next-line max-len
    const result = await get({ id: route.params.id as string, isNew: false, data: { page: currentPage.value, limit: PAGE_LIMIT, type: route.query.type as TypeMessage } });

    list.value.push(...(result.data?.elements || []));

    loading.list = false;

    if ((result.data?.elements || []).length < PAGE_LIMIT) {
      stopRequest.value = true;
    }
  }
  catch (error) {
    console.error(error);
  }
  finally {
    loading.list = false;
  }
};

const attachmentsUpload = async () => {
  if (!fileList.value.length) {
    return [];
  }

  const result = await fileUploadMultiple(fileList.value.map((item) => (item as { raw: File }).raw), 'default');

  return result?.data?.map((item) => item.id);

  // const result = [];
  // // eslint-disable-next-line no-restricted-syntax
  // for (const file of fileList.value) {
  //   // eslint-disable-next-line no-await-in-loop
  //   const resultUpload = await fileUpload((file as { raw: File }).raw, 'default');

  //   result.push((resultUpload.data as { id: string }).id);
  // }

  // return result;
};

const sendMessage = async () => {
  if (!message.value.trim().length && !fileList.value.length) {
    return;
  }

  loading.message = true;

  try {
    const attachments = await attachmentsUpload();

    const data = {
      walkingId: route.params.id as string,
      type: route.query.type as TypeMessage,
      content: message.value,
      attachments,
    };

    const result = await create({ id: '', isNew: true, data });

    list.value?.unshift(result.data as Message);

    message.value = '';
    fileList.value = [];

    container.value.scrollTo(0, 0);
  }
  catch (error) {
    console.error(error);
  }
  finally {
    loading.message = false;
  }
};

const removeMessage = async (id: string) => {
  const index = list.value.findIndex((item) => item.id === id);

  list.value.splice(index, 1);

  await remove({ id, isNew: false, data: {} });
};

/* Initial */
const initMessages = () => {
  const notificationEvent = new EventSourcePolyfill(`${socket.value?.url}`, {
    headers: {
      Authorization: `Bearer ${socket.value?.jwt}`,
    },
  });

  notificationEvent.addEventListener('open', (e) => {
    console.warn('Сообщение готовы к слушанию');
  });

  notificationEvent.addEventListener('message', (e) => {
    const message = JSON.parse(e.data);

    if (!message) {
      console.error('Нет данных');

      return;
    }

    if (message.authorId === userId.value) {
      return;
    }

    list.value.unshift({
      ...message,
    });
  });  
};

onMounted(() => {
  initMessages();

  container.value.addEventListener('scroll', async (e:any) => {
    if (list.value.length < PAGE_LIMIT) {
      return;
    }
                
    if (stopRequest.value) {
      return;
    }

    if (loading.list) {
      return;
    }

    const position = (e.target.scrollHeight - e.target.offsetHeight);
                
    if (e.target.scrollTop * -1 >= position) {
      await request();
    }
  });
});
</script>

<style>
.chat-card {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  height: 100%;
  overflow: hidden;
  max-height: calc(100vh - 49px - 24px);
  height: calc(100vh - 49px - 24px);
}

.chat-card__image {
  min-width: 32px;
  height: 32px;
  max-width: 32px;
  border-radius: 50%;
  background-image: url('~/assets/icons/logo-icon.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f4f4f5;
  background-size: 18px;
}

.chat-card__header {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #DFE2E8;
}

.chat-card__header-back {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  color:#303133;
}

.chat-card__header-title {
  font-size: 14px;
  margin-left: 4px;
  color:#303133;
  text-decoration: none;
}

.chat-card__content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  overflow-y: auto;
  padding-bottom: 8px;
  flex: 1 1 auto;
  width: calc(100% + 24px);
  overflow-anchor: none
}

.chat-card__footer {
  display: flex;
  align-items: flex-end;
  padding-top: 12px;
}

.chat-card__input {
  margin: 0 12px;
}

.chat-card__input textarea {
  resize: none;
}

.chat-card__send {
  max-width: 32px;
  margin-bottom: 0 !important;
}

.chat-card__date {
  text-align: center;
  font-size: 12px;
  padding: 4px 0;
  color: rgba(0,0,0,0.5);
  position: sticky;
  top: 12px;
  z-index: 2;
}

.chat-card__date span {
  background: white;
  padding: 4px 6px;
  border-radius: 10px;
}

.chat-card__message {
  display: flex;
  margin-top: 12px;
}

.chat-card__text {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 8px;
  background-color: #f4f4f5;
}

.chat-card__text div {
  line-height: 21px;;
}

.chat-card__text span {
  text-align: right;
  font-size: 10px;
  margin-top: 4px;
  opacity: 0.6;
}

.chat-card__text-box {
  position: relative;
  margin-left: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 48%;
}

.chat-card__message--me {
  justify-content: flex-end;
}

.chat-card__message--me .chat-card__text-box {
  display: flex;
  margin-right: 24px;
  align-items: flex-end;
}

.chat-card__message--me .chat-card__text-box .chat-card__text {
  color: white;
  background: #47a0fc;
}

.chat-card__message--me .chat-card__image,
.chat-card__message--me .chat-card__name {
  display: none;
}

.chat-card__message-content {
  margin-top: 12px;
}

.chat-card__box {
  width: 100%;
}

.chat-card__name {
  font-weight: 500;
  color: rgba(0,0,0,0.7);
  margin-bottom: 6px;
  margin-top: 4px;
}

.chat-card__file {
  display: flex;
  align-items: center;
  height: 31px;
}

.chat-card__files {
  margin-top: 12px;
}

.chat-card__files-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
}

.chat-card__files-item:last-child {
  margin-bottom: 0;
}

.chat-card__files-item-name {
  display: flex;
  align-items: center;
}

.chat-card__files-item-name i {
  margin-right: 4px;
}

.chat-card__files-item-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 16px;
}

.chat-card__files-item-icon i:last-child {
  display: none;
}

.chat-card__files-item-icon:hover i:first-child {
  display: none;
}

.chat-card__files-item-icon:hover i:last-child {
  display: flex;
}

.chat-card__actions {
  display: flex;
  justify-content: flex-end;
}

.chat-card__delete {
  cursor: pointer;
  opacity: 0.4;
  font-size: 12px;
  color: rgba(0,0,0,0.7);
  margin-top: 4px;
}

.chat-card__delete:hover {
  opacity: 0.8;
}

.chat-card__loading {
  display: flex;
  justify-content: center;
}

.chat-card__text-content {
  white-space: pre-wrap;
  word-break: break-word;
}

.chat-card__text-container {
  display: flex;
}

.chat-card__role {
  opacity: 0.6;
  font-size: 12px;
}

.chat-card__text-content--file {
  display: flex;
  align-items: center;
}

.chat-card__text-content-file-icon {
  margin-right: 4px;
}

.chat-card__text-container--file {
  margin-top: 12px;
  text-decoration: none;
}
 
.chat-card__empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.chat-card__header-link {
  margin-right: 8px;
  margin-top: 4px;
}

.chat-card__header-link:first-child{
  margin-left: 4px;
}

.chat-card__header-link button {
  margin-bottom: 0 !important;
}

@media (max-width: 767px) {
  .chat-card__text-box {
    max-width: 80%;
  }

  .chat-card__message .el-avatar {
    min-width: 32px;
  }
}
</style>
