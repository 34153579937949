import { ValueLabel } from '~/types/common';
import { SelectItem } from '~/types';

export interface TimeSlot {
  id: string,
  weekDay: number,
  startTime: number,
  endTime: number,
}

export interface WorkingSchedule {
  id: string,
  timeSlots: TimeSlot[],
}

export interface WalkersList {
  id: string,
  name: string,
  surname: string,
  phone: string,
  email: string,
  telegram: string,
  rating: string,
  hasOffers: boolean,
  status: SelectItem,
  city: SelectItem,
}

export interface Walkers {
  id: string,
  name: string,
  surname: string,
  phone: string,
  roles: SelectItem[],
  email: string,
  status: any,
  city: any,
  telegram: string,
  rating: string,
  birthdate: string,
  offers: string[],
  photoId?: string,
  motivation: string,
  experience: string,
  pets: string,
  interests: string,
  vk: string,
  workingSchedule: WorkingSchedule[],
  video: string,
  offerCategories: ValueLabel[],
  moderatedAt: string,

  profileDataOnModeration: Walkers,
  tag?: string,
}
 
export interface WalkersCreate extends Walkers {
}

export enum WalkerStatuses {
  active = 'active',
  inactive = 'inactive',
  moderation = 'moderation',
  registration = 'registration',
  declined = 'declined',
  blocked = 'blocked',
  removing_requested = 'removing_requested',
  removed = 'removed'
}
