import dayjs from 'dayjs';
import { request } from '~/utils/request';
import { filterURLBuilder } from '~/utils/filter-builder';

export interface ListResponse<T> {
  data: {
    pageLimit: number,
    countItems: number,
    countPages: number,
    elements: T,
  },
}

export const getListResponse = async <ENTITY>(url: string, page?: number) => {
  const res = await request.api.get<ListResponse<ENTITY[]>>(url);

  if (res.error) {
    return {
      data: {
        rows: [],
        pagination: null,
      },
    };
  }

  res.data?.data.elements.map((item: ENTITY) => {
    if ((item as {createdAt: string}).createdAt) {
      (item as {createdAt: string}).createdAt = dayjs.tz((item as {createdAt: string}).createdAt, 'UTC').tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');
    }

    if ((item as {startTime: string}).startTime) {
      (item as {startTime: string}).startTime = dayjs.tz((item as {startTime: string}).startTime, 'UTC').tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');
    }

    return item;
  });

  return {
    data: {
      rows: res.data?.data.elements,
      pagination: {
        currentPage: page || 1,
        totalPages: res.data?.data.countPages || 1,
        totalItems: res.data?.data.countItems || 0,
      },
    },
  };
};

// eslint-disable-next-line arrow-body-style
export const sortTable = (sort: any, keys: string[]) => {
  return keys
    .filter((key) => sort[key])
    .map((key, i) => `&sort[${i}][id]=${key}&sort[${i}][value]=${sort[key]}`)
    .join('');
};

export const filtersTable = <T>(filters: T, keys: Array<keyof typeof filters>) => keys
  // eslint-disable-next-line array-callback-return, consistent-return
  .filter((key) => {
    if (Array.isArray(filters[key]) || typeof filters[key] === 'string') {
      // eslint-disable-next-line no-unused-expressions
      return (filters[key] as any)?.length > 0;
    }

    if (typeof filters[key] === 'boolean' || typeof filters[key] === 'number') {
      return filters[key];
    }
  })
  .map((key, i) => {
    if (Array.isArray(filters[key])) {
      return filterURLBuilder({ filters } as any, i, key as string);
    }

    return `&filters[${i}][id]=${String(key)}&filters[${i}][value]=${
      filters[key]
    }`;
  })
  .join('');
