import { filtersFormController } from 'magner';

const chatsFilters = filtersFormController<any>({
  saveToLocalStorage: false,
  actions: [],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    search: '',
  },
  layout: [
    // {
    //   type: 'input',
    //   name: 'search',
    //   props: {
    //     placeholder: 'По имени или email',
    //     inputDelay: 250,
    //   },
    // },
  ],
});

export default chatsFilters;
