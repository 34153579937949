import { ft } from '~/utils/feature-translate';

export const ClientRu = {
  sidebar: {
    table_title: 'Клиенты',
    card_title: 'Клиент',
  },
  table: {
    title: 'Клиенты',
    not_found: 'Клиенты не найдены',
    remove_many: 'Удалить выбранное',
    addNew: 'Добавить',
    headers: {
      id: 'ID',
      firstname: 'Имя',
      lastname: 'Фамилия',
      city: 'Город',
      email: 'E-mail',
      phone: 'Телефон',
      petBreeds: 'Породы питомцев',
      petAges: 'Возраст питомцев',
      cities: 'Города адресов',
      districts: 'Районы адресов',
      contacts: 'Контакты',
      status: 'Статус',
      actions: 'Действия',
    },
  },
  filters: {
    id: 'ID',
    name: 'Имя или фамилия',
    surname: 'Фамилия',
    phone: 'Телефон',
    status: 'Статус',
    city: 'Город',
    districts: 'Районы',
    email: 'Электронная почта',
    petBreeds: 'Породы питомцев',
    petAgeFrom: 'Возраст питомцев, от (года)',
    petAgeTo: 'Возраст питомцев, до (года)',
    contacts: 'Контакты',
  },
  form: {
    title: 'Клиент',
    fields: {
      id: 'ID',
      phone: 'Телефон',
      name: 'Имя',
      surname: 'Фамилия',
      roles: 'Тип аккаунта',
      status: 'Статус',
      email: 'E-mail',
      city: 'Город',
    },
    pets: {
      title: 'Питомцы',
      empty_title: 'Пока не добавлено ни одного питомца',
      fields: {
        name: 'Имя',
        breed: 'Порода',
        age: 'Возраст',
        weight: 'Вес, кг',
        gender: 'Пол',
        peculiarities: 'Особенности',
        comment: 'Комментарий',
      },
    },
    address: {
      title: 'Адреса',
      empty_title: 'Пока не добавлено ни одного адреса',
      fields: {
        city: 'Город',
        district: 'Район',
        street: 'Улица',
        house: 'Дом',
        porch: 'Подъезд',
        floor: 'Этаж',
        flat: 'Квартира/офис',
        intercom: 'Домофон',
        comment: 'Комментарий',
      },
    },
    contacts: {
      title: 'Контакты',
      empty_title: 'Пока не добавлено ни одного контакта',
      fields: {
        phone: 'Телефон',
        firstname: 'Имя',
        lastname: 'Фамилия',
      },
    },
  },
};

export const t = ft(ClientRu, 'users.clients.');
