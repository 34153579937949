import { ft } from '~/utils/feature-translate';

export const DiscountsRu = {
  sidebar: {
    table_title: 'Скидки',
    card_title: 'Скидка',
  },
  table: {
    title: 'Скидки',
    not_found: 'Скидки не найдены',
  },
  card: {
    title: 'Скидка',
  },
};

export const t = ft(DiscountsRu, 'catalog.discounts.');
