<template>
  <div class="order-rating">
    <h2 class="custom-label">
      {{ modelValue.rating ? `Оценка - ${modelValue.rating}`: 'Оценки нет' }}
    </h2>
    <ReadOnlyInput
      class="order-rating__input"
      label="Метки оценки"
      :value="features"
    />
    <ReadOnlyInput
      class="order-rating__input"
      label="Комментарий"
      :value="modelValue.comment ? modelValue.comment : '-'"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, PropType } from 'vue';
import { OrderRating } from 'features/orders/types';
import ReadOnlyInput from 'features/common/components/read-only-input.vue';

const props = defineProps({
  modelValue: {
    type: Object as PropType<OrderRating>,
    required: true,
  },
});

const features = computed(() => {
  if (props.modelValue.features.length) {
    return props.modelValue.features.map((item) => item.title).join(', ');
  }

  return '-';
});
</script>

<style lang="postcss">
.order-rating {
  width: 100%;
  &__input {
    &:not(:last-child) {
      margin-bottom: 25px;
    }
  }
}
</style>
