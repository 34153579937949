<template>
  <div class="walk-services">
    <h2 class="custom-label">
      Услуги на {{ fullPrices.full }}
    </h2>
    <div
      v-if="city"
      class="walk-services__row"
      style="margin-bottom: 12px;"
      :class="{
        'radio-button-disabled': route.name === 'order' || (props.form.status !== WalkingStatuses.waitingPayment && route.params.id !== 'new')
      }"
    >
      <MagnerFormRadio
        v-model="services.offer"
        :field="{
          type: 'radio',
          name: 'type',
          options: offersList,
          dataType: 'string',
          props: {
            required: true,
          },
          validation: [
            {
              type: 'empty-required' as 'empty',
              trigger: 'change',
            },
          ],
        }"
      />
    </div>
    <div class="walk-services__row">
      <div class="walk-services__field half">
        <p>Количество минут<sup>*</sup></p>

        <el-form-item :error="services.typeError || ''">
          <MagnerFormSelect
            v-model="services.type"
            :field="{
              options: offersRates,
              props: {
                required: true,
                disabled: disabled,
                placeholder: 'Выбрать',
                valueKey: 'id',
                labelKey: 'title',
                filterable: true,
                remote: true,
              },
            }"
            @update:model-value="updateType"
          />
        </el-form-item>
      </div>
      <div class="walk-services__field little">
        <p>Цена</p>
        <div class="walk-services__field-readonly">
          {{ typePrices?.price }}
        </div>
      </div>
      <div class="walk-services__field little">
        <p>Скидка</p>
        <div class="walk-services__field-readonly">
          {{ typePrices?.discount }}
        </div>
      </div>
      <div class="walk-services__field little">
        <p>Итого</p>
        <div class="walk-services__field-readonly">
          {{ typePrices?.full }}
        </div>
      </div>
    </div>

    <div class="walk-services__row">
      <div class="walk-services__field" style="width: 100%">
        <p>Тип</p>
        <el-form-item :error="services.offerTypeError || ''">
          <MagnerFormSelect
            v-model="services.offerType"
            :field="{
              options: offerTypesOptions,
              props: {
                disabled: disabled,
                placeholder: 'Выбрать',
                valueKey: 'id',
                labelKey: 'title',
                filterable: true,
                remote: true,
              },
            }"
            @update:model-value="updateType"
          />
        </el-form-item>
      </div>
    </div>
    <div class="walk-services__row">
      <div class="walk-services__field half no-error-input-styles">
        <p>Дополнительно</p>
        <MagnerFormSelect
          :key="services.type"
          v-model="services.addition"
          :field="{
            options: additionOptions,
            props: {
              disabled: disabled,
              placeholder: 'Выбрать',
              valueKey: 'id',
              labelKey: 'title',
              multiple: true,
              filterable: true,
              remote: true,
            },
          }"
          @update:model-value="updateAddition"
        />
      </div>
      <div class="walk-services__field little">
        <p>Цена</p>
        <div class="walk-services__field-readonly">
          {{ additionPrices?.price }}
        </div>
      </div>
      <div class="walk-services__field little">
        <p>Скидка</p>
        <div class="walk-services__field-readonly">
          {{ additionPrices?.discount }}
        </div>
      </div>
      <div class="walk-services__field little">
        <p>Итого</p>
        <div class="walk-services__field-readonly">
          {{ additionPrices?.full }}
        </div>
      </div>
    </div>

    <div class="walk-services__row">
      <div class="walk-services__field quart">
        <p>Стоимость заказа</p>
        <div class="walk-services__field-readonly">
          {{ fullPrices.price }}
        </div>
      </div>
      <div class="walk-services__field quart">
        <p>Скидка на заказ</p>
        <div class="walk-services__field-readonly">
          {{ fullPrices.discount }}
        </div>
      </div>
      <div class="walk-services__field quart no-error-input-styles">
        <p>Ручная скидка</p>
        <MagnerFormInput
          v-model="services.manualDiscount"
          :field="{
            props: {
              disabled: disabled,
              type: 'number',
              placeholder: '0₽',
            }
          }"
          @update:model-value="updateVal"
        />
      </div>
      <div class="walk-services__field quart">
        <p>Итого со скидкой</p>
        <div class="walk-services__field-readonly">
          {{ fullPrices.full }}
        </div>
      </div>
    </div>

    <el-row class="walk-services__row">
      <div class="walk-services__field full no-error-input-styles">
        <p>Комментарий</p>
        <MagnerFormInput
          v-model="services.userComment"
          :field="{
            props: {
              disabled: disabled
            }
          }"
          @update:model-value="updateVal"
        />
      </div>
    </el-row>
    <div class="mt">
      <sup v-if="!city">* Сначала выберите город</sup>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineEmits,
  defineProps, onMounted,
  PropType,
  reactive,
  ref,
  watch,
} from 'vue';
import { MagnerFormRadio, MagnerFormInput, MagnerFormSelect } from 'magner';
import type { OrderFrontend } from 'features/orders';
import { offerList, offerRead } from 'features/orders/requests';
import { useRoute } from 'vue-router';
import type { OfferRead } from '../types';
import { type WalkingOption, type WalkingType, WalkingStatuses } from '~/types/common';

const props = defineProps({
  form: {
    type: Object as PropType<OrderFrontend>,
    required: true,
  },
  field: {
    type: Object as PropType<{ disabled: boolean }>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object] as PropType<{}>,
    default: null,
  },
});

const route = useRoute();
const emit = defineEmits(['update:modelValue']);

const city = computed(() => props.form.city);
const disabled = computed(() => props.field.disabled);

const additionOptions = ref<WalkingOption[]>([]);

const isSingleList = computed(() => props.form.status !== WalkingStatuses.waitingPayment && route.params.id !== 'new');

const offers = ref(await offerList({}));
// eslint-disable-next-line max-len
const offer = ref<Partial<{ data: OfferRead }>>({ data: {} as OfferRead });

const offersList = computed(() => {
  if (isSingleList.value) {
    return [{ label: props.modelValue.offerReadonly?.title, value: props.modelValue.offerReadonly?.id }];
  }

  return (offers.value.data || [])
    .filter((item) => item.city.id === city?.value && item.status.value !== 'inactive')
    .map((item) => ({ label: item.title, value: item.id }));
});

const offersRates = computed(() => {
  if (isSingleList.value) {
    const item = props.modelValue.offerReadonly.offerRate;

    return [{
      title: `${item.time} минут`,
      price: item.price.inRubles,
      walkerFee: item.walkerFee.inRubles,
      id: item.id,
    }];
  }

  return (offer.value.data?.offerRates || []).map((item) => ({
    title: `${item.time} минут`,
    price: item.price.inRubles,
    walkerFee: item.walkerFee.inRubles,
    id: item.id,
  }));
});

const offersOptions = computed(() => {
  if (isSingleList.value) {
    return props.modelValue.offerReadonly.offerOptions.map((item) => ({
      title: item.title,
      price: item.price.inRubles,
      walkerFee: item.walkerFee.inRubles,
      id: item.id,
    }));
  }

  return (offer.value.data?.offerOptions || []).map((item) => ({
    title: item.title,
    price: item.price.inRubles,
    walkerFee: item.walkerFee.inRubles,
    id: item.id,
  }));
});

const offerTypesOptions = computed(() => {
  if (!isSingleList.value) {
    return offer.value.data?.offerTypes || [];
  }

  return props.modelValue?.offerReadonly?.offerType ? [props.modelValue?.offerReadonly?.offerType] : [];
});

const services = reactive({
  type: props.modelValue?.type ? props.modelValue.type : null as string | null,
  typeData: props.modelValue?.typeData ? props.modelValue.typeData : null as WalkingType | null,
  addition: props.modelValue?.addition ? props.modelValue.addition : [] as string[],
  additionData: props.modelValue?.additionData ? props.modelValue.additionData : [] as WalkingOption[],
  manualDiscount: props.modelValue?.manualDiscount ? props.modelValue.manualDiscount : '0' as string,
  userComment: props.modelValue?.userComment ? props.modelValue.userComment : '',
  offer: props.modelValue?.offer ? props.modelValue.offer?.id : '',
  offerType: props.modelValue?.offerType ? props.modelValue.offerType : '',
  offerReadonly: props.modelValue?.offerReadonly ? props.modelValue.offerReadonly : '',
});

const typePrices = computed(() => (services.typeData ? ({
  price: `${services.typeData.price}₽`,
  discount: '0₽',
  full: `${services.typeData.price}₽`,
}) : null));

const additionPrices = computed(() => {
  if (!services.additionData?.length) return null;
  const price = services.additionData.reduce((accum: number, curr: WalkingOption) => {
    if (services.addition.some((id: string) => curr.id === id)) {
      accum += curr.price;
    }
    return accum;
  }, 0);
  return {
    price: `${price}₽`,
    discount: '0₽',
    full: `${price}₽`,
  };
});

const fullPrices = computed(() => {
  const additionPrice = services.additionData.reduce((accum: number, curr: WalkingOption) => {
    if (services.addition.some((id: string) => curr.id === id)) {
      accum += curr.price;
    }
    return accum;
  }, 0);
  const price = additionPrice + (services.typeData?.price || 0);
  const full = additionPrice + (services.typeData?.price || 0) - parseInt(services.manualDiscount || '0', 10);
  return {
    price: `${price}₽`,
    discount: '0₽',
    full: `${full}₽`,
  };
});

const updateVal = () => {
  emit('update:modelValue', services);
};

const updateAddition = () => {
  if (services.addition) {
    services.additionData = additionOptions.value.filter((a) => services.addition.includes(a.id));
    updateVal();
  }
  else {
    services.additionData = [];
  }
};

const updateType = () => {
  const type = offersRates.value.find((t) => t.id === services.type);

  services.typeData = type;

  // @ts-ignore
  additionOptions.value = offersOptions.value;

  if (props.form?.petsEdit?.length > 1) {
    additionOptions.value = additionOptions.value.map((item) => {
      item.disabled = true;
      return item;
    });

    updateAddition();
  }

  updateVal();

  services.typeError = '';
  services.offerTypeError = '';
};

watch(() => city.value, async (newVal: string | null) => {
  services.typeError = '';
  services.offerTypeError = '';

  services.type = null;
  services.typeData = null;
  services.addition = [];
  services.additionData = [];
  additionOptions.value = [];
  services.offer = '';
});

watch(() => services.offer, async () => {
  services.typeError = '';
  services.offerTypeError = '';

  services.type = null;
  services.typeData = null;
  services.addition = [];
  services.additionData = [];

  services.offerType = '';

  offer.value = await offerRead({ id: services.offer });

  updateType();

  updateVal();
});

watch(() => props.form?.petsEdit?.length, () => {
  if (services.typeData) {
    if (props.form?.petsEdit.length > 1) {
      additionOptions.value = additionOptions.value.map((item) => {
        item.disabled = true;
        return item;
      });
    }
    else {
      services.addition = [];
      additionOptions.value = additionOptions.value.map((item) => item);
    }

    updateAddition();
  }
});

const init = async () => {
  /* is new */
  if (route.params.id === 'new') {
    return;
  }

  if (props.form.status === WalkingStatuses.waitingPayment) {
    /* offer */
    services.offer = offersList.value?.find((item) => item.label === props.modelValue.offerTitle)?.value;

    offer.value = await offerRead({ id: services.offer });

    /* type */
    services.type = offersRates.value.find((item) => item.title === `${props.modelValue.typeTime} минут`)?.id;

    updateType();

    /* offer type */
    services.offerType = offer.value.data?.offerTypes?.find((item) => item.title === props.modelValue?.offerTypeTitle)?.id;

    /* additional */
    services.addition = additionOptions.value
      .filter((item) => props.modelValue.additionTitles.includes(item.title))
      .map((item) => item.id);

    updateAddition();
  }
};

onMounted(async () => {
  await init();
  updateType();
});
</script>

<style scoped>
.walk-services {
  width: 100%;
}

.walk-services h2 {
  margin-bottom: 10px !important;
}

.walk-services__row:not(:last-of-type) {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}

.walk-services__field.full {
  width: 100%;
}

.walk-services__field.half {
  width: 50%;
}

.walk-services__field.quart {
  width: 25%;
}

.walk-services__field.little {
  width: 16%;
}

.walk-services__field-readonly {
  height: 32px;
  background-color: #F4F4F5;
  border-radius: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2px 6px;
}

.walk-services__field > p {
  margin: 0 0 8px;
}

sup {
  color: red;
  opacity: .7;
}

.mt {
  margin-top: 15px;
}
</style>
