import { request } from '~/utils/request';
import { init } from '~/utils/get-init';
import { UserResponse } from '~/types/common/profile';
import initNotification from '~/utils/notification';

const profileRequest = request.profile(async ({ api, lstorage }) => {
  const token = lstorage.read('token');

  const res = await api.get<{ data: UserResponse }>('/profile');

  request.api.instance.defaults({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const unauthorized = await init.set();

  if (unauthorized) {
    lstorage.delete('token');
    api.instance.defaults({ headers: {} }, false);
    return { error: { message: 'Пользователь не авторизован', fields: {} } };
  }

  initNotification();

  return {
    data: {
      user: {
        id: res.data?.data.id,
      },
      role: res.data?.data.mainRole.value,
    },
  };
});

export const profileId = request.custom(async ({ lstorage }) => ({ data: lstorage.read('id') }) as any);

export default profileRequest;
