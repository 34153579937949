import { ft } from '~/utils/feature-translate';

export const AdsPushRu = {
  sidebar: {
    table_title: 'Рассылки',
    card_title: 'Рассылка',
  },
  table: {
    title: 'Рассылки',
    not_found: 'Рассылки не найдены',
    headers: {
      title: 'Название служебное',
      type: 'Город',
      audience: 'Тип аудитории',
      breeds: 'Породы питомцев',
      transition: 'Переход',
      status: 'Статус',
    },
  },
  filters: {
    code: 'Название служебное',
    type: 'Отправка',
    city: 'Город',
    title: 'Заголовок',
    text: 'Текст',
    transition: 'Переход',
    audience: 'Тип аудитории',
    breeds: 'Породы питомцев',
    petAge: 'Возраст питомцев',
    onlyNewClients: 'Заказов еще не было',
    status: 'Статус',
  },
  card: {
    title: 'Рассылка',
    form: {
      
    },
  },
};

export const t = ft(AdsPushRu, 'ads_push.');
