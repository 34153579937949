import { tableController, tablePageController } from 'magner';

import { servicesT } from './index';
import servicesFilters from './filters';
import { read } from './requests';
import type { List } from './types';

import ServicesStatusTable from './components/services-status-table.vue';

export const tableConfig = tablePageController<List>({
  header: {
    title: servicesT('table.title'),
  },
  filters: servicesFilters as any,
  request: read,
  table: tableController<List>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: servicesT('table.not_found'),
    columns: [
      {
        prop: 'title',
        label: servicesT('table.headers.title'),
        sortable: true,
      },
      {
        prop: 'city',
        label: servicesT('table.headers.city'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (row: any) => row?.name || '',
        },
      },
      {
        prop: 'maxNumberOfPets',
        label: servicesT('table.headers.max_number_of_pets'),
        sortable: true,
      },
      {
        prop: 'hasOptions',
        label: servicesT('table.headers.hasOptions.title'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (row: any) => (row?.hasOptions ? 'Есть' : 'Нет'),
        },
      },
      {
        prop: 'status',
        label: servicesT('table.headers.status'),
        sortable: true,
        view: {
          type: 'custom',
          component: () => ServicesStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'services-view',
      params: { id: row.id },
    }),
  }),
});
