<template>
  <div style="min-width: 160px !important;">
    <el-select
      v-model="model"
      :class="className"
      @click.prevent
      @change="onChange"
    >
      <el-option
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps, PropType, ref, computed, watch,
} from 'vue';

import { searchOfferStatuses } from '~/utils/searchInit';
import { updateStatus } from '../requests';

const props = defineProps({
  row: {
    type: Object as PropType<any>,
    required: true,
  },
});

const model = ref(props.row.status.value);

const items = ref((await searchOfferStatuses({})).data);

const className = computed(() => {
  if (model.value === 'active') {
    return 'status-green';
  }

  if (model.value === 'inactive') {
    return 'status-red';
  }

  return '';
});

const onChange = async (value: boolean) => {
  await updateStatus({ id: props.row.id, data: { status: value }, isNew: false });
};
  
watch(
  () => props.row.status, 
  () => {
    model.value = props.row.status.value;
  },
);
</script>
