import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { citiesT } from './index';
import {
  getDistrict,
} from './requests';
import { District } from './types';
import CitiesImportDistrict from './components/cities-import-district.vue';
import CitiesDeleteDistrict from './components/cities-delete-district.vue';

export const cardConfig = cardPageController<District>({
  header: {
    title: citiesT('card.title_district'),

    tabs: [
      {
        label: 'Карточка',
        link: (route) => ({ name: 'cities-view', params: { id: route.params.id } }),
        active: false,
      },
      {
        label: 'Рабочие зоны',
        link: (route) => ({ name: 'cities-district-view', params: { id: route.params.id } }),
        active: true,
      },
    ],
  },

  getRequest: getDistrict,

  updateRequest: () => false as any,
  createRequest: () => false as any,

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'cities' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          fields: [
            {
              type: 'custom',
              name: 'id',
              component: () => CitiesImportDistrict,
              props: {},
            },
            {
              type: 'collection',
              name: 'items',
              dataType: 'array',
              props: {
                readOnly: true,
              },
              layout: [
                /** Title */
                {
                  type: 'column',
                  props: {
                    align: 'top',
                    elementsGrow: true,
                    styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                  },
                  fields: [
                    {
                      type: 'input',
                      name: 'id',
                      dataType: 'string',
                      props: {
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'slug',
                      dataType: 'string',
                      props: {
                        readOnly: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'title',
                      dataType: 'string',
                      props: {
                        readOnly: true,
                      },
                    },
                    {
                      type: 'custom',
                      name: 'id',
                      props: {},
                      component: () => CitiesDeleteDistrict,
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {},
          layout: [],
        },
      ],
    },
  },
});
