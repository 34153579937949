import { filtersFormController } from 'magner';
import { baseT } from 'configs/translation/base';
import { adminsT } from './index';
import { userRoles, adminStatuses } from '~/utils/searchInit';
import type { Filters } from './types';

const adminsFilters = filtersFormController<Filters>({
  saveToLocalStorage: true,
  actions: [
    {
      type: 'link',
      to: { name: 'admins-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    name: '',
    roles: '',
    phone: '',
    email: '',
    statuses: '',
  },
  layout: [
    {
      type: 'input',
      name: 'name',
      props: {
        placeholder: adminsT('filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'roles',
      options: [],
      props: {
        placeholder: adminsT('filters.roles'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        remoteMethod: userRoles,
      },
    },
    {
      type: 'input',
      name: 'phone',
      props: {
        placeholder: adminsT('filters.phone'),
      },
    },
    {
      type: 'input',
      name: 'email',
      props: {
        placeholder: adminsT('filters.email'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'statuses',
      options: [],
      props: {
        placeholder: adminsT('filters.statuses'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        remoteMethod: adminStatuses,
      },
    },
  ],
});

export default adminsFilters;
