import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';

interface OfferList {
  id: string,
  comment: string,
  likeCount: number,
  author: string,
  createdAt: string,
}

const BASE = '/pet';

export const list = request.custom<OfferList[]>(async ({ data }) => {
  const pagination = 'limit=100&page=1';

  const res = await getListResponse<OfferList>(`${BASE}/${data.id}/comment/?${pagination}`);

  return { data: res.data.rows || [] };
});

export const removeComment = request.card(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.delete(`${BASE}/${data.id}/comment/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return { data: res.data?.data };
  },
);
