export enum Roles {
  USER = 'ROLE_USER',
  ADMIN = 'ROLE_ADMIN',
  MANAGER = 'ROLE_MANAGER',
  MARKETER = 'ROLE_MARKETER',
  WALKER = 'ROLE_WALKER',
  SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export enum AdminStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
}

export interface RolesSelect {
  label: string,
  value: Roles,
}

export interface AdminStatusSelect { 
  label: string,
  value: AdminStatus,
}
