import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { bannersT } from './index';
import {
  get, create, update, remove, 
} from './requests';
import type { Edit } from './types';
import { searchCities, deepLinks } from '~/utils/searchInit';
import { saveImageContextImage } from '~/features/common/file-request';
import bannersTransition from './components/banners-transition.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: bannersT('card.title'),
  },

  getRequest: get as any,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  confirmDelete: true,

  form: {
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'banners' }
          : { name: 'banners-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          class: 'width-auto',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                /** Title */
                {
                  type: 'input',
                  name: 'title',
                  label: bannersT('card.form.title.title'),
                  dataType: 'string',
                  hint: bannersT('card.form.title.hint'),
                  props: {
                    placeholder: bannersT('card.form.title.placeholder'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                /** Sort */
                {
                  type: 'input',
                  name: 'sort',
                  label: bannersT('card.form.sort.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    type: 'number',
                    placeholder: bannersT('card.form.sort.placeholder'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                /** City */
                {
                  type: 'select',
                  name: 'cityId',
                  options: [],
                  label: bannersT('card.form.city.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: bannersT('card.form.city.placeholder'),
                    valueKey: 'id',
                    labelKey: 'name',
                    remote: true,
                    remoteMethod: searchCities,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                /** Date */
                {
                  type: 'datetime',
                  name: 'date',
                  label: bannersT('card.form.date.title'),
                  dataType: 'date',
                  props: {
                    required: true,
                    type: 'datetimerange',
                    startPlaceholder: 'От',
                    endPlaceholder: 'До',
                    format: 'YYYY-MM-DD HH:mm',
                    rangeSeparator: '–',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                // /** Transition */
                {
                  type: 'custom',
                  name: 'transition',
                  label: bannersT('card.form.transition.title'),
                  props: {
                    required: true,
                  },
                  component: () => bannersTransition,
                  changeAction: ({ form }) => {
                    form.link = null;
                  },
                },
                /** Link */
                {
                  type: 'input',
                  name: 'link',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: bannersT('card.form.link.placeholder'),
                    hidden: ({ state }) => state.transition === 'app',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                /** App */
                {
                  type: 'select',
                  name: 'link',
                  options: [],
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: bannersT('card.form.app.placeholder'),
                    valueKey: 'value',
                    labelKey: 'label',
                    remote: true,
                    remoteMethod: deepLinks,
                    hidden: ({ state }) => state.transition !== 'app',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'dropzone',
                  label: bannersT('card.form.image.title'),
                  name: 'imageId',
                  props: {
                    required: true,
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImageContextImage,
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [],
        },
      ],
    },
  },
});
