import { filtersFormController } from 'magner';
import { baseT } from 'configs/translation/base';
import { t } from 'features/users/clients/ru';
import { servicesT } from './index';
import { searchCities, searchOfferStatuses } from '~/utils/searchInit';
import { Filters } from './types';
import { statuses } from '~/utils/list';

const servicesFilters = filtersFormController<Filters>({
  saveToLocalStorage: true,
  actions: [
    {
      type: 'link',
      to: { name: 'services-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    cities: '',
    statuses: '',
  },
  layout: [
    {
      type: 'select',
      name: 'cities',
      options: [],
      props: {
        placeholder: t('filters.city'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: searchCities,
      },
    },
    {
      type: 'select',
      name: 'statuses',
      options: statuses,
      props: {
        placeholder: servicesT('filters.status'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'value',
        labelKey: 'label',
        remoteMethod: searchOfferStatuses,
      },
    },
  ],
});

export default servicesFilters;
