import { Client, ClientCreate } from 'features/users/clients';
import { magnerMessage } from 'magner';
import { format } from 'date-fns';
import { request } from '~/utils/request';
import {
  type Walkers, type WalkersCreate, type WalkersList, WalkerStatuses, 
} from './types';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { filterURLBuilder } from '~/utils/filter-builder';
import { offerCategories } from '~/utils/searchInit';

const BASE = '/walker';

export const read = request.table<WalkersList>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const name = data.filters.name ? `&filters[0][id]=name&filters[0][value]=${data.filters.name}` : '';
  const cities = data.filters.cities.length ? filterURLBuilder(data, 1, 'cities') : '';
  const status = data.filters.status ? `&filters[2][id]=statuses&filters[2][value]=${data.filters.status}` : '';
  const phone = data.filters.phone ? `&filters[3][id]=phone&filters[3][value]=${encodeURIComponent(data.filters.phone)}` : '';
  const email = data.filters.email ? `&filters[4][id]=email&filters[4][value]=${encodeURIComponent(data.filters.email)}` : '';
  const telegram = data.filters.telegram
    ? `&filters[5][id]=telegram&filters[5][value]=${encodeURIComponent(
      data.filters.telegram,
    )}`
    : '';
  const rating = data.filters.rating
    ? `&filters[6][id]=ratingFrom&filters[6][value]=${data.filters.rating}&filters[7][id]=ratingTo&filters[7][value]=5`
    : '';

  const nameSort = data.sort.name ? `&sort[0][id]=name&sort[0][value]=${data.sort.name}` : '';
  const surnameSort = data.sort.surname ? `&sort[1][id]=surname&sort[1][value]=${data.sort.surname}` : '';
  const citySort = data.sort.city ? `&sort[2][id]=city&sort[2][value]=${data.sort.city}` : '';
  const ratingSort = data.sort.rating ? `&sort[3][id]=rating&sort[3][value]=${data.sort.rating}` : '';
  const servicesSort = data.sort.services ? `&sort[4][id]=services&sort[4][value]=${data.sort.services}` : '';
  const statusSort = data.sort.status ? `&sort[5][id]=status&sort[5][value]=${data.sort.status}` : '';

  return getListResponse<WalkersList>(`${BASE}?${pagination}${name}${cities}${telegram}${phone}${status}${rating}${email}${nameSort}${surnameSort}${citySort}${ratingSort}${servicesSort}${statusSort}`, activePage);
});

export const search = request.custom<WalkersList[], string>(async ({ data }) => {
  const pagination = 'limit=50&page=1';
  const nameFilter = data.search ? `&filters[0][id]=name&filters[0][value]=${data.search}` : '';
  const status = '&filters[1][id]=statuses&filters[1][value]=active';

  const res = await getListResponse<WalkersList>(`${BASE}?${pagination}${nameFilter}${status}`);

  return {
    data: res.data.rows,
  };
});

export const get = request.card<Walkers, WalkersCreate>(async ({
  api, data, parseError,
}) => {
  const res = await api.get<{ data: Walkers }>(`${BASE}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const isModeration = res.data.data.status.value === WalkerStatuses.moderation;

  // eslint-disable-next-line no-nested-ternary
  const tag = res.data?.data.status.value === WalkerStatuses.registration
    ? 'Регистрация'
    : isModeration
      ? 'Модерация'
      : '';
  
  const result = res.data.data;

  if (isModeration) {
    const keys = [
      'birthdate',
      'email',
      'experience',
      'interests',
      'motivation',
      'pets',
      'photo',
      'video',
      'vk',
      'offerCategories',
    ];

    keys.forEach((key) => {
      if (Object.hasOwn(result.profileDataOnModeration, key)) {
        (result as any)[key] = (result.profileDataOnModeration as any)?.[key as any] || null;
      }
    });
  }
  
  return {
    data: {
      ...result,
      tag,
      services: result.services?.join(', '),
    },
  };
});

export const create = request.card<any, any>(async ({
  api, data, parseError, router,
}) => {
  try {
    filterRequestStrings(data.data);

    const { tag, profileDataOnModeration, ...sendData } = data.data;

    if (Array.isArray(sendData.photo) || sendData.photo?.id) {
      if (sendData.photo?.id) {
        sendData.photoId = sendData.photo.id;
      }
      else {
        sendData.photoId = sendData.photo[0] || null;
      }
    }

    delete sendData.photo;

    const res = await api.post<{ data: any }>(`${BASE}/create`, {
      ...sendData,
      birthdate: data.data.birthdate ? format(new Date(data.data.birthdate), 'yyyy-MM-dd') : null,
    });

    if (res.error) {
      return { error: parseError(res.error) };
    }

    router.push({ name: 'walkers' });

    return res;
  }
  catch (error) {
    console.error(error);
  }
});

export const update = request.card<any, any>(async (
  {
    api, data, parseError, router,
  },
) => {
  filterRequestStrings(data.data);

  if (typeof data.data?.status === 'object') {
    data.data.status = data.data?.status.value;
  }

  const { tag, profileDataOnModeration, ...sendData } = data.data;

  if (Array.isArray(sendData.photo) || sendData.photo?.id) {
    if (sendData.photo?.id) {
      sendData.photoId = sendData.photo.id;
    }
    else {
      sendData.photoId = sendData.photo[0] || null;
    }
  }

  delete sendData.photo;
  
  const res = await api.patch<{ data: Walkers }>(`${BASE}/update/${data.id}`, {
    ...sendData,
    birthdate: data.data.birthdate
      ? format(new Date(data.data.birthdate), 'yyyy-MM-dd')
      : null,
    city: data.data.city?.id || data.data.city,
    offers: data.data.offers?.map((offer: any) => offer.id) || [],
    offerCategories: data.data.offerCategories?.map((offer: any) => offer.value) || [],
  });

  if (res.error) {
    return { error: parseError(res.error) };
  }
  await router.push({ name: 'walkers' });

  return res;
});

export const tableStatusUpdate = request.card<Client, ClientCreate>(async ({
  api, data, parseError,
}) => {
  const res = await api.patch<{ data: Client }>(`${BASE}/update/${data.id}`, data);

  if (res.error) {
    magnerMessage({
      type: 'error',
      message: 'Ошибка',
    });
    return { error: parseError(res.error) };
  }

  magnerMessage({
    type: 'success',
    message: 'Статус сохранен',
  });

  return { data: {} };
});

export const updateStatus = request.card<Walkers, { status: WalkerStatuses }>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.patch<{ data: Walkers }>(
      `${BASE}/update/${data.id}`,
      {
        ...data.data,
      },
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    window.location.reload();

    return res;
  },
);
