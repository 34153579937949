<template>
  <el-select
    v-model="selected"
    :disabled="disabled || items.length === 1"
    @change="emit('update:modelValue', $event);"
  >
    <el-option
      v-for="item in items"
      :key="item"
      :value="item"
    >
      {{ item }}
    </el-option>
  </el-select>
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, computed, watch, onMounted,
} from 'vue';

const props = defineProps({
  form: {
    type: Object as PropType<any>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<number>,
    required: true,
  },
}); 

const emit = defineEmits(['update:modelValue']);

const selected = ref(props.modelValue || 1);

const disabled = computed(() => props.form.maxNumberOfPets <= 0 || !Number.isInteger(+props.form.maxNumberOfPets));

const items = computed(() => {
  if (disabled.value) {
    return [1];
  }

  return new Array(+props.form.maxNumberOfPets).fill(1).map((_, i) => i + 1);
});

watch(() => props.form.maxNumberOfPets, () => {
  selected.value = 1;

  emit('update:modelValue', selected.value);
});

onMounted(() => {
  emit('update:modelValue', selected.value);
});
</script>
