<template>
  <div v-if="modelValue" class="process">
    <p v-if="modelValue.id === loginId">
      Вы сейчас работаете над этим заказом
    </p>
    <p v-else>
      {{ `${modelValue.name} ${modelValue.surname} сейчас работает над заказом` }}
    </p>
  </div>
  <div v-else>
    <p>&#x200B;</p>
  </div>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';

const props = defineProps({
  modelValue: {
    type: [String, Number, Array, Object],
    required: true,
  },
});

const loginId = JSON.parse(localStorage.getItem('magner-petshop') || '').id;
</script>

<style scoped>
p {
  opacity: .3;
}
</style>
