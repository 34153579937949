import {
  tableController,
  tablePageController,
} from 'magner';

import { adsPushT } from './index';
import adsPushFilters from './filters';
import { read } from './requests';
import type { List, ListType } from './types';

import adsPushStatusTable from './components/ads-push-status-table.vue';

export const tableConfig = tablePageController<List>({
  header: {
    title: adsPushT('table.title'),
  },
  filters: adsPushFilters as any,
  request: read,
  table: tableController<List>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: adsPushT('table.not_found'),
    columns: [
      {
        prop: 'code',
        label: 'Название служебное',
        sortable: true,
      },
      {
        prop: 'type',
        label: 'Отправка',
        view: {
          type: 'text',
          formatter: (row) => (row as ListType)?.label,
        },
      },
      {
        prop: 'city',
        label: 'Город',
        sortable: true,
      },
      {
        prop: 'title',
        label: 'Заголовок',
        sortable: true,
      },
      {
        prop: 'text',
        label: 'Текст',
        sortable: true,
      },
      {
        prop: 'link',
        label: 'Переход',
        sortable: true,
      },
      {
        prop: 'audience',
        label: 'Тип аудитории',
        view: {
          type: 'text',
          formatter: (row) => (row as ListType)?.label,
        },
      },
      {
        prop: 'breeds',
        label: 'Порода питомцев',
        view: {
          type: 'html',
          formatter: (row) => (row as string[]).join(', <br />'),
        },
      },
      {
        prop: 'petAge',
        label: 'Возраст питомцев',
        sortable: true,
      },
      {
        prop: 'onlyNewClients',
        label: 'Заказов еще не было',
        view: {
          type: 'text',
          formatter: (row) => (row ? 'Да' : 'Нет'),
        },
      },
      {
        prop: 'clients',
        label: 'Конкретный клиент',
        view: {
          type: 'html',
          formatter: (row) => (row as string[]).join('<br />'),
        },
      },
      {
        prop: 'status',
        label: 'Статус',
        sortable: true,
        fixed: 'right',
        view: {
          type: 'custom',
          component: () => adsPushStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'ads-push-view',
      params: { id: row.id },
    }),
  }),
});
