import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import Text from 'features/common/components/text.vue';
import { get } from './requests';
import { t } from './ru';
import { Client } from './types';
import { ClientsStatuses } from '~/types/common/clients';
import { Pet } from '~/types/common';
import { DictionaryItem } from '~/types';
import WalkersComments from '~/components/walkers-comments.vue';
import { petsTypes } from '~/utils/list';

export const cardConfig = cardPageController<Client>({
  getRequest: get,

  header: {
    title: 'Клиент',
  },

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'clients' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route) => ({ name: 'client-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          hidden: ({ state }) => state.status?.value === ClientsStatuses.removed,
          class: 'width-auto',
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Name, Surname */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: t('form.fields.name'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.name'),
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'surname',
                  label: t('form.fields.surname'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.surname'),
                    readOnly: true,
                  },
                },
              ],
            },
            /**   Phone, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                align: 'middle',
                styles: { gap: '12px', 'flex-wrap': 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'phone',
                  label: t('form.fields.phone'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.phone'),
                    readOnly: true,
                  },
                },
                {
                  type: 'select',
                  name: 'status',
                  label: t('form.fields.status'),
                  dataType: 'string',
                  options: [],
                  props: {
                    placeholder: t('form.fields.status'),
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
              ],
            },
            /**  Email, City */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                align: 'middle',
                styles: { gap: '12px' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'email',
                  label: t('form.fields.email'),
                  props: {
                    type: 'email',
                    placeholder: t('form.fields.email'),
                    readOnly: true,
                  },
                },
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: t('form.fields.city'),
                  props: {
                    placeholder: t('form.fields.city'),
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.name,
                  },
                },
              ],
            },
            /** Pets */
            {
              type: 'column',
              title: t('form.pets.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
                hidden: ({ state }) => !state.pets?.length,
              },
              fields: [
                {
                  type: 'collection',
                  name: 'pets',
                  dataType: 'array',
                  props: {
                    readOnly: true,
                  },
                  layout: [
                    /** Pets type */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'radio',
                          name: 'petType',
                          options: petsTypes,
                          dataType: 'string',
                          props: {
                            disabled: true,
                          },
                        },
                      ],
                    },
                    /** Name, Breed */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'name',
                          label: t('form.pets.fields.name'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.pets.fields.name'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'breed',
                          label: t('form.pets.fields.breed'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.pets.fields.breed'),
                            readOnly: true,
                            readOnlyFormatter: (val) => (val?.title as string) || '–',
                            hidden: (_: any, currentItem: any) => currentItem.petType !== 'dog' && currentItem.petType !== 'cat',
                          },
                        },
                      ],
                    },
                    /** Age, Weight, Gender */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'age',
                          label: t('form.pets.fields.age'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.pets.fields.age'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'weight',
                          label: t('form.pets.fields.weight'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.pets.fields.weight'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'sex',
                          label: t('form.pets.fields.gender'),
                          dataType: 'string',
                          props: {
                            readOnly: true,
                            readOnlyFormatter: (val) => (val === 'm' ? 'Мальчик' : 'Девочка'),
                          },
                        },
                      ],
                    },
                    /** Peculiarities */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'peculiaritiesReadOnly',
                          label: t('form.pets.fields.peculiarities'),
                          dataType: 'string',
                          props: {
                            readOnly: true,
                            hidden: (_, petInstance?: Pet) => !petInstance?.peculiaritiesReadOnly?.length,
                            readOnlyFormatter: (val) => {
                              const value = val as Pet['peculiarities'] as DictionaryItem[];
                              return `${value
                                .map((item) => item.title)
                                .join(', ')}`;
                            },
                          },
                        },
                      ],
                    },
                    /** Comment */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'textarea',
                          name: 'comment',
                          label: t('form.pets.fields.comment'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.pets.fields.comment'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'custom',
                          name: 'id',
                          props: {
                            hidden: (_: any, currentItem: any) => !currentItem.id,
                            readOnly: true,
                          },
                          component: () => WalkersComments,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              title: t('form.pets.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
                hidden: ({ state }) => !!state.pets?.length,
              },
              fields: [
                {
                  type: 'custom',
                  name: 'id',
                  component: () => Text,
                  props: {
                    value: 'Пока не добавлено ни одного питомца',
                  },
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
          },
          layout: [
            /** Addresses */
            {
              type: 'column',
              title: t('form.address.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
                hidden: ({ state }) => !state.addresses?.length,
              },
              fields: [
                {
                  type: 'collection',
                  name: 'addresses',
                  dataType: 'array',
                  props: {
                    readOnly: true,
                  },
                  layout: [
                    /** City, District */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'cityReadOnly',
                          label: t('form.address.fields.city'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.city'),
                            readOnly: true,
                            readOnlyFormatter: (val) => val?.name,
                          },
                        },
                        {
                          type: 'input',
                          name: 'districtReadOnly',
                          label: t('form.address.fields.district'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.district'),
                            readOnly: true,
                            readOnlyFormatter: (val) => val?.slug,
                          },
                        },
                      ],
                    },
                    /** Street, House */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'street',
                          label: t('form.address.fields.street'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.street'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'house',
                          label: t('form.address.fields.house'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.house'),
                            readOnly: true,
                          },
                        },
                      ],
                    },
                    /** Entrance, Floor, Flat, DoorCode */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'entrance',
                          label: t('form.address.fields.porch'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.porch'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'floor',
                          label: t('form.address.fields.floor'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.floor'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'office',
                          label: t('form.address.fields.flat'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.flat'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'doorCode',
                          label: t('form.address.fields.intercom'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.intercom'),
                            readOnly: true,
                          },
                        },
                      ],
                    },
                    /** Comment */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'textarea',
                          name: 'comment',
                          label: t('form.address.fields.comment'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.comment'),
                            readOnly: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              title: t('form.address.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
                hidden: ({ state }) => !!state.addresses?.length,
              },
              fields: [
                {
                  type: 'custom',
                  name: 'id',
                  component: () => Text,
                  props: {
                    value: 'Пока не добавлено ни одного адреса',
                  },
                },
              ],
            },
            /** Contacts */
            {
              type: 'column',
              title: t('form.contacts.title'),
              props: {
                span: 24,
                titleType: 'heading',
                isPaper: true,
                hidden: ({ state }) => !state.contacts?.length,
              },
              fields: [
                {
                  type: 'collection',
                  name: 'contacts',
                  dataType: 'array',
                  props: {
                    readOnly: true,
                  },
                  layout: [
                    /** Phone, Name, Surname */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'phone',
                          label: t('form.contacts.fields.phone'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.contacts.fields.phone'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'name',
                          label: t('form.contacts.fields.firstname'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.contacts.fields.firstname'),
                            readOnly: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'surname',
                          label: t('form.contacts.fields.lastname'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.contacts.fields.lastname'),
                            readOnly: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              title: t('form.contacts.title'),
              props: {
                span: 24,
                titleType: 'heading',
                isPaper: true,
                hidden: ({ state }) => !!state.contacts?.length,
              },
              fields: [
                {
                  type: 'custom',
                  name: 'id',
                  component: () => Text,
                  props: {
                    value: 'Пока не добавлено ни одного контакта',
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
