import { filtersFormController } from 'magner';
import { ClientFilters } from 'features/users/clients/types';
import { baseT } from 'configs/translation/base';
import { t } from 'features/users/clients/ru';
import { filterSearchDistricts, searchBreed, searchCities } from '~/utils/searchInit';
import { ClientsStatuses } from '~/types/common/clients';

const clientsFilters = filtersFormController<ClientFilters>({
  // saveToLocalStorage: true,
  fieldsShowAmount: 5,
  actions: [
    {
      type: 'link',
      to: { name: 'client-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
        // icon: () => import('assets/icons/add.svg'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    name: '',
    phone: '',
    email: '',
    petBreeds: '',
    petAgeFrom: '',
    petAgeTo: '',
    city: '',
    districts: '',
    statuses: '',
  },
  layout: [
    {
      type: 'input',
      name: 'name',
      props: {
        placeholder: t('filters.name'),
        inputDelay: 250,
        clearable: true,
      },
    },
    {
      type: 'input',
      name: 'phone',
      props: {
        placeholder: t('filters.phone'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'statuses',
      options: [
        {
          label: baseT('status.active'),
          value: ClientsStatuses.active,
        },
        {
          label: baseT('status.blocked'),
          value: ClientsStatuses.blocked,
        },
        {
          label: baseT('status.request'),
          value: ClientsStatuses.request,
        },
        {
          label: baseT('status.removed'),
          value: ClientsStatuses.removed,
        },
      ],
      props: {
        placeholder: t('filters.status'),
        multiple: true,
        collapseTags: true,
        clearable: true,
      },
    },
    {
      type: 'select',
      name: 'petBreeds',
      options: [],
      props: {
        placeholder: t('filters.petBreeds'),
        clearable: true,
        multiple: true,
        collapseTags: true,
        filterable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'title',
        remoteMethod: searchBreed,
      },
    },
    {
      type: 'select',
      name: 'city',
      options: [],
      props: {
        placeholder: t('filters.city'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: searchCities,
      },
      changeAction: ({ form }) => {
        form.districts = [];
      },
    },
    {
      type: 'select',
      name: 'districts',
      options: [],
      props: {
        placeholder: t('filters.districts'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        filterable: true,
        remote: true,
        loadRemoteMethodOnFocus: true,
        valueKey: 'id',
        labelKey: 'slug',
        remoteMethod: filterSearchDistricts,
      },
    },
    {
      type: 'input',
      name: 'email',
      props: {
        placeholder: t('filters.email'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'petAgeFrom',
      props: {
        placeholder: t('filters.petAgeFrom'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
    {
      type: 'input',
      name: 'petAgeTo',
      props: {
        placeholder: t('filters.petAgeTo'),
        inputDelay: 250,
        mask: {
          mask: '#*',
        },
      },
    },
  ],
});

export default clientsFilters;
