<template>
  <el-table :data="list" class="data-table">
    <el-table-column label="Название">
      <template #default="{row}">
        <div class="cell-content">{{ row.name }}</div>
      </template>
    </el-table-column>
    
    <el-table-column alight-right>
      <template #default="{row}">
        <div class="cell-content">
          <div class="custom-table__actions">
            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === 0 }"
              @click.prevent="rowUp(row, cardSortUpdate)"
            >
              <icon-up />
            </el-button>

            <el-button
              class="custom-table__btn"
              :class="{ 'custom-table__btn--disabled': findIndex(row.id) === list.length - 1 }"
              @click.prevent="rowDown(row, cardSortUpdate)"
            >
              <icon-down />
            </el-button>

            <el-button class="custom-table__btn-edit" @click="onEdit(row.id)">
              Редактировать
            </el-button>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script lang="ts" setup>
import { defineProps } from 'vue';
import { openModal } from 'magner';

import { sortTableRequest } from 'features/common/sort-table-request';
import { cardSortUpdate } from '../../requests';
import cardEdit from '../../card-edit';

import IconUp from '~/components/icon-up.vue';
import IconDown from '~/components/icon-down.vue';

import './style.css';

const props = defineProps({
  modelValue: {
    type: Array as any,
    required: true,
  },
});

const {
  list, setupSort, findIndex, rowUp, rowDown, 
} = sortTableRequest(props.modelValue);

setupSort(props.modelValue);

const onEdit = async (id: string | null) => {
  try {
    await openModal<any>({
      type: 'card',
      config: cardEdit,
      customClass: 'modal-base',
      props: {
        emptyCard: false,
        entityId: id,
      },
    });

    return false;
  }
  catch (e) {
    return false;
  }
};
</script>
