<template>
  <el-select
    v-model="selected"
    :disabled="!isCreate"
    @change="emit('update:modelValue', selected);"
  >
    <el-option
      v-for="item in items"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, onMounted,
} from 'vue';
import { searchOfferStatuses } from '~/utils/searchInit';
import type { Status } from '~/types/common/status';

const props = defineProps({
  modelValue: {
    type: Object as PropType<{ id: string, value: Status }>,
    required: true,
  },
}); 

const isCreate = ref(props.modelValue);

const emit = defineEmits(['update:modelValue']);

const selected = ref(props.modelValue?.value ?? 'active');

const items = ref((await searchOfferStatuses({})).data);

onMounted(() => {
  emit('update:modelValue', selected.value);
});
</script>
