import { routerController, translate } from 'magner';
import loginPageConfig from 'features/login/login';

import { clientTableConfig, clientCardConfig, clientEditCardConfig } from 'features/users/clients';
import { adminsTableConfig, adminsCardConfig, adminsEditCardConfig } from 'features/users/admins';
import {
  orderTableConfig,
  orderCardConfig,
  orderEditCardConfig,
  orderReportCardConfig,
  orderReportEditCardConfig,
  orderReportNotFoundCardConfig,
} from 'features/orders';
import { walkersTableConfig, walkersCardConfig } from 'features/users/walkers';
import { walkersEditCardConfig } from 'features/users/walkers/walker-edit';
import {
  servicesTableConfig,
  servicesEditCardConfig,
  servicesCardConfig,
} from 'features/catalog/services';
import {
  citiesTableConfig, citiesEditCardConfig, citiesCardConfig, citiesDistrictCardConfig, 
} from 'features/settings/cities';
import { discountsTableConfig, discountsEditCardConfig, discountsCardConfig } from '~/features/catalog/discounts';
import { bannersTableConfig, bannersEditCardConfig, bannersCardConfig } from '~/features/content/banners';
import { adsPushTableConfig, adsPushEditCardConfig, adsPushCardConfig } from '~/features/ads-push';
import {
  servicesMainTableConfig,
  servicesMainEditCardConfig,
  servicesMainCardConfig,
} from '~/features/content/services';
import { chatsTableConfig, chatsCardConfig } from '~/features/chat'; 
import { ammunitionsTableConfig } from '~/features/content/ammunitions';

import { ROLE } from '~/constants';

const router = routerController<ROLE>({
  global: {
    homeNoAuthName: 'login',
    homeHasAuthName: 'orders',
  },

  routes: [
    {
      type: 'custom',
      route: {
        name: 'home',
        path: '/',
        component: () => import('./empty.vue'),
        roles: false,
      },
    },
    {
      type: 'layout',
      layout: {
        layout: 'main',
        name: '',
        path: '/',
        props: {
          headerIcon: () => import('assets/icons/logo.svg'),
          headerCollapsedIcon: () => import('assets/icons/logo-small.svg'),
          sidebarGroups: [
            {
              name: 'users-group',
              icon: () => import('assets/icons/users.svg'),
              title: translate('base.sidebar.users_group'),
              routes: ['clients', 'walkers', 'admins'],
            },
            {
              name: 'catalog-group',
              icon: () => import('assets/icons/catalog.svg'),
              title: translate('base.sidebar.catalog_group'),
              routes: ['services', 'discounts'],
            },
            {
              name: 'content-group',
              icon: () => import('assets/icons/content.svg'),
              title: translate('base.sidebar.content_group'),
              routes: ['banners', 'services-main', 'ammunitions'],
            },
            {
              name: 'settings-group',
              icon: () => import('assets/icons/settings.svg'),
              title: translate('base.sidebar.settings_group'),
              routes: ['cities'],
            },
          ],
        },

        routes: [
          /** Orders */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: orderTableConfig,
            },
            route: {
              name: 'orders',
              path: '/orders',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: true,
              icon: () => import('assets/icons/orders.svg'),
              title: translate('base.sidebar.order_group'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderCardConfig,
            },
            route: {
              name: 'order',
              path: '/order/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
              icon: () => import('assets/icons/order.svg'),
              title: translate('base.sidebar.order_group'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderEditCardConfig,
            },
            route: {
              name: 'order-edit',
              path: '/order-edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
              icon: () => import('assets/icons/order.svg'),
              title: translate('base.sidebar.order_group'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderEditCardConfig,
            },
            route: {
              name: 'order-edit',
              path: '/order-edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
              icon: () => import('assets/icons/order.svg'),
              title: translate('base.sidebar.order_group'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderReportCardConfig,
            },
            route: {
              name: 'order-report',
              path: '/order-report/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderReportEditCardConfig,
            },
            route: {
              name: 'order-report-edit',
              path: '/order-report/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: orderReportNotFoundCardConfig,
            },
            route: {
              name: 'order-report-not-found',
              path: '/order-report/not-found/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          /** Client */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: clientTableConfig,
            },
            route: {
              name: 'clients',
              path: '/users/clients',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: true,
              title: translate('users.clients.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: clientCardConfig,
            },
            route: {
              name: 'client',
              path: '/users/clients/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: clientEditCardConfig,
            },
            route: {
              name: 'client-edit',
              path: '/client-edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
              icon: () => import('assets/icons/edit.svg'),
              title: translate('base.sidebar.notifications_group'),
            },
          },
          /** Walkers */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: walkersTableConfig,
            },
            route: {
              name: 'walkers',
              path: '/users/walkers',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: true,
              title: translate('users.walkers.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: walkersCardConfig,
            },
            route: {
              name: 'walker',
              path: '/walker/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
              icon: () => import('assets/icons/edit.svg'),
              title: translate('users.walkers.sidebar.card_title'),
            },
          },
          /** Walker Edit */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: walkersEditCardConfig,
            },
            route: {
              name: 'walker-edit',
              path: '/walker-edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
              icon: () => import('assets/icons/edit.svg'),
              title: translate('users.walkers.sidebar.card_title'),
            },
          },
          /** Admins */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: adminsTableConfig,
            },
            route: {
              name: 'admins',
              path: '/users/admins',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('users.admins.sidebar.table_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: adminsCardConfig,
            },
            route: {
              name: 'admins-view',
              path: '/users/admins/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
              icon: () => import('assets/icons/edit.svg'),
              title: translate('users.admins.sidebar.card_title'),
            },
          },
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: adminsEditCardConfig,
            },
            route: {
              name: 'admins-edit',
              path: '/users/admims/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
              icon: () => import('assets/icons/edit.svg'),
              title: translate('users.admins.sidebar.card_title'),
            },
          },

          /**
           *
           * Catalog
           *
           * */

          /** Services */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: servicesTableConfig,
            },
            route: {
              name: 'services',
              path: '/catalog/services',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('catalog.services.sidebar.table_title'),
            },
          },
          /** Services view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: servicesCardConfig,
            },
            route: {
              name: 'services-view',
              path: '/catalog/services/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Services edit */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: servicesEditCardConfig,
            },
            route: {
              name: 'services-edit',
              path: '/catalog/services/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },

          /** Discounts  */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: discountsTableConfig,
            },
            route: {
              name: 'discounts',
              path: '/catalog/discounts',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('catalog.discounts.sidebar.table_title'),
            },
          },
          /** Discounts view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: discountsCardConfig,
            },
            route: {
              name: 'discounts-view',
              path: '/catalog/discounts/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Discounts edit  */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: discountsEditCardConfig,
            },
            route: {
              name: 'discounts-edit',
              path: '/catalog/discounts/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },

          /**
           *
           * Content
           *
           * */

          /** Banners */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: bannersTableConfig,
            },
            route: {
              name: 'banners',
              path: '/content/banners',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('content.banners.sidebar.table_title'),
            },
          },
          /** Banners view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: bannersCardConfig,
            },
            route: {
              name: 'banners-view',
              path: '/content/banners/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Banners edit  */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: bannersEditCardConfig,
            },
            route: {
              name: 'banners-edit',
              path: '/content/banners/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },

          /** Services main */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: servicesMainTableConfig,
            },
            route: {
              name: 'services-main',
              path: '/content/services',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('content.services.sidebar.table_title'),
            },
          },
          /** Services main view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: servicesMainCardConfig,
            },
            route: {
              name: 'services-main-view',
              path: '/content/services/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Services main edit  */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: servicesMainEditCardConfig,
            },
            route: {
              name: 'services-main-edit',
              path: '/content/services/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },

          /** Amunitions */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: ammunitionsTableConfig,
            },
            route: {
              name: 'ammunitions',
              path: '/content/ammunitions',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('content.ammunitions.sidebar.table_title'),
            },
          },

          /**
           *
           * Chats
           *
           * */

          /** Chats list */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: chatsTableConfig,
            },
            route: {
              name: 'chats',
              path: '/chats',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: true,
              icon: () => import('assets/icons/message.svg'),
              title: translate('chats.sidebar.table_title'),
            },
          },
          /** Chats view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: chatsCardConfig,
            },
            route: {
              name: 'chats-view',
              path: '/chats/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN, ROLE.MANAGER],
              visible: false,
            },
          },

          /**
           *
           * Ads push
           *
           * */

          /** Ads push list */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: adsPushTableConfig,
            },
            route: {
              name: 'ads-push',
              path: '/ads-push',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              icon: () => import('assets/icons/ads-push.svg'),
              title: translate('ads_push.sidebar.table_title'),
            },
          },
          /** Cities view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: adsPushCardConfig,
            },
            route: {
              name: 'ads-push-view',
              path: '/ads-push/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Cities edit */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: adsPushEditCardConfig,
            },
            route: {
              name: 'ads-push-edit',
              path: '/ads-push/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },

          /**
           *
           * Settings
           *
           * */

          /** Cities */
          {
            type: 'preset',
            preset: {
              preset: 'table',
              config: citiesTableConfig,
            },
            route: {
              name: 'cities',
              path: '/settings/cities',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: true,
              title: translate('settings.cities.sidebar.table_title'),
            },
          },
          /** Cities view */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: citiesCardConfig,
            },
            route: {
              name: 'cities-view',
              path: '/settings/cities/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Cities edit */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: citiesEditCardConfig,
            },
            route: {
              name: 'cities-edit',
              path: '/settings/cities/edit/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
          /** Cities district */
          {
            type: 'preset',
            preset: {
              preset: 'card',
              config: citiesDistrictCardConfig,
            },
            route: {
              name: 'cities-district-view',
              path: '/settings/cities/district/:id',
              roles: [ROLE.SUPERADMIN, ROLE.ADMIN],
              visible: false,
            },
          },
        ],
      },
    },
    // TODO BAG: Логинится со второго раза
    {
      type: 'preset',
      preset: {
        preset: 'login',
        config: loginPageConfig,
      },
      route: {
        path: '/login',
        name: 'login',
      },
    },

    {
      type: 'preset',
      route: {
        path: '/:pathMatch(.*)*',
        name: 'error',
      },
      preset: {
        preset: '404',
        config: {},
      },
    },
  ],
});

export default router;
