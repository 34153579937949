import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { servicesT } from './index';
import {
  get, create, update, remove, 
} from './requests';
import type { Edit } from './types';
import DiscountsTransition from './components/discounts-transition.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: servicesT('card.title'),
  },

  getRequest: get as any,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'services-main' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route): any => ({ name: 'services-main-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          class: 'width-auto',
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                /** Name */
                {
                  type: 'input',
                  name: 'title',
                  label: servicesT('card.form.name.title'),
                  dataType: 'string',
                  hint: servicesT('card.form.name.hint'),
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                /** Sort */
                {
                  type: 'input',
                  name: 'sort',
                  label: servicesT('card.form.sort.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'isActive',
                  label: 'Статус',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => (val === 'active' ? 'Активен' : 'Неактивен'),
                  },
                },
              ],
            },
            {
              type: 'column',
              props: {},
              fields: [
                /** City */
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: baseT('label.city'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.name,
                  },
                },
              ],
            },
            {
              type: 'column',
              title: 'Переход',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'transition',
                  props: {
                    readOnly: true,
                  },
                  component: () => DiscountsTransition,
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'dropzone',
                  label: servicesT('card.form.image.title'),
                  name: 'imageId',
                  props: {
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    readOnly: true,
                    inputAtts: {
                      accept: 'image/svg+xml',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
