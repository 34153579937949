import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { ammunitionsT } from './index';

import {
  create,
  get,
  remove,
  update,
} from './requests';

const cardConfig = cardPageController<any>({
  getRequest: get,
  createRequest: create,
  updateRequest: update,
  deleteRequest: remove,

  header: {
    title: 'Редактировать',
    newTitle: 'Новый пункт',
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: baseT('form.submit_button'),
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          text: baseT('form.remove_button'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'name',
          label: ammunitionsT('form.name.title'),
          props: {
            required: true,
            placeholder: ammunitionsT('form.name.placeholder'),
          },
          validation: [
            {
              type: 'empty',
              trigger: 'blur',
            },
          ],
        },
      ],
    },
  },
});

export default cardConfig;
