<template>
  <chat-card 
    v-if="route.query?.type === 'client_admin' || route.query?.type === 'walker_admin' || route.query.type === 'client_walker_admin'" 
  />

  <chat-types v-else />
</template>

<script lang="ts" setup>
import { useRoute } from 'vue-router';

import ChatCard from './chat-card.vue';
import ChatTypes from './chat-types.vue';

const route = useRoute();

</script>
