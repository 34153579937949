import { request } from '~/utils/request';
import { getListResponse, sortTable, filtersTable } from '~/utils/get-list';
import type {
  List, Filters, OfferRead, OfferEdit, 
} from './types';
import type { ValueLabel } from '~/types/common';

const BASE = '/offer';

const saveFormatter = (data: OfferEdit, type: 'create' | 'update' = 'create') => {
  const unit = 100;

  const offerOptions = data.offerOptions.map((item: any) => ({
    ...item,
    price: item.price * unit,
    walkerFee: item.walkerFee * unit,
  }));

  const offerRates = data.offerRates.map((item: any) => ({
    ...item,
    price: item.price * unit,
    walkerFee: item.walkerFee * unit,
    additionalPetPrice: item.additionalPetPrice * unit,
    additionalPetWalkerFee: item.additionalPetWalkerFee * unit,
  }));

  if (type === 'update') {
    const existsOptions = offerOptions.filter((item: any) => item.id);
    const newOptions = offerOptions.filter((item: any) => !item.id);

    const existsRates = offerRates.filter((item: any) => item.id);
    const newRates = offerRates.filter((item: any) => !item.id);

    const existsTypes = data.offerTypes.filter((item: any) => item.id);
    const newTypes = data.offerTypes.filter((item: any) => !item.id);

    return {
      ...data,

      category: (data.category as ValueLabel)?.value || data.category,

      offerOptions: existsOptions,
      newOfferOptions: newOptions,

      offerRates: existsRates,
      newOfferRates: newRates,

      offerTypes: existsTypes,
      newOfferTypes: newTypes,
    };
  }

  return {
    ...data,
    offerOptions,
    offerRates,
  };
};

export const read = request.table<List>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sort = sortTable(data.sort, ['title', 'city', 'status', 'maxNumberOfPets', 'option']);

  const filters = filtersTable(data.filters as Filters, ['cities', 'statuses']);

  return getListResponse<List>(`${BASE}?${pagination}${sort}${filters}`, activePage);
});

export const get = request.card<OfferRead, OfferEdit>(async ({ api, data, parseError }) => {
  const res = await api.get(`${BASE}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data?.data,
      offerOptions: res.data?.data.offerOptions.map((item: any) => ({
        ...item,
        price: item.price.inRubles,
        walkerFee: item.walkerFee.inRubles,
      })),
      offerRates: res.data?.data.offerRates
        .map((item: any) => ({
          ...item,
          price: item.price.inRubles,
          walkerFee: item.walkerFee.inRubles,
          additionalPetPrice: item.additionalPetPrice.inRubles,
          additionalPetWalkerFee: item.additionalPetWalkerFee.inRubles,
        })),
    },
  };
});

export const create = request.card<OfferRead, OfferEdit>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.post(`${BASE}/create`, { ...saveFormatter(data.data), status: 'active' });

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'services' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<OfferRead, OfferEdit>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.patch<{ data: any }>(`${BASE}/update/${data.id}`, {
      ...saveFormatter(data.data, 'update'),
      city: data.data.city?.id || data.data.city,
    });

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'services-view', params: { id: data.id as string } });

    return {
      data: res.data.data,
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'services' });

    return { data: res.data?.data };
  },
);

export const updateStatus = request.card<OfferRead, OfferEdit>(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.patch(`${BASE}/update/${data.id}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data?.data,
    };
  },
);
