<template>
  <div style="min-width: 160px !important;">
    <el-select
      v-model="model"
      :class="className"
      :disabled="row.type.value === Types.once"
      @click.prevent
      @change="onChange"
    >
      <el-option
        v-for="item in items"
        :key="item?.value"
        :label="item?.label"
        :value="item?.value"
      />
    </el-select>
  </div>
</template>
    
<script lang="ts" setup>
import {
  defineProps, PropType, ref, computed, watch,
} from 'vue';
  
import { adsPushStatusesList } from '~/utils/list';
import { Statuses, Types, type List } from '../types';
import { updateStatus } from '../requests';
  
const props = defineProps({
  row: {
    type: Object as PropType<List>,
    required: true,
  },
});
    
const model = ref(props.row.status.value);
    
const items = computed(() => {
  if (props.row.type.value === Types.once) {
    return [
      adsPushStatusesList.find((item) => item.value === Statuses.planned),
      adsPushStatusesList.find((item) => item.value === Statuses.sent),
    ];
  }

  return [
    adsPushStatusesList.find((item) => item.value === Statuses.active),
    adsPushStatusesList.find((item) => item.value === Statuses.cancelled),
  ];
});
    
const className = computed(() => {
  if (model.value === Statuses.active) {
    return 'status-green';
  }

  if (model.value === Statuses.cancelled) {
    return 'status-red';
  }

  return '';
});
  
const onChange = async (value: boolean) => {
  await updateStatus({ id: props.row.id, data: { status: value } });
};
  
watch(
  () => props.row.status, 
  () => {
    model.value = props.row.status.value;
  },
);
</script>
