import { ft } from '~/utils/feature-translate';

export const ChatsRu = {
  sidebar: {
    table_title: 'Чаты',
    card_title: 'Чаты',
  },
  table: {
    title: 'Чаты',
    not_found: 'Чатов не найдено',
    headers: {
      from: 'От кого',
      to: 'Кому',
      type: 'Тип',
      lastMessage: 'Последнее сообщение',
      createdAt: 'Дата',
    },
  },
};

export const t = ft(ChatsRu, 'chats.');
