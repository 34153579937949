<template>
  <div class="pets-select">
    <MagnerFormRadio
      v-if="!disabled"
      v-model="pet.type"
      :field="{
        props: { class: 'ml-4' },
        options: [{
          label: 'Новый',
          value: 'new',
        }, {
          label: 'Есть в базе',
          value: 'existing',
        }],
      }"
      @update:model-value="toggleRadio"
    />

    <!-- Existing -->
    <template v-if="pet.type === 'existing'">
      <div v-if="!disabled" class="row">
        <MagnerFormSelect
          :key="user.existingId + selectUpdaterKey + isEmptyPets"
          v-model="pet.existingId"
          :field="{
            props: {
              disabled: !user.existingId || (user.existingId && isEmptyPets),
              placeholder: selectPlaceholder,
              valueKey: 'id',
              labelKey: 'name',
              filterable: true,
              remote: true,
              remoteMethod: petsSearch,
              class: 'pets-selector'
            },
          }"
          @update:model-value="updateExisting"
        />
      </div>
      <div v-if="petSelected">
        <div class="pets-select__blocks">
          <MagnerFormRadio
            v-model="petSelected.petType.value"
            :field="fields.petTypeReadonly"
          />
        </div>

        <div class="pets-select__blocks">
          <div>
            <p>Имя</p>
            <MagnerFormInput
              v-model="petSelected.name"
              :field="fields.nameReadonly"
            />
          </div>
          <div v-if="petSelected.petType.value === 'dog' || petSelected.petType.value === 'cat'">
            <p>Порода</p>
            <MagnerFormSelect
              v-model="petSelected.breed"
              :field="fields.breedReadonly"
            />
          </div>
        </div>
        <div class="pets-select__blocks pets-select__blocks_sub-info">
          <div class="pets-select__block">
            <p>Дата рождения</p>
            <MagnerFormDateTime
              v-model="petSelected.birthdate"
              :field="fields.birthdateReadonly"
            />
          </div>
          <div class="pets-select__block">
            <p>Вес</p>
            <MagnerFormInput
              v-model="petSelected.weight"
              :field="fields.weightReadonly"
            />
          </div>
          <div>
            <p>Пол</p>
            <MagnerFormRadio
              v-model="petSelected.sex"
              :field="fields.sexReadonly"
            />
          </div>
        </div>
        <div class="pets-select__blocks">
          <div>
            <p>Особенности</p>
            <MagnerFormSelect
              v-model="petSelected.peculiarities"
              :field="fields.peculiaritiesReadonly"
            />
          </div>
        </div>

        <div class="pets-select__blocks">
          <div>
            <p>Комментарий владельца</p>
            <MagnerFormInput
              v-model="petSelected.comment"
              :field="fields.commentReadonly"
            />
          </div>
        </div>
        <div class="pets-select__blocks">
          <div>
            <p>Комментарии выгульщиков</p>

            <div v-if="comments?.data?.length" class="w-full">
              <div v-for="item in comments.data" :key="item.id" class="pets-select__comment">
                <MagnerFormInput
                  :model-value="item.comment"
                  :field="fields.commentReadonly"
                />

                <el-tooltip>
                  <template #content>
                    <div>{{ item.author }}</div>
                    
                    <div class="text-sm">{{ formatDate(item.createdAt) }}</div>

                    <div class="text-sm">Нравится: {{ item.likeCount }}</div>
                  </template>

                  <el-icon color="rgba(223, 226, 232, 1)" class="pets-select__comment-hint">
                    <InfoFilled />
                  </el-icon>
                </el-tooltip>
              </div>
            </div>

            <div v-else class="mt-3 color-gray">Нет комментариев</div>
          </div>
        </div>
      </div>
    </template>

    <!-- New -->
    <template v-else>
      <div class="pets-select__blocks pets-select__blocks--bt" :class="{ 'is-success': pet.newEntity.petType }">
        <MagnerFormRadio
          v-model="pet.newEntity.petType"
          :field="fields.petType"
          @update:model-value="
            pet.newEntity.breedId = null; 
            updateValue();
          "
        />
      </div>

      <div class="pets-select__blocks">
        <div :class="{ 'is-success': pet.newEntity.name }">
          <p>Имя <sup>*</sup></p>
          <MagnerFormInput
            v-model="pet.newEntity.name"
            :field="fields.name"
            @update:model-value="updateValue"
          />
        </div>
        <div v-if="pet.newEntity.petType === 'dog'" :class="{ 'is-success': pet.newEntity.breedId }">
          <p>Порода <sup>*</sup></p>
          <MagnerFormSelect
            v-model="pet.newEntity.breedId"
            :field="{
              props: {
                filterable: true,
                placeholder: 'Выбрать',
                labelKey: 'title',
                valueKey: 'id',
                remote: true,
                remoteMethod: searchBreedDogs,
              },
            }"
            @update:model-value="updateValue"
          />
        </div>

        <div v-else-if="pet.newEntity.petType === 'cat'" :class="{ 'is-success': pet.newEntity.breedId }">
          <p>Порода <sup>*</sup></p>
          <MagnerFormSelect
            v-model="pet.newEntity.breedId"
            :field="{
              props: {
                filterable: true,
                placeholder: 'Выбрать',
                labelKey: 'title',
                valueKey: 'id',
                remote: true,
                remoteMethod: searchBreedCats,
              },
            }"
            @update:model-value="updateValue"
          />
        </div>
      </div>
      <div class="pets-select__blocks pets-select__blocks_sub-info">
        <div class="pets-select__block" :class="{ 'is-success': pet.newEntity.birthdate }">
          <p>Дата рождения <sup>*</sup></p>
          <MagnerFormDateTime
            v-model="pet.newEntity.birthdate"
            :field="fields.birthdate"
            @update:model-value="updateValue"
          />
        </div>
        <div class="pets-select__block" :class="{ 'is-success': pet.newEntity.weight }">
          <p>Вес <sup>*</sup></p>
          <MagnerFormInput
            v-model="pet.newEntity.weight"
            :field="fields.weight"
            @update:model-value="updateValue"
          />
        </div>
        <div class="sex" :class="{ 'is-success': pet.newEntity.sex }">
          <p>Пол <sup>*</sup></p>
          <MagnerFormRadio
            v-model="pet.newEntity.sex"
            :field="fields.sex"
            @update:model-value="updateValue"
          />
        </div>
      </div>
      <div class="pets-select__blocks">
        <div class="no-error-input-styles">
          <p>Особенности</p>
          <MagnerFormSelect
            v-model="pet.newEntity.peculiarities"
            :field="fields.peculiarities"
            @update:model-value="updateValue"
          />
        </div>
      </div>
      <div class="pets-select__blocks">
        <div class="no-error-input-styles">
          <!-- TODO: добавить в api -->
          <p>Комментарий владельца</p>
          <MagnerFormInput
            v-model="pet.newEntity.comment"
            :field="fields.comment"
            @update:model-value="updateValue"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  reactive,
  computed,
  ref, onMounted, watch,
} from 'vue';
import {
  MagnerFormRadio,
  MagnerFormSelect,
  MagnerFormInput,
  MagnerFormDateTime,
} from 'magner';
import { clientSimpleGet } from 'features/users/clients';
import { SelectOrCreate } from 'features/orders/types';
import { InfoFilled } from '@element-plus/icons-vue';
import {
  searchBreed, searchBreedCats, searchBreedDogs, searchPeculiarities, 
} from '~/utils/searchInit';
import { Pet, PetCreateDto, WalkingStatuses } from '~/types/common';
import { list as commentList } from '~/api/pets';
import { formatDate } from '~/utils/date-transform';
import { petsTypes } from '~/utils/list';

const props = defineProps({
  form: {
    type: Object as PropType<Record<string, any>>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<SelectOrCreate<Pet, PetCreateDto>>,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'input']);

const comments = ref();

const selectUpdaterKey = computed(() => props.form.petsEdit
  .reduce((acc: any, item: { pet: { existingId: string } }) => {
    if (item.pet.existingId) {
      acc += item.pet.existingId;
    }

    return acc;
  }, ''));

const user = computed(() => props.form.user);
const status = computed(() => props.form.status);

const disabled = computed(() => status.value === WalkingStatuses.walking
  || status.value === WalkingStatuses.completed
  || status.value === WalkingStatuses.canceled);

const pet = reactive({
  type: props.modelValue?.type ? props.modelValue.type : 'new',
  existingId: props.modelValue?.existingId ? props.modelValue.existingId : null,
  newEntity: props.modelValue?.newEntity ? props.modelValue.newEntity : {
    name: '',
    breedId: '',
    birthdate: '',
    weight: '',
    sex: '',
    peculiarities: [],
    comment: '',
    petType: 'dog',
  },
});

const petSelected = ref<Pet | null>(null);

const updateExisting = async () => {
  if (!props.form.user.existingId) {
    return;
  }

  const clientData = await clientSimpleGet({ id: props.form.user.existingId, isNew: false, data: {} as any });
  if (clientData.data) {
    petSelected.value = clientData.data.pets.find((p) => p.id === pet.existingId) || null;
    if (petSelected.value) {
      petSelected.value.peculiarities = petSelected.value?.peculiarities.length ? petSelected.value.peculiarities.map((item) => item.id) : [];
    }

    emit('update:modelValue', pet);
  }

  if (props.modelValue.existingId) {
    comments.value = await commentList({ id: props.modelValue.existingId });
  }
};

const updateValue = () => {
  emit('update:modelValue', pet);
};

const toggleRadio = () => {
  if (pet.type === 'new') {
    updateValue();
  }
  else {
    updateExisting();
  }
};

const isEmptyPets = ref<boolean>();

const selectPlaceholder = computed(() => {
  if (user.value?.type === 'new') {
    return 'Для нового клиента нет питомцев в базе';
  }

  if (!user.value?.existingId) {
    return 'Выберите клиента из базы';
  }

  if (user.value?.existingId && isEmptyPets.value) {
    return 'Питомцев у выбранного клиента не найдено';
  }

  return 'Выбрать';
});

const petsSearch = async () => {
  if (user.value.existingId) {
    const clientData = await clientSimpleGet({ id: user.value.existingId, isNew: false, data: {} as any });

    if (clientData.data) {
      isEmptyPets.value = !clientData.data.pets.length;

      // TODO требует доработки
      clientData.data.pets.forEach((petFromBack) => {
        if (props.form.petsEdit.some((item: any) => petFromBack.id === item.pet.existingId)) {
          petFromBack.disabled = true;
        }
      });

      return { data: clientData.data.pets };
    }
  }
  isEmptyPets.value = true;
  return { data: [] };
};

onMounted(async () => {
  if (pet.type === 'existing') {
    updateExisting();
  } 
});

// Если пользователь изменил клиента из базы на другого ИЛИ выбрал нового, cбрасываем поля питомцев
watch(() => props.form.user.existingId, () => {
  petSelected.value = null;
  pet.existingId = null;
  updateValue();
});

const fields = {
  name: {
    props: {
      placeholder: 'Жорж',
    },
  },
  nameReadonly: {
    props: {
      placeholder: 'Жорж',
      disabled: true,
    },
  },
  petType: {
    options: petsTypes,
    props: {},
  },
  petTypeReadonly: {
    options: petsTypes,
    props: {
      disabled: true,
    },
  },
  breed: {
    props: {
      filterable: true,
      placeholder: 'Выбрать',
      labelKey: 'title',
      valueKey: 'id',
      remote: true,
      remoteMethod: searchBreed,
    },
  },
  breedReadonly: {
    props: {
      labelKey: 'title',
      valueKey: 'id',
      remote: true,
      remoteMethod: searchBreed,
      disabled: true,
    },
  },
  birthdate: {
    dataType: 'date',
    props: {
      format: 'DD.MM.YYYY',
      valueFormat: 'YYYY-MM-DD',
      placeholder: '01.01.2020',
      disabledDate: (time: Date) => {
        const allowedDateOfBirth = new Date();
        // 4 месяца минимальный возраст питомца для регистрации
        allowedDateOfBirth.setMonth(allowedDateOfBirth.getMonth() - 4);
        return time.getTime() > allowedDateOfBirth.getTime();
      },
    },
  },
  birthdateReadonly: {
    dataType: 'date',
    props: {
      disabled: true,
      format: 'DD.MM.YYYY',
      valueFormat: 'YYYY-MM-DD',
      placeholder: '01.01.2020',
    },
  },
  weight: {
    dataType: 'number',
    props: {
      placeholder: 'Введите вес в кг',
    },
  },
  weightReadonly: {
    dataType: 'number',
    props: {
      disabled: true,
      placeholder: 'Введите вес в кг',
    },
  },
  sex: {
    options: [
      {
        label: 'Мальчик',
        value: 'm',
      },
      {
        label: 'Девочка',
        value: 'f',
      },
    ],
    props: {
      placeholder: 'XX лет',
      radioButtons: true,
    },
  },
  sexReadonly: {
    options: [
      {
        label: 'Мальчик',
        value: 'm',
      },
      {
        label: 'Девочка',
        value: 'f',
      },
    ],
    props: {
      disabled: true,
      placeholder: 'XX лет',
      radioButtons: true,
    },
  },
  peculiarities: {
    options: [],
    props: {
      filterable: true,
      placeholder: 'Выбрать',
      labelKey: 'title',
      valueKey: 'id',
      multiple: true,
      remote: true,
      remoteMethod: searchPeculiarities,
    },
  },
  peculiaritiesReadonly: {
    options: [],
    props: {
      disabled: true,
      labelKey: 'title',
      valueKey: 'id',
      multiple: true,
      remote: true,
      remoteMethod: searchPeculiarities,
    },
  },
  comment: {
    props: {
    },
  },
  commentReadonly: {
    props: {
      readOnly: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
.pets-select {
  width: 100%;
}

.pets-select__blocks {
  display: flex;
  gap: 12px;
  padding-top: 8px;
  margin-bottom: 4px;
}

.pets-select__blocks--bt {
  border-top: 1px solid #ebeef5;
  margin-top: 8px;
}

.pets-select__blocks p {
  margin: 0 0 8px;
}

.pets-select__blocks > div {
  width: 100%;
}

sup {
  color: red;
  opacity: .6;
  font-size: 14px;
}
</style>

<style lang="postcss">
.pets-select__comment {
  margin-bottom: 8px;
  position: relative;

  &:last-child {
    margin-bottom: 0px;
  }
}

.pets-select__comment-hint {
  position: absolute !important;
  top: 10px;
  right: 8px;
}

.pets-select__comment .readonly-block {
  padding-right: 28px !important;
}

.pets-select__blocks_sub-info {
  .el-radio-group {
    flex-wrap: nowrap !important;
  }

  .el-date-editor {
    width: auto !important;
  }

  @media (max-width: 1236px) {
    flex-wrap: wrap;

    & > div.pets-select__block {
      width: calc(50% - 6px) !important;
    }
  }

  @media (max-width: 767px) {
    flex-wrap: nowrap;

    & > div.pets-select__block {
      width: 100% !important;
    }
  }

  @media (max-width: 480px) {
    flex-wrap: wrap;

    & > div.pets-select__block {
      width: calc(50% - 6px) !important;
    }
  }
}

.collection-petsEdit .is-success .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

.collection-petsEdit .is-success .el-radio-button__original-radio:checked+.el-radio-button__inner {
  background-color: #12b052 !important;
  color: #f2f2f2 !important;
  border-color: var(--el-radio-button-checked-border-color, var(--el-color-primary)) !important;
  box-shadow: -1px 0 0 0 var(--el-radio-button-checked-border-color, var(--el-color-primary)) !important;
}

.collection-petsEdit .is-success .el-radio-button__inner {
  border: var(--el-border) !important;
}
</style>
