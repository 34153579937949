<template>
  <el-select
    v-model="selected"
    @change="changeHandler"
    @click.prevent
  >
    <el-option
      v-for="item in items"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="(item.value === ClientsStatuses.removed) && !roleChecker(ROLE.SUPERADMIN)"
    />
  </el-select>
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, 
} from 'vue';
import { magnerConfirm } from 'magner';
import { ClientsStatuses } from '~/types/common/clients';
import { roleChecker } from '~/utils/role-checker';
import { ROLE } from '~/constants';

const props = defineProps({
  modelValue: {
    type: Object as PropType<ClientsStatuses>,
    required: true,
  },
});

const selected = ref(props.modelValue || null);

const emit = defineEmits(['update:modelValue']);

const items = [
  {
    label: 'Активный',
    value: ClientsStatuses.active,
  },
  {
    label: 'Заблокирован',
    value: ClientsStatuses.blocked,
  },
  {
    label: 'Запрос на удаление',
    value: ClientsStatuses.request,
  },
  {
    label: 'Удален',
    value: ClientsStatuses.removed,
  },
];

const changeHandler = (value: ClientsStatuses) => {
  if (value === ClientsStatuses.removed) {
    magnerConfirm({
      message: 'Вы уверены, что хотите удалить клиента? Данное действие необратимо.',
      title: 'Внимание',
      confirmButtonText: 'Да, удалить',
      cancelButtonText: 'Отмена',
      type: 'warning',
    }).then(() => {
      emit('update:modelValue', value);
    }).catch(() => {
      selected.value = props.modelValue;
    });
    return;
  }
  if (value === ClientsStatuses.blocked) {
    magnerConfirm({
      message: 'Вы уверены, что хотите заблокировать клиента?',
      title: 'Внимание',
      confirmButtonText: 'Да, заблокировать',
      cancelButtonText: 'Отмена',
      type: 'warning',
    }).then(() => {
      emit('update:modelValue', value);
    }).catch(() => {
      selected.value = props.modelValue;
    });
    return;
  }

  emit('update:modelValue', value);
};

</script>

<style scoped>

</style>
