<template>
  <div class="chat-types">
    <div class="chat-types__title">Выберите тип</div>

    <div class="chat-types__btn">
      <a :href="link('client_admin')">
        <ElButton
          type="primary"
          size="large"
          @click="router.replace({ query: { type: 'client_admin' } })"
        >
          Чат с клиентом
        </ElButton>
      </a>
    </div>

    <div class="chat-types__btn">
      <a :href="link('walker_admin')">
        <ElButton
          type="primary"
          size="large"
          @click="router.replace({ query: { type: 'walker_admin' } })"
        >
          Чат с выгульщиком
        </ElButton>
      </a>
    </div>

    <div class="chat-types__btn">
      <a :href="link('client_walker_admin')">
      
        <ElButton
          type="primary"
          size="large"
          @click="router.replace({ query: { type: 'client_walker_admin' } })"
        >
          Чат с клиентом и выгульщиком
        </ElButton>
      </a>
    </div>

    <div class="chat-types__footer">
      <router-link :to="{ name: 'chats' }" class="chat-types__footer-button">
        <el-button>К списку</el-button>
      </router-link>

      <router-link :to="{ name: 'order', params: { id: route.params.id } }" class="chat-types__footer-button">
        <el-button>К заказу</el-button>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const link = (type: string) => `/chats/${route?.params?.id}?type=${type}`;
</script>

<style>
.chat-types {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;;
  flex-direction: column;
  height: 100%;
}

.chat-types__title {
  margin-bottom: 12px;
}

.chat-types__btn {
  width: 300px
}

.chat-types__footer {
    max-width: 300px;
  display: flex;
  width: 100%;
  gap: 8px;
}

.chat-types__footer-button {
 width: 50%;
}
</style>
