import { request } from '~/utils/request';
import { getListResponse, sortTable, filtersTable } from '~/utils/get-list';
import type {
  List, Filters, Read, Edit, UpdateStatus,
} from './types';
import type { City, ValueLabel } from '~/types/common';
import { deepLinks } from '~/utils/searchInit';
import { toDateISOString, toDateLocaleTimeZone } from '~/utils/date-transform';

const BASE = '/ads-push';

const sendData = (data: Edit) => {
  const sendData = { ...data };

  if ((sendData.city as City)?.id) {
    sendData.city = (sendData.city as City)?.id;
  }

  if ((sendData.link as ValueLabel)?.value) {
    sendData.link = (sendData.link as ValueLabel)?.value;
  }
  
  sendData.minPetAge = sendData.petAge?.min || 0;
  sendData.maxPetAge = sendData.petAge?.max || 0;

  if (!sendData.dispatchTime) {
    sendData.dispatchTime = new Date().toISOString();
  }
  else {
    sendData.dispatchTime = toDateISOString(sendData.dispatchTime);
  }

  /* removed helpers */
  delete sendData.id;
  delete sendData.breedsNone;
  delete sendData.petAge;
  delete sendData.petAgeNone;
  delete sendData.transition;

  return sendData;
};

export const read = request.table<List>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sortData = data.sort;

  if (sortData.petAge) {
    sortData.minPetAge = sortData.petAge;
    delete sortData.petAge;
  }

  const sort = sortTable(sortData, [
    'code',
    'status',
    'title',
    'text',
    'city',
    'link',
    'minPetAge',
  ]);

  const filters = filtersTable(data.filters as Filters, ['city', 'status', 'link', 'audience', 'breed', 'title']);

  const result = await getListResponse<List>(`${BASE}?${pagination}${filters}${sort}`, activePage);

  const { data: deppLinksResult } = await deepLinks({});
  
  return {
    data: {
      ...result.data,
      rows: result.data.rows.map((row) => ({
        ...row,
        link: deppLinksResult?.find((item) => item.value === row.link)?.label || row.link,
      })),
    },
  };
});

export const get = request.card<Read, Edit>(async ({ api, data, parseError }) => {
  const res = await api.get(`${BASE}/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const { data: deppLinksResult } = await deepLinks({});

  return {
    data: {
      ...res.data.data,
      type: res.data.data.type.value,
      transition: deppLinksResult?.some((item) => item.value === res.data.data.link) ? 'app' : 'link',
      audience: res.data.data.audience.value,
      breeds: res.data.data.breeds.map((item: any) => item.id),
      breedsNone: Boolean(!res.data.data.breeds.length),
      petAgeNone: Boolean(!res.data.data.minPetAge && !res.data.data.maxPetAge),
      petAge: res.data.data.minPetAge >= 0 && res.data.data.maxPetAge ? { min: res.data.data.minPetAge, max: res.data.data.maxPetAge } : null,
      dispatchTime: toDateLocaleTimeZone(res.data.data.dispatchTime),
      link: deppLinksResult?.find((item) => item.value === res.data.data.link) || res.data.data.link as string,
    },
  };
});

export const getEdit = request.card<Read, Edit>(async ({ api, data, parseError }) => {
  const res = await api.get(`${BASE}/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const { data: deppLinksResult } = await deepLinks({});

  return {
    data: {
      ...res.data.data,
      type: res.data.data.type.value,
      transition: deppLinksResult?.some((item) => item.value === res.data.data.link) ? 'app' : 'link',
      audience: res.data.data.audience.value,
      breeds: res.data.data.breeds.map((item: any) => item.id),
      breedsNone: Boolean(!res.data.data.breeds.length),
      petAgeNone: Boolean(!res.data.data.minPetAge && !res.data.data.maxPetAge),
      petAge: res.data.data.minPetAge >= 0 && res.data.data.maxPetAge ? { min: res.data.data.minPetAge, max: res.data.data.maxPetAge } : null,
      link: deppLinksResult?.find((item) => item.value === res.data.data.link) || res.data.data.link as string,
      dispatchTime: '',
    },
  };
});

export const create = request.card<Read, Edit>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.post(`${BASE}`, sendData(data.data));

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'ads-push' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<Read, Edit>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.patch(`${BASE}/${data.id}`, sendData(data.data));

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'ads-push-view', params: { id: data.id as string } });

    return {
      data: res.data.data,
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'ads-push' });

    return { data: res.data?.data };
  },
);

export const updateStatus = request.card<Read, UpdateStatus>(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.patch(`${BASE}/${data.id}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data?.data,
    };
  },
);

// TODO: Добавить эндпоинт для списка всех ссылок 
export const linksList = request.custom(async ({ api }) => {
  // const res = await api.get(`${BASE}/links-list`);
  const deepLinksList = await deepLinks({});

  return {
    data: deepLinksList.data,
  };
});
