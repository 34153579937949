<template>
  <ElSelect
    v-model="status"
    :disabled="field.disabled()"
    class="status-select"
    :class="[colorClass]"
    @change="updateStatus"
    @click.prevent
  >
    <ElOption
      v-for="item in orderStatusList"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </ElSelect>
</template>

<script lang="ts" setup>
import {
  computed,
  defineProps,
  onMounted,
  PropType,
  ref,
} from 'vue';
import { WalkingStatus, WalkingStatuses } from '~/types/common';
import { searchStatuses } from '~/utils/searchInit';
import { getStatusSelectColorClass } from '~/features/orders/utils';
import { walkingCardStatus } from '~/utils/walking-card-status';

const props = defineProps({
  field: {
    type: Object as PropType<{ disabled: boolean }>,
    required: true,
  },
  modelValue: {
    type: String as PropType<WalkingStatuses>,
    default: null,
  },
});

const status = ref<WalkingStatuses>(props.modelValue);
const orderStatusList = ref<WalkingStatus[]>([]);
const colorClass = computed<string>(() => getStatusSelectColorClass(status.value));

const updateStatus = (val: WalkingStatuses) => {
  walkingCardStatus.value = val;
};

searchStatuses()
  .then((res) => {
    if (res.data) {
      orderStatusList.value = res.data;

      if (status.value === WalkingStatuses.canceled) {
        return;
      }

      orderStatusList.value = orderStatusList.value.filter((item) => item.value !== WalkingStatuses.canceled);
    }
  });

onMounted(() => {
  walkingCardStatus.value = props.modelValue;
});
</script>

<style>
.status-select {
  max-width: 170px;
}
</style>
