<template>
  <el-select
    v-model="selected"
    :disabled="(readOnly && type === Types.birthday && route.params.id === 'new') || (readOnly && route.params.id !== 'new')"
    @change="emit('update:modelValue', selected);"
  >
    <el-option
      v-for="item in items"
      :key="item?.value"
      :label="item?.label"
      :value="item?.value"
    />
  </el-select>
</template>
  
<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, computed, onMounted, watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { adsPushStatusesList } from '~/utils/list';
import {
  Statuses, Types, type List, type Read, 
} from '../types';
  
const props = defineProps({
  modelValue: {
    type: Object as PropType<{ label: string, value: Statuses }>,
    required: true,
  },
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  form: {
    type: Object as PropType<Read>,
    required: true,
  },
}); 

const route = useRoute();

const type = computed(() => props.form.type?.value || props.form.type);

const items = computed(() => {
  if (type.value === Types.once) {
    return [
      adsPushStatusesList.find((item) => item.value === Statuses.planned),
      adsPushStatusesList.find((item) => item.value === Statuses.sent),
    ];
  }

  return [
    adsPushStatusesList.find((item) => item.value === Statuses.active),
    adsPushStatusesList.find((item) => item.value === Statuses.cancelled),
  ];
});

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
  return props.field.readOnly;
});
  
const emit = defineEmits(['update:modelValue']);
  
const selected = ref(props.modelValue?.value || items.value[0]?.value);
  
onMounted(() => {
  emit('update:modelValue', selected.value);
});

watch(() => props.form.type, () => {
  selected.value = items.value[0]?.value as Statuses;

  emit('update:modelValue', selected.value);
});
</script>

<style>
.page-ads-push-edit .input-status,
.page-ads-push-view .input-status {
  max-width: 185px
}
</style>
