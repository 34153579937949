export enum NotificationTypes {
  walking_new = 'walking_new',
  walking_payment_time_exceeding = 'walking_payment_time_exceeding',
  walking_paid = 'walking_paid',
  walking_cancel = 'walking_cancel',
  walking_processing = 'walking_processing',
  walking_message = 'walking_message',
  walking_without_walker = 'walking_without_walker',
  walking_to_verify = 'walking_to_verify',
  walker_accepted_walking = 'walker_accepted_walking',
  walking_did_not_start = 'walking_did_not_start',
}

export interface NotificationDirection {
  jwt: string,
  url: string,
}

export interface NotificationData {
  notification: {
    linkType: 'walking',
    text: string,
    link: string,
    data: any[],
  },
  type: NotificationTypes,
}
