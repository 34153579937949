import { loginPageController, openModal, actionCard } from 'magner';
import changePassConfig from 'features/login/changepass/change-password';
import loginRequest from './requests';
import { t } from './ru';

const loginPageConfig = loginPageController({
  title: 'Вход',
  request: loginRequest,
  logo: '/logo.svg',
  form: {
    fullDataOnUpdate: true,
    submitOnEnter: true,

    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'success',
          text: t('submitButton'),
        },
      },
      {
        type: 'action',
        emits: '',
        props: {
          type: 'text',
          class: 'forget-btn',
          text: t('forgetButton'),
        },
        action: actionCard(async () => {
          try {
            await openModal<any>({
              type: 'card',
              config: changePassConfig,
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          }
          catch (e) {
            return false;
          }
        }),
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'input',
          name: 'login',
          props: {
            type: 'text',
            placeholder: t('fields.login.placeholder'),
            autocomplete: 'on',
          },
          validation: {
            type: 'empty',
            trigger: 'blur',
          },
        },
        {
          type: 'input',
          name: 'password',
          props: {
            type: 'password',
            autocomplete: 'on',
            placeholder: t('fields.password.placeholder'),
          },
          validation: {
            type: 'password',
            trigger: 'blur',
          },
        },
      ],
    },
  },
});

export default loginPageConfig;
