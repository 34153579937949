import { tableController, tablePageController } from 'magner';

import { bannersT } from './index';
import bannersFilters from './filters';
import { read } from './requests';
import type { List, ActiveTimeRange } from './types';
import { formatDate } from '~/utils/date-transform';

export const tableConfig = tablePageController<List>({
  header: {
    title: bannersT('table.title'),
  },
  filters: bannersFilters,
  request: read,
  table: tableController<List>({
    tableLayout: 'auto',
    emptyText: bannersT('table.not_found'),
    columns: [
      {
        prop: 'title',
        label: bannersT('table.headers.title'),
        sortable: true,
      },
      {
        prop: 'sort',
        label: bannersT('table.headers.sort'),
        sortable: true,
      },
      {
        prop: 'city',
        label: bannersT('table.headers.city'),
        sortable: true,
      },
      {
        prop: 'activeTimeRange',
        label: bannersT('table.headers.date'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => `${formatDate((row as ActiveTimeRange).start)} – ${formatDate((row as ActiveTimeRange).end)}`,
        },
      },
      {
        prop: 'link',
        label: bannersT('table.headers.transition'),
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => row,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'banners-view',
      params: { id: row.id },
    }),
  }),
});
