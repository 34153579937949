import dayjs from 'dayjs';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';

const BASE = '/chat';

export type TypeMessage = 'client_admin' | 'walker_admin' | 'client_walker_admin';

export type AuthorType = 'admin' | 'client' | 'walker';

interface Create {
  type: TypeMessage,
  walkingId: string,
  content: string,
  attachments: string[],
}

export interface Message {
  attachments: { downloadUrl: string }[],
  authorId: string,
  authorName: string,
  authorType: AuthorType,
  content: string,
  createdAt: string,
  id: string,
  type: TypeMessage,
  walkingId: string,
}

interface MessagesRequest {
  page: number,
  limit: number,
  type: TypeMessage,
}

interface Init {
  url: string,
  jwt: string,
}

export interface List {
  id: string,
  createdAt: string,
  from: string,
  lastMessage: string,
  to: string,
  walkingExternalId: null,
  walkingId: string,
  type: {
    value: TypeMessage,
    label: string,
  },
}

export const read = request.table<Message>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const result = await getListResponse<Message>(`${BASE}/list?${pagination}`, activePage);

  return {
    data: {
      ...result.data,
      rows: result.data.rows.map((item: any) => ({
        ...item,
        id: item.walkingId,
      })),
    },
  };
});

export const get = request.card<{ elements: Message[] }, MessagesRequest>(async ({ api, data, parseError }) => {
  const pagination = `limit=${data.data.limit}&page=${data.data.page || 1}`;

  // eslint-disable-next-line max-len
  const filter = `filters[0][id]=walkingId&filters[0][value]=${data.id}&filters[1][id]=type&filters[1][value]=${data.data.type}`;

  const res = await api.get(`${BASE}?${pagination}&${filter}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  res.data.data.elements.map((item: Message) => {
    item.createdAt = dayjs.tz(item.createdAt, 'UTC').tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');

    return item;
  });

  return {
    data: res.data.data,
  };
});

export const create = request.card<Message, Create>(
  async ({
    api, data, parseError,
  }) => {
    if ((data.data as Message).createdAt) {
      (data.data as Message).createdAt = dayjs.tz((data.data as Message).createdAt, dayjs.tz.guess()).tz('UTC').format('YYYY-MM-DDTHH:mm:ss');
    }

    const res = await api.post(BASE, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return res.data;
  },
);

export const update = request.card<any, any>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.patch<{ data: any }>(
      `${BASE}/update/${data.id}`,
      data.data,
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'options' });

    return {
      data: {},
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return { data: res.data?.data };
  },
);

export const init = request.card<Init>(
  async ({ api, data, parseError }) => {
    const res = await api.get(
      `${BASE}/init?type=${data.data.type}&walkingId=${data.id}`,
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data.data,
    };
  },
);
