import { request } from '~/utils/request';

import type {
  List, Create, Update, Read, Sort,
} from './types';

const BASE = '/ammunition';

export const read = request.card<{ items: List[] }, List[]>(async ({ api }) => {
  const res = await api.get(BASE);

  if (res.error) {
    return { data: { items: [] } };
  }

  return {
    data: {
      items: res.data?.data.elements,
    },
  };
});

export const get = request.card<Read, Read>(async ({ api, data, parseError }) => {
  const res = await api.get(`${BASE}/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data?.data,
    },
  };
});

export const create = request.card<List, Create>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.post(BASE, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    setTimeout(() => window.location.reload(), 500);
    
    return res;
  },
);

export const update = request.card<List, Update>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.patch(
      `${BASE}/${data.id}`,
      data.data,
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    setTimeout(() => window.location.reload(), 500);

    return res;
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    setTimeout(() => window.location.reload(), 500);

    return { data: res.data?.data };
  },
);

export const cardSortUpdate = request.card<Sort[], Sort[]>(
  async ({ api, data, parseError }) => {
    const res = await api.post(`${BASE}/sort`, {
      sortedData: data.data,
    });

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return { data: res.data?.data };
  },
);
