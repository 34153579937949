import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { searchCities } from '~/utils/searchInit';
import type { ExportWalking } from './types';
import { t } from './ru';

import {
  exportWalking,
} from './requests';

const cardConfig = cardPageController<ExportWalking>({
  getRequest: () => false as any,
  createRequest: exportWalking,
  updateRequest: () => false as any,

  successMessage: {
    create: 'Выполненные заказы выгружены',
  },

  header: {
    title: t('table.export_title.walking'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: baseT('form.execute'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'select',
          name: 'city',
          options: [],
          label: baseT('label.city'),
          dataType: 'string',
          props: {
            required: true,
            placeholder: baseT('placeholder.city'),
            valueKey: 'id',
            labelKey: 'name',
            remote: true,
            remoteMethod: searchCities,
          },
          validation: [
            {
              type: 'empty-required' as 'empty',
              trigger: 'change',
            },
          ],
        },
        {
          type: 'datetime',
          name: 'date',
          dataType: 'date',
          label: baseT('label.period'),
          props: {
            required: true,
            type: 'datetimerange',
            startPlaceholder: 'От',
            endPlaceholder: 'До',
            format: 'YYYY-MM-DD HH:mm',
            rangeSeparator: '–',
          },
          validation: [
            {
              type: 'empty-required' as 'empty',
              trigger: 'change',
            },
          ],
        },
      ],
    },
  },
});

export default cardConfig;
