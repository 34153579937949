import { Client } from 'features/users/clients';
import { magnerMessage } from 'magner';
import { request } from '~/utils/request';
import { getListResponse } from '~/utils/get-list';
import { toDateISOString } from '~/utils/date-transform';

import type {
  OrderBacked,
  OrderFrontend,
  OrderList,
  OfferList,
  OfferRead,
  Report,
  SelectWalker,
  ExportWalker,
  ExportWalking,
} from './types';
import { filterURLBuilder } from '~/utils/filter-builder';
import {
  formToBackendCreateOrderAdapter,
  formToBackendUpdateOrderAdapter,
  orderBackendToFrontend,
} from '~/features/orders/adapters';
import { WalkingCancelReasons } from '~/types/common';

const BASE = '/walking';
const OFFER = '/offer';

export const unpinOrder = (async ({ data, router }) => {
  const res = await request.api.delete(`/walking/management/processing/${data.id}`);

  if (res.error) {
    magnerMessage({
      type: 'error',
      message: res.error.data?.message || 'Ошибка',
    });
    return true;
  }

  magnerMessage({
    type: 'success',
    message: 'Заказ откреплен',
  });

  await router.push({ name: 'order' });
  return false;
});

export const read = request.table<OrderList>(async ({ data, parseError, lstorage }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sortCity = data.sort.walkCity ? `&sort[0][id]=city&sort[0][value]=${data.sort.walkCity}` : '';
  const sortDistrict = data.sort.walkDistrict ? `&sort[1][id]=district&sort[1][value]=${data.sort.walkDistrict}` : '';
  const sortWalkTime = data.sort.startTime ? `&sort[2][id]=startTime&sort[2][value]=${data.sort.startTime}` : '';
  const sortName = data.sort.client ? `&sort[3][id]=userName&sort[3][value]=${data.sort.client}` : '';
  const sortAsap = data.sort.asap ? `&sort[4][id]=asap&sort[4][value]=${data.sort.asap}` : '';
  const sortWalkingTypePrice = data.sort.walk ? `&sort[5][id]=walkingTypePrice&sort[5][value]=${data.sort.walk}` : '';
  const sortPrice = data.sort.price ? `&sort[6][id]=price&sort[6][value]=${data.sort.price}` : '';
  const sortPaymentStatus = data.sort.isPaid ? `&sort[7][id]=paymentStatus&sort[7][value]=${data.sort.isPaid}` : '';
  const sortStatus = data.sort.status ? `&sort[8][id]=status&sort[8][value]=${data.sort.status}` : '';
  const sortCreatedAt = data.sort.createdAt ? `&sort[9][id]=createdAt&sort[9][value]=${data.sort.createdAt}` : '';

  const filterExternalId = data.filters.externalId ? `&filters[0][id]=externalId&filters[0][value]=${data.filters.externalId}` : '';
  const filterAsap = data.filters.asap ? `&filters[1][id]=asap&filters[1][value]=${data.filters.asap === 1 ? 'true' : 'false'}` : '';
  const filterCity = data.filters.city ? `&filters[2][id]=cities&filters[2][value]=${data.filters.city}` : '';
  const filterStatus = data.filters.statuses.length ? filterURLBuilder(data, 3, 'statuses') : '';
  const filterUserName = data.filters.userName ? `&filters[4][id]=userName&filters[4][value]=${data.filters.userName}` : '';
  const filterUserPhone = data.filters.userPhone ? `&filters[5][id]=userPhone&filters[5][value]=${encodeURIComponent(data.filters.userPhone)}` : '';
  const filterWalkingTypePriceTo = data.filters.walkingTypePriceTo ? `&filters[6][id]=walkingTypePriceTo&filters[6][value]=${Number(data.filters.walkingTypePriceTo) * 100}` : '';
  const filterWalkingTypePriceFrom = data.filters.walkingTypePriceFrom ? `&filters[7][id]=walkingTypePriceFrom&filters[7][value]=${Number(data.filters.walkingTypePriceFrom) * 100}` : '';
  const filterPriceFrom = data.filters.priceFrom ? `&filters[8][id]=priceFrom&filters[8][value]=${Number(data.filters.priceFrom) * 100}` : '';
  const filterBreeds = data.filters.petBreeds.length ? filterURLBuilder(data, 9, 'petBreeds') : '';
  const filterWalkDateFrom = data.filters.walkDate ? `&filters[10][id]=startTimeFrom&filters[10][value]=${new Date(data.filters.walkDate[0]).toISOString()}` : '';
  const filterWalkDateTo = data.filters.walkDate ? `&filters[11][id]=startTimeTo&filters[11][value]=${new Date(data.filters.walkDate[1]).toISOString()}` : '';
  const filterRatingFrom = data.filters.ratingFrom ? `&filters[15][id]=ratingFrom&filters[15][value]=${Number(data.filters.ratingFrom)}` : '';
  const filterRatingTo = data.filters.ratingTo ? `&filters[15][id]=ratingTo&filters[15][value]=${Number(data.filters.ratingTo)}` : '';
  const filterWalkingType = data.filters.walkingType ? `&filters[16][id]=walkingTypes&filters[16][value]=${data.filters.walkingType}` : '';
  const filterPriceTo = data.filters.priceTo ? `&filters[17][id]=priceTo&filters[17][value]=${Number(data.filters.priceTo) * 100}` : '';
  const filterPetAgeFrom = data.filters.petAgeFrom ? `&filters[18][id]=petAgeFrom&filters[18][value]=${Number(data.filters.petAgeFrom) * 12}` : '';
  const filterPetAgeTo = data.filters.petAgeTo ? `&filters[19][id]=petAgeTo&filters[19][value]=${Number(data.filters.petAgeTo) * 12}` : '';
  const filterWeightFrom = data.filters.weightFrom ? `&filters[20][id]=weightFrom&filters[20][value]=${Number(data.filters.weightFrom)}` : '';
  const filterWeightTo = data.filters.weightTo ? `&filters[21][id]=weightTo&filters[21][value]=${Number(data.filters.weightTo)}` : '';
  const filterSource = data.filters.source ? filterURLBuilder(data, 22, 'source') : '';
  const filterPaymentStatus = data.filters.paymentStatuses ? `&filters[23][id]=paymentStatuses&filters[23][value]=${data.filters.paymentStatuses === 1 ? 'true' : 'false'}` : '';
  const filterId = data.filters.id ? `&filters[24][id]=publicId&filters[24][value]=${encodeURIComponent(data.filters.id)}` : '';
  const filterWalkerName = data.filters.walkerName ? `&filters[25][id]=walkerName&filters[25][value]=${data.filters.walkerName}` : '';
  const filterWalkerPhone = data.filters.walkerPhone ? `&filters[26][id]=walkerPhone&filters[26][value]=${encodeURIComponent(data.filters.walkerPhone)}` : '';
  const filterDistricts = data.filters.districts.length ? filterURLBuilder(data, 26, 'districts') : '';
  const filterWalkingOptions = data.filters.walkingOptions.length ? filterURLBuilder(data, 27, 'walkingOptions') : '';

  const res = await getListResponse<OrderList>(`${BASE}?${pagination}${sortCity}${sortDistrict}${sortWalkTime}${sortName}${sortAsap}${sortWalkingTypePrice}${sortPrice}${sortPaymentStatus}${sortStatus}${sortCreatedAt}${filterExternalId}${filterId}${filterAsap}${filterCity}${filterWalkDateFrom}${filterWalkDateTo}${filterStatus}${filterPaymentStatus}${filterUserName}${filterUserPhone}${filterWalkingTypePriceTo}${filterWalkingTypePriceFrom}${filterPriceFrom}${filterPriceTo}${filterBreeds}${filterPetAgeFrom}${filterPetAgeTo}${filterWeightFrom}${filterWeightTo}${filterRatingFrom}${filterRatingTo}${filterWalkingType}${filterSource}${filterWalkerName}${filterWalkerPhone}${filterDistricts}${filterWalkingOptions}`, activePage);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  res.data?.rows.map((item) => {
    if (item.processingManager && item.processingManager.id === lstorage.read('id')) {
      item.isEditing = true;
    }
    else {
      item.isEditing = false;
    }

    return item;
  });

  return {
    data: {
      ...res.data,
    },
  };
});

export const search = request.custom<OrderList[]>(async () => {
  const pagination = 'limit=50&page=1';
  const res = await getListResponse<OrderList>(`${BASE}/list?${pagination}`);
  return { data: res.data.rows };
});

export const get = request.card<OrderBacked, OrderFrontend>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: OrderBacked }>(`${BASE}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  setTimeout(() => {
    const header = document.querySelector('.magner-page-header_title');

    if (header && res.data?.data.publicId) {
      header.textContent = `Заказ ${res.data?.data.publicId}`;
    }
  }, 300);

  return {
    data: orderBackendToFrontend(res.data?.data),
  };
});

export const report = request.card<Report, Report>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.get<{ data: Report }>(`${BASE}/report/${data.id}`);

    if (res.error) {
      router.push({ name: 'order-report-not-found', params: { id: data.id } });

      return { error: parseError(res.error) };
    }

    return {
      data: {
        ...res.data?.data,
        commentForClient: res.data.data.finish.comment,
        reportUrl: res.data.data.finish.reportUrl,
      },
    };
  },
);

export const create = request.card<OrderBacked, OrderFrontend>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const res = await api.post<{ data: OrderBacked }>(
    `${BASE}/create`,
    await formToBackendCreateOrderAdapter(data.data),
  );

  if (res.error) {
    const parsed = parseError(res.error);
    if (parsed.message) {
      magnerMessage({
        type: 'error',
        message: parsed.message,
      });
    }
    return { error: parsed };
  }

  await router.push({ name: 'order', params: { id: res.data?.data.id } });
  return { data: res.data?.data };
});

export const update = request.card<OrderBacked, OrderFrontend>(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const body = await formToBackendUpdateOrderAdapter(data.data);
  const res = await api.patch<{ data: OrderBacked }>(`${BASE}/update/${data.id}`, body);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await unpinOrder({ data: { id: data.id }, router });
  return { data: res.data?.data };
});

export const cancelWalking = request.card(async (
  {
    data,
    api,
    parseError,
    router,
  },
) => {
  const res = await api.patch<{ data: Client }>(`/walking/cancel/${data.id}`,
    {
      reason: data.data.reason,
      comment: data.data.reason === WalkingCancelReasons.other ? data.data.comment : null,
    });

  if (res.error) {
    magnerMessage({
      type: 'error',
      message: res.error.data?.message || 'Ошибка',
    });

    return { error: parseError(res.error) };
  }

  await unpinOrder({ data: { id: data.id }, router });
  await router.push(`/order/${data.id}`);

  return { data: {} };
});

export const unpinFromAdmin = (async ({ data, router }) => {
  await unpinOrder({ data: { id: data.id }, router });
  await router.push({ name: 'orders' });
});

export const attachOrder = (async ({ data, router }) => {
  const res = await request.api.patch(`/walking/management/processing/${data.id}`, '');

  if (res.error) {
    magnerMessage({
      type: 'error',
      message: res.error.data?.message || 'Ошибка',
    });
    return {};
  }

  router.push({ name: 'order-edit' });
  return false;
});

export const offerList = request.custom<OfferList[]>(async () => {
  const pagination = 'limit=100&page=1';

  const res = await getListResponse<OfferList>(`${OFFER}?${pagination}`);

  return { data: res.data.rows || [] };
});

export const offerRead = request.custom<OfferRead>(async ({ api, data }) => {
  const res = await api.get<{ data: OfferRead }>(`${OFFER}/read/${data.id}`);

  return { data: res.data?.data as OfferRead };
});

export const selectWalkers = request.custom(async ({ api, data }) => {
  const res = await api.get<{ data: { walkers: SelectWalker[] } }>(`/walking/select-walkers/${data.id}`);

  return { data: res.data?.data.walkers as SelectWalker[] };
});

export const exportWalker = request.card<{}, ExportWalker>(
  async ({
    api, data, parseError, 
  }) => {
    const res = await api.post('/walker/export', {
      ...data.data,
      type: 'xlsx',
    });

    if (res.error) {
      return { error: parseError(res.error) };
    }

    window.open(res.data.data.downloadUrl, '_blank');

    return { data: res.data.data };
  },
);

export const exportWalking = request.card<{}, ExportWalking>(
  async ({
    api, data, parseError, 
  }) => {
    const sendData = { ...data.data };

    if (sendData.date[0] && sendData.date[0]) {
      sendData.dateStart = toDateISOString(sendData.date[0]);
      sendData.dateEnd = toDateISOString(sendData.date[1]);
    }

    delete sendData.date;

    const res = await api.post('/walking/export', {
      ...sendData,
      type: 'xlsx',
    });

    if (res.error) {
      return { error: parseError(res.error) };
    }

    window.open(res.data.data.downloadUrl, '_blank');

    return { data: res.data.data };
  },
);
