<template>
  <div
    class="walking-status-tag"
    :class="[colorClass]"
  >
    {{ label }}
  </div>
</template>

<script lang="ts" setup>
import {
  computed,
  defineProps,
  PropType, ref,
} from 'vue';
import { getStatusSelectColorClass } from 'features/orders/utils';
import { WalkingStatus, WalkingStatuses } from '~/types/common';
import { searchStatuses } from '~/utils/searchInit';

const props = defineProps({
  status: {
    type: String as PropType<WalkingStatuses>,
    required: true,
  },
});

const orderStatusList = ref<WalkingStatus[]>([]);
const colorClass = computed<string>(() => getStatusSelectColorClass(props.status));
const label = computed<string>(() => orderStatusList.value.find((item) => item.value === props.status)?.label || '');

searchStatuses()
  .then((res) => {
    if (res.data) {
      orderStatusList.value = res.data;
    }
  });
</script>

<style lang="postcss">
.walking-status-tag {
  border-radius: 4px;
  padding: 4px 14px;

  &.status-green {
    background: #F0F9EB;
    color: #6AC044;
    border: 1px solid #6AC044;
  }

  &.status-orange {
    background: #FFDCA3;
    color: #E4A147;
    border: 1px solid #E4A147;
  }

  &.status-red {
    background: #FEF0F0;
    color: #F36D6F;
    border: 1px solid #F36D6F;
  }

  &.status-border-red {
    color: #F36D6F;
    border: 1px solid #F36D6F;
  }
}
</style>
