import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { searchCities } from '~/utils/searchInit';
import type { ExportWalker } from './types';
import { t } from './ru';

import {
  exportWalker,
} from './requests';

const cardConfig = cardPageController<ExportWalker>({
  getRequest: () => false as any,
  createRequest: exportWalker,
  updateRequest: () => false as any,

  successMessage: {
    create: 'Выгульщики выгружены',
  },

  header: {
    title: t('table.export_title.walker'),
  },

  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'primary',
          text: baseT('form.execute'),
        },
      },
    ],
    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'select',
          name: 'city',
          options: [],
          label: baseT('label.city'),
          dataType: 'string',
          props: {
            required: true,
            placeholder: baseT('placeholder.city'),
            valueKey: 'id',
            labelKey: 'name',
            remote: true,
            remoteMethod: searchCities,
          },
          validation: [
            {
              type: 'empty-required' as 'empty',
              trigger: 'change',
            },
          ],
        },
      ],
    },
  },
});

export default cardConfig;
