import { ft } from '~/utils/feature-translate';

export const ServicesRu = {
  sidebar: {
    table_title: 'Услуги',
    card_title: 'Услуга',
  },
  table: {
    title: 'Услуги',
    not_found: 'Услуги не найдены',
    headers: {
      title: 'Название',
      city: 'Город',
      max_number_of_pets: 'Max. питомцев для услуги',
      status: 'Статус',
      hasOptions: {
        title: 'Опции',
        yes: 'Есть',
        no: 'Нет',
      },
    },
  },
  filters: {
    status: 'Статус',
    city: 'Город',
  },
  card: {
    title: 'Услуга',
    form: {
      category: {
        title: 'Категория',
        placeholder: 'Выберите категорию',
      },
      description: {
        title: 'Описание',
        placeholder: 'Введите описание',
      },
      max_number_of_pets: 'Max. питомцев для услуги',
      tariff: {
        title: 'Тариф',
        time: 'Количество минут',
        price: 'Стоимость для клиента',
        walker_fee: 'Заработок выгульщика',
        additional_pet_price: 'Стоимость для клиента',
        additional_pet_walker_fee: 'Заработок выгульщика',
      },
      type: 'Тип',
      options: {
        title: 'Опции',
        name: 'Название',
        prompt: 'Подсказка',
        price: 'Стоимость для клиента',
        walker_fee: 'Заработок выгульщика',
        max_number_of_pets: 'Max. питомцев для услуги',
      },
    },
  },
};

export const t = ft(ServicesRu, 'catalog.services.');
