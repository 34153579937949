<template>
  <div 
    v-if="props.modelValue.length"
    class="orders-report-images"
  >
    <div 
      v-for="item, i in props.modelValue" 
      :key="item.file.id" 
      class="orders-report-images__item"
    >
      <div class="grid-content ep-bg-purple" />
      <el-image
        :src="item.file.downloadUrl"
        :initial-index="i"
        :preview-src-list="props.modelValue.map((item) => item.file.downloadUrl)"
        fit="cover"
      />
    </div>
  </div>
  
  <div v-else>Нет фотографий</div>
</template>

<script lang="ts" setup>
import {
  defineProps, PropType,
} from 'vue';

import type { WalkingPhoto } from '~/features/orders/types';

const props = defineProps({
  modelValue: {
    type: Array as PropType<WalkingPhoto[]>,
    required: true,
  },
}); 
</script>

<style>
.orders-report-images {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}

.orders-report-images__item {
  position: relative;
  width: calc(25% - 6.5px);
  padding-bottom: calc(25% - 6.5px);
  height: 0;
}

.orders-report-images__item .el-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
}

.orders-report-images__item .grid-content {
   margin-top: 75%;
}
</style>
