<template>
  <div style="min-width: 160px !important;">
    <el-select
      v-model="model"
      :class="className"
      @click.prevent
      @change="onChange"
    >
      <el-option
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
  
<script lang="ts" setup>
import {
  defineProps, PropType, ref, computed, watch,
} from 'vue';

import { statusesBoolean } from '~/utils/list';
import type { List } from '../types';
import { updateActive } from '../requests';

const props = defineProps({
  row: {
    type: Object as PropType<List>,
    required: true,
  },
});
  
const model = ref(props.row.active);
  
const items = ref(statusesBoolean);
  
const className = computed(() => (model.value ? 'status-green' : 'status-red'));

const onChange = async (value: boolean) => {
  await updateActive({ id: props.row.id, data: { active: value } });
};

watch(
  () => props.row.active, 
  () => {
    model.value = props.row.active;
  },
);
</script>
