import { ft } from '~/utils/feature-translate';

export const loginRu = {
  submitButton: 'Войти',
  forgetButton: 'Забыли пароль?',
  fields: {
    login: {
      placeholder: 'Логин',
      label: 'Логин',
    },
    password: {
      placeholder: 'Пароль',
      label: 'Пароль',
    },
  },
  changePass: {
    title: 'Восстановление пароля',
    submitBtn: 'Сменить пароль',
    form: {
      email: 'Адрес электронной почты',
    },
  },
};

export const t = ft(loginRu, 'login.');
