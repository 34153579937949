<template>
  <el-upload
    :show-file-list="false"
    accept=".geojson"
    action="#"
    :auto-upload="false"
    :on-change="onChange"
  >
    <el-button type="primary" :loading="isLoading">Загрузить файл</el-button>
  </el-upload>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
 
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { fileUpload } from '~/features/common/file-request';
import { importDistrict } from '../requests';

const isLoading = ref(false);
const route = useRoute();

const errorMessage = () => {
  ElMessage({
    message: 'Ошибка при загрузке файла',
    type: 'error',
  });
};

const onChange = async (file: { raw: File }) => { 
  isLoading.value = true;

  try {
    const resultUpload = await fileUpload((file as { raw: File }).raw, 'geoData');

    if (!resultUpload.data?.id) {
      errorMessage();

      return;
    }

    const result = await importDistrict({ 
      geoDataFileId: resultUpload.data?.id || '', 
      cityId: route.params.id as string, 
    });

    if (result.error) {
      errorMessage();
    
      return;
    }

    ElMessage({
      message: 'Файл успешно загружен',
      type: 'success',
    });

    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  catch (e) {
    errorMessage();
  }
  finally {
    isLoading.value = false;
  }
};
</script>
