import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { orderRu } from '../index';
import { get, create, update } from '../requests';
import { t } from '../ru';

import OrdersReportImages from './components/orders-report-images.vue';
import OrdersReportMap from './components/orders-report-map.vue';
import OrdersReportAfterWalks from './components/orders-report-after-walks.vue';

export const cardConfig = cardPageController<any>({
  header: {
    title: orderRu('form.title'),
    tabs: [
      {
        label: 'Карточка',
        link: (route) => ({
          name: 'order-report',
          params: { id: route.params.id },
        }),
        active: false,
      },
      {
        label: 'Отчет',
        link: (route) => ({
          name: 'order-report-edit',
          params: { id: route.params.id },
        }),
        active: true,
      },
    ],
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,

  form: {
    actions: [
      {
        type: 'link',
        to: (route): any => ({ name: 'order-report', params: { route } }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** startTime, urgent, distance */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.walkDate'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.walkDate'),
                    readOnly: true,
                    readOnlyFormatter: (val) => new Date(val as string)
                      .toLocaleDateString()
                      .replaceAll('/', '.'),
                  },
                },
                {
                  type: 'select',
                  name: 'asap',
                  options: [
                    {
                      label: 'Да',
                      value: true,
                    },
                    {
                      label: 'Нет',
                      value: false,
                    },
                  ],
                  label: t('form.fields.urgent'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => (val ? 'Да' : 'Нет'),
                  },
                },
                {
                  type: 'select',
                  name: 'distance',
                  options: [],
                  label: t('form.fields.distance'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
              ],
            },
            /** Start plan, end plan, walking time */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.startPlan'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.startPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => new Date(val as string)
                      .toLocaleDateString()
                      .replaceAll('/', '.'),
                  },
                },
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.finishPlan'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.finishPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => new Date(val as string)
                      .toLocaleDateString()
                      .replaceAll('/', '.'),
                  },
                },
                {
                  type: 'select',
                  name: 'walkingTime',
                  options: [],
                  label: t('form.fields.walkingTime'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
              ],
            },
            /** Start fact, end fact, walking time fact */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.startPlanFact'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.startPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => new Date(val as string)
                      .toLocaleDateString()
                      .replaceAll('/', '.'),
                  },
                },
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.finishPlanFact'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.finishPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => new Date(val as string)
                      .toLocaleDateString()
                      .replaceAll('/', '.'),
                  },
                },
                {
                  type: 'select',
                  name: 'walkingTime',
                  options: [],
                  label: t('form.fields.walkingTimeFact'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
              ],
            },
            /** Сomment for client */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'textarea',
                  name: 'commentForClient',
                  label: t('form.fields.commentForClient'),
                  dataType: 'string',
                  props: {
                    required: true,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },

            /** Comment for your own */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'textarea',
                  name: 'commentForOwn',
                  label: t('form.fields.commentForOwn'),
                  dataType: 'string',
                  props: {
                    required: true,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },

                {
                  type: 'input',
                  name: 'reportUrl' as 'finish',
                  label: 'Ссылка на отчет кинолога',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },

            /** Images */
            {
              type: 'column',
              title: t('form.fields.images'),
              props: {
                // span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'images',
                  component: () => OrdersReportImages,
                  props: {},
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            /** Map */
            {
              type: 'column',
              title: t('form.fields.map'),
              props: {
                // span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'map',
                  component: () => OrdersReportMap,
                  props: {},
                },
              ],
            },
            /** After walks */
            {
              type: 'column',
              title: t('form.fields.after_walks'),
              props: {
                // span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'map',
                  component: () => OrdersReportAfterWalks,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
