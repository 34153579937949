import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { servicesT } from './index';
import {
  get, create, update, remove, 
} from './requests';
import { searchCities } from '~/utils/searchInit';
import { saveImageContextImage } from '~/features/common/file-request';
import type { Edit } from './types';
import DiscountsStatusCard from './components/discounts-status-card.vue';
import DiscountsTransition from './components/discounts-transition.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: servicesT('card.title'),
  },

  getRequest: get as any,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'services-main' }
          : { name: 'services-main-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          class: 'width-auto',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                /** Name */
                {
                  type: 'input',
                  name: 'title',
                  label: servicesT('card.form.name.title'),
                  dataType: 'string',
                  hint: servicesT('card.form.name.hint'),
                  props: {
                    placeholder: servicesT('card.form.name.placeholder'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                /** Sort */
                {
                  type: 'input',
                  name: 'sort',
                  label: servicesT('card.form.sort.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    type: 'number',
                    placeholder: servicesT('card.form.sort.placeholder'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },

                {
                  type: 'custom',
                  name: 'isActive',
                  label: 'Статус',
                  component: () => DiscountsStatusCard,
                  props: {},
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              props: {},
              fields: [
                /** City */
                {
                  type: 'select',
                  name: 'cityId',
                  options: [],
                  label: servicesT('card.form.city.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: servicesT('card.form.city.placeholder'),
                    valueKey: 'id',
                    labelKey: 'name',
                    remote: true,
                    remoteMethod: searchCities,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            {
              type: 'column',
              title: 'Переход',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'transition',
                  props: {
                    required: true,
                    disabled: ({ state }) => !state.cityId,
                  },
                  component: () => DiscountsTransition,
                  validation: [
                    {
                      type: 'empty-required-discounts-transition' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'dropzone',
                  label: servicesT('card.form.image.title'),
                  name: 'imageId',
                  props: {
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImageContextImage,
                    inputAtts: {
                      accept: 'image/svg+xml',
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
