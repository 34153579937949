<template>
  <div class="discount-transition">
    <el-switch
      v-model="isLink"
      class="mb-2"
      style="--el-switch-on-color: #13ce66; --el-switch-off-color: #13ce66"
      active-text="По ссылке"
      inactive-text="Внутри приложения"
      :disabled="readOnly"
      @change="reset"
    />

    <template v-if="isLink">
      <div class="discount-transition__item" :class="{ 'is-success': linkText }">
        <p>Ссылка для перехода</p>
        <el-input
          v-if="!readOnly"
          v-model="linkText"
          :disabled="readOnly"
          placeholder="https://..."
          @change="update"
        />
          
        <MagnerFormInput
          v-else
          :model-value="linkText"
          :field="readOnlyInput"
        />
      </div>

      <div class="discount-transition__item" :class="{ 'is-success': price }">
        <p>Стоимость, “от ... руб”</p>
        <el-input
          v-if="!readOnly"
          v-model="price"
          type="number"
          :disabled="readOnly"
          placeholder="Введите сумму в рублях"
          @change="update"
        />
          
        <MagnerFormInput
          v-else
          :model-value="price"
          :field="readOnlyInput"
        />
      </div>
    </template>

    <template v-else>
      <div class="discount-transition__item" :class="{ 'is-success': offer }">
        <p>Услуга</p>
        <el-select
          v-if="!readOnly"
          v-model="offer"
          :disabled="!props.form.cityId || readOnly"
          :placeholder="!props.form.cityId ? 'Сначала выберите город' : 'Выберите одну услугу'"
          @change="onOffer"
        >
          <el-option
            v-for="item in offers"
            :key="item.id"
            :label="item.title"
            :value="item.id"
            :disabled="item.status.value === 'inactive'"
          />
        </el-select>
      
        <MagnerFormInput
          v-else
          :model-value="offers.find((item) => item.id === offer)?.title || '–'"
          :field="readOnlyInput"
        />
      </div>
      
      <div class="discount-transition__item" :class="{ 'is-success': tariff }">
        <p>Тариф</p>
        <el-select
          v-if="!readOnly"
          v-model="tariff"
          collapse-tags
          :disabled="!offer || readOnly"
          :placeholder="!offer 
            ? 'Сначала выберите услугу' 
            : 'Выберите тариф'"
          @change="update"
        >
          <el-option
            v-for="item in offersTariff"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      
        <MagnerFormInput
          v-else
          :model-value="offersAllTariff?.filter((item) => tariff.includes(item.id)).map(item => item.title).join(', ') || '–'"
          :field="readOnlyInput"
        />

        <div class="discount-transition__switch">
          <el-switch
            v-model="asap"
            :disabled="!offer || readOnly"
            active-text="Срочно"
            @change="update"
          />
        </div>
      </div>
      
      <div class="discount-transition__item" :class="{ 'is-success': option }">
        <p>Опция</p>
        <el-select
          v-if="!readOnly"
          v-model="option"
          collapse-tags
          :disabled="!offer || readOnly || isNoOption" 
          :placeholder="isNoOption ? 'Без выбора опции' 
            : !offer 
              ? 'Сначала выберите услугу' 
              : 'Выберите опцию'"
          @change="update"
        >
          <el-option
            v-for="item in offersOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id"
          />
        </el-select>
      
        <MagnerFormInput
          v-else
          :model-value="offersAllOptions?.filter((item) => option.includes(item.id)).map(item => item.title).join(', ') || '–'"
          :field="readOnlyInput"
        />

        <div class="discount-transition__switch">
          <el-switch
            v-model="isNoOption"
            :disabled="!offer || readOnly"
            active-text="Без выбора опций"
            @change="
              option = '';
              update()
            "
          />
        </div>
      </div>    

      <div>
        <p>Стоимость, “от ... руб”</p>
        <MagnerFormInput
          :key="appPrice" 
          :model-value="appPrice"
          :field="readOnlyInput"
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  computed,
  ref,
  watch,
} from 'vue';

import { MagnerFormInput } from 'magner';
import { searchOffers } from '~/utils/searchInit';
import type { OfferRead } from '~/features/catalog/services/types';

import type { Edit, Transition } from '../types';

const props = defineProps({
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  form: {
    type: Object as PropType<Edit>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<Transition>,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const isLink = ref<boolean>(props.modelValue?.isLink ?? true);
// Data link
const linkText = ref(props.modelValue?.linkText || '');
const price = ref(props.modelValue?.price || '');

// Data app
const offer = ref(props.modelValue?.offer || null);
const asap = ref(props.modelValue?.asap || false);

const tariff = ref(props.modelValue?.tariff || '');

const option = ref(props.modelValue?.option || '');
const isNoOption = ref(props.modelValue?.isNoOption || false);

// Computed
const sendData = computed(() => ({
  isLink: isLink.value,
  
  linkText: linkText.value,
  price: price.value,

  offer: offer.value,
  tariff: tariff.value,
  option: option.value,
  isNoOption: isNoOption.value,
  asap: asap.value,
}));

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }

  return props.field.readOnly;
});

// Lists
const offersAll = ref<{ data: OfferRead[] }>(await searchOffers());
// eslint-disable-next-line max-len, vue/max-len
const offers = computed(() => offersAll.value.data.filter((item) => item.city.id === ((props.form.city as any)?.id || props.form.cityId)));

const offersTariff = computed(() => [
  ...(offers.value.find((item) => item.id === offer.value)?.offerRates.map((item) => ({
    title: `${item.time} минут`,
    id: item.id,
    price: item.price.inRubles,
  })) || []),
]);

const offersOptions = computed(() => offers.value.find((item) => item.id === offer.value)?.offerOptions);

const offersAllTariff = computed(() => offersAll.value.data.map((item) => item.offerRates).flat().map((item) => ({
  title: `${item.time} минут`,
  id: item.id,
})));

const offersAllOptions = computed(() => offersAll.value.data.map((item) => item.offerOptions).flat());

//
const appPrice = computed(() => {
  const priceTariff = offersTariff.value.find((item) => item.id === tariff.value)?.price || 0;
  const priceOption = offersOptions?.value?.find((item) => item.id === option.value)?.price?.inRubles || 0;

  return (priceTariff + priceOption) || '';
});

// Methods
const update = () => emit('update:modelValue', sendData.value);

const onOffer = () => {
  tariff.value = '';
  option.value = '';
  asap.value = false;
  isNoOption.value = false;

  update();
};

const reset = () => {
  linkText.value = '';
  price.value = '';

  offer.value = null;
  tariff.value = '';
  option.value = '';
  asap.value = false;
  isNoOption.value = false;

  update();
};

/* Other */
watch(() => props.form.cityId, () => {
  reset();

  update();
});

update();

const readOnlyInput = { props: { readOnly: true } } as any;
</script>

<style>
.discount-transition {
  width: 100%;
}

.discount-transition__switch {
  margin-top: 8px;
}

.discount-transition__item {
  width: 100%;
}

.discount-transition__item:not(:last-child) {
  margin-bottom: 24px;
}

.input-objectData .is-success .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

.custom-header .el-checkbox {
    display: flex;
    height: unset;
}
</style>
