<template>
  <div class="w-full">
    <div class="el-form-item__label">Комментарии выгульщиков </div>
    <div v-if="comments?.data?.length" class="w-full">
      <div v-for="item, index in comments.data" :key="item.id" class="client-pets-comments">
        <MagnerFormInput
          :model-value="item.comment"
          :field="fields.commentReadonly"
        />

        <div class="client-pets-comments__actions">
          <el-tooltip>
            <template #content>
              <div>{{ item.author }}</div>
                      
              <div class="text-sm">{{ formatDate(item.createdAt) }}</div>
  
              <div class="text-sm">Нравится: {{ item.likeCount }}</div>
            </template>
  
            <el-icon color="rgba(223, 226, 232, 1)" class="client-pets-comments-hint">
              <InfoFilled />
            </el-icon>
          </el-tooltip>
  
          <el-icon
            v-if="!readOnly"
            color="#f56c6c"
            class="client-pets-comments-delete"
            @click="remove(item.id, index)"
          >
            <Delete />
          </el-icon>
        </div>
      </div>
    </div>
    <div v-else>Нет комментариев</div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  defineProps,
  PropType,
  computed,
} from 'vue';

import {
  MagnerFormInput,
} from 'magner';

import { InfoFilled, Delete } from '@element-plus/icons-vue';
import { list, removeComment } from '~/api/pets';
import { formatDate } from '~/utils/date-transform';

const props = defineProps({
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
      isNew: boolean,
    }>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<any>,
    required: true,
  },
});

const comments = ref(await list({ id: props.modelValue }));

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
  
  return props.field.readOnly;
});

const remove = async (id: string, index: number) => {
  comments.value.data.splice(index, 1);

  await removeComment({ id });
};

const fields = {
  commentReadonly: {
    props: {
      readOnly: true,
    },
  },
};
</script>

<style lang="postcss">
.client-pets-comments {
  margin-bottom: 8px;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
}

.client-pets-comments__actions {
  display: flex;
  align-items: center;
  position: absolute !important;
  top: 10px;
  right: 8px;
}

.client-pets-comments-delete {
  height: 18px;
  width: 18px;
  cursor: pointer;
  margin-left: 8px;
}
</style>
