<template>
  <el-select
    v-model="selected"
    :disabled="!isCreate"
    @change="emit('update:modelValue', selected);"
  >
    <el-option
      v-for="item in items.data"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>
    
<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, onMounted,
} from 'vue';
import { AdminStatusSelect } from '~/types/common';
import { adminStatuses } from '~/utils/searchInit';
    
const props = defineProps({
  modelValue: {
    type: Object as PropType<AdminStatusSelect>,
    required: true,
  },
});

const items = ref(await adminStatuses({}));
    
const isCreate = ref(props.modelValue);
    
const emit = defineEmits(['update:modelValue']);
    
const selected = ref(props.modelValue?.value || 'active');
    
onMounted(() => {
  emit('update:modelValue', selected.value);
});
</script>
