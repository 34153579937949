<template>
  <div v-if="props.modelValue.length" class="w-full">
    <div v-for="item in props.modelValue" :key="item.id" class="w-full orders-reports-comments">
      <div class="el-form-item__label">Комментарий выгульщика для своих // {{ item.pet.name }} </div>

      <div>
        <MagnerFormInput
          :model-value="item.comment"
          :field="fields.commentReadonly"
        />
      </div>
    </div>
  </div>

  <div v-else class="w-full">
    <div class="el-form-item__label">Комментарии выгульщиков </div>
    <div>Нет комментариев</div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  PropType,
} from 'vue';

import {
  MagnerFormInput,
} from 'magner';

import type { WalkerPetComment } from '~/features/orders/types';

const props = defineProps({
  modelValue: {
    type: Array as PropType<WalkerPetComment[]>,
    required: true,
  },
});

const fields = {
  commentReadonly: {
    props: {
      readOnly: true,
    },
  },
};
</script>

<style lang="postcss">
.orders-reports-comments {
  margin-bottom: 12px;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0px;
  }
}
</style>
