<template>
  <div class="orders-report-not-found">
    <el-result
      icon="warning"
      title="Отчет по данному заказу еще не готов"
    />
  </div>
</template>

<script lang="ts" setup>
</script>

<style>
.orders-report-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 194px);
  width: 100%;
}
</style>
