import type {
  City, Breed, Price, ValueLabel, 
} from '~/types/common';
import type { OfferRead, OfferOption, OfferRate } from '~/features/catalog/services/types';
import type { Client } from '~/features/users/clients/types';

export type Audience = 'clients' | 'segment'

export enum Statuses {
  planned = 'planned',
  sent = 'sent',
  active = 'active',
  cancelled = 'cancelled',
}

export enum Types {
  once = 'once',
  birthday = 'birthday',
}

export interface ListType {
  label: string, 
  value: Types,
}

export interface ListStatus {
  label: string,
  value: Statuses,
}

export interface List {
  id: string,
  code: string,
  title: string,
  text: string,
  link: string,
  city: string,
  type: ListType,
  status: ListStatus,
  audience: Audience,
  dispatchTime: string,
  minPetAge: number,
  maxPetAge: number,
  breeds: string[],
  petAge: string,
  onlyNewClients: boolean,
  clients: { value: string}[],
}

export interface Filters {
  city: string[],
  status: Statuses[],
  link: string[],
  audience: string[],
  title: string,
  breed: string[],
}  

export interface Read {
  code: string,
  title: string,
  text: string,
  city: string | City,
  link: string,
  audience: Audience,
  type: Types,
  status: Statuses,
  dispatchTime: string | null,
  minPetAge: number,
  maxPetAge: number,
  onlyNewClients: true,
  breeds: string[],
  clients: string[],

  // helpers
  transition?: 'link' | 'app',
  id?: string,
  breedsNone?: boolean,
  petAge?: any,
  petAgeNone?: boolean,
}

export interface Edit {
  code: string,
  title: string,
  text: string,
  city: string | City,
  link: string | ValueLabel,
  audience: Audience,
  type: Types,
  status: Statuses,
  dispatchTime?: string | null,
  minPetAge: number,
  maxPetAge: number,
  onlyNewClients: true,
  breeds: string[],
  clients: string[],

  // helpers
  transition?: 'link' | 'app',
  id?: string,
  breedsNone?: boolean,
  petAge?: any,
  petAgeNone?: boolean,
}

export interface UpdateStatus {
  status: Statuses,
}
