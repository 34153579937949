import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { orderRu } from '../index';
import { report, create, update } from '../requests';
import { t } from '../ru';
import type { Report } from '../types';
import { toDateTimeLocale } from '~/utils/date-transform';

import OrdersReportImages from './components/orders-report-images.vue';
import OrdersReportMap from './components/orders-report-map.vue';
import OrdersReportAfterWalks from './components/orders-report-after-walks.vue';
import OrdersReportComments from './components/orders-report-comments.vue';

export const cardConfig = cardPageController<Report>({
  header: {
    title: orderRu('form.title'),
    tabs: [
      {
        label: 'Карточка',
        link: (route) => ({ name: 'order', params: { id: route.params.id } }),
        active: false,
      },
      {
        label: 'Чат',
        link: (route) => ({
          name: 'chats-view',
          params: { id: route.params.id },
        }),
        active: false,
      },
      {
        label: 'Отчет',
        link: (route) => ({
          name: 'order-report',
          params: { id: route.params.id },
        }),
        active: true,
      },
    ],
  },

  getRequest: report,
  updateRequest: update,
  createRequest: create,

  form: {
    actions: [
      {
        type: 'link',
        to: (route): any => ({ name: 'orders' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      // {
      //   type: 'link',
      //   to: (route): any => ({ name: 'order-report-edit', params: { route } }),
      //   props: {
      //     text: baseT('form.edit_button'),
      //     class: 'width-auto',
      //     type: 'primary',
      //   },
      // },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** startTime, urgent, distance */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.walkDate'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.walkDate'),
                    readOnly: true,
                    readOnlyFormatter: (val) => new Date(val as string)
                      .toLocaleDateString()
                      .replaceAll('/', '.'),
                  },
                },
                {
                  type: 'select',
                  name: 'asap',
                  options: [
                    {
                      label: 'Да',
                      value: true,
                    },
                    {
                      label: 'Нет',
                      value: false,
                    },
                  ],
                  label: t('form.fields.urgent'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => (val ? 'Да' : 'Нет'),
                  },
                },
                {
                  type: 'input',
                  name: 'distance',
                  label: t('form.fields.distance'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => `${val || 0} км`,
                  },
                },
              ],
            },
            /** Start plan, end plan, walking time */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'datetime',
                  name: 'startTime',
                  label: t('form.fields.startPlan'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.startPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => toDateTimeLocale(val),
                  },
                },
                {
                  type: 'datetime',
                  name: 'completedAtPlan',
                  label: t('form.fields.finishPlan'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.finishPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => toDateTimeLocale(val),
                  },
                },
                {
                  type: 'input',
                  name: 'walkingDuration',
                  label: t('form.fields.walkingTime'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => `${val || 0} мин`,
                  },
                },
              ],
            },
            /** Start fact, end fact, walking time fact */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'datetime',
                  name: 'startTimeFact',
                  label: t('form.fields.startPlanFact'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.startPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => toDateTimeLocale(val),
                  },
                },
                {
                  type: 'datetime',
                  name: 'completedAtFact',
                  label: t('form.fields.finishPlanFact'),
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    placeholder: t('form.fields.finishPlan'),
                    readOnly: true,
                    readOnlyFormatter: (val) => toDateTimeLocale(val),
                  },
                },
                {
                  type: 'input',
                  name: 'walkingDurationAtFact',
                  label: t('form.fields.walkingTimeFact'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => `${val || 0} мин`,
                  },
                },
              ],
            },
            /** Сomment for client */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'textarea',
                  name: 'commentForClient' as 'finish',
                  label: t('form.fields.commentForClient'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },

            /** Comment for your own */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'walkerPetComments',
                  component: () => OrdersReportComments,
                  props: {},
                },

                {
                  type: 'input',
                  name: 'reportUrl' as 'finish',
                  label: t('form.fields.reportUrl'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },

            /** Images */
            {
              type: 'column',
              title: t('form.fields.images'),
              props: {
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'walkingPhotos',
                  component: () => OrdersReportImages,
                  props: {},
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            /** Map */
            {
              type: 'column',
              title: t('form.fields.map'),
              props: {
                // span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'route',
                  component: () => OrdersReportMap,
                  props: {},
                },
                {
                  type: 'input',
                  name: 'activitiesOnWalking',
                  dataType: 'string',
                  props: {
                    hidden: true,
                  },
                },
              ],
            },
            /** After walks */
            {
              type: 'column',
              title: t('form.fields.after_walks'),
              props: {
                // span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'finish',
                  component: () => OrdersReportAfterWalks,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
