/* eslint-disable no-return-await */
import { ElMessage } from 'element-plus';
import { request } from '~/utils/request';

const API_URL = import.meta.env.VITE_APP_API_URL;

interface FileUploadResponse {
  id: string,
  imagePath: string,
}

type Context = 'image' | 'default' | 'userAvatar' | 'product_image' | 'geoData';

export const fileUpload = (file: File, context: Context) => {
  const fileCreate = request.custom<FileUploadResponse, File>(async ({ api, data }) => await api
    .instance
    .url(`${API_URL}v1/client/file/upload`, true)
    .formData({
      file: data,
      context,
    })
    .post()
    .unauthorized(() => window.location.replace('/login'))
    .json()
    .then((response) => {
      const uploaded = (response as { data: { id: string } })?.data;

      const result = uploaded?.id ? { data: uploaded } : { error: 'Не удалось загрузить файл' };

      return result;
    })
    .catch((e) => {
      ElMessage({
        message: 'Не удалось загрузить файл',
        type: 'error',
      });

      return {
        error: 'Не удалось загрузить файл',
      } as any;
    }));

  return fileCreate(file);
};

export const fileUploadMultiple = (files: File[], context: Context) => {
  const fileCreate = request.custom< FileUploadResponse[], File[]>(async ({ api, data }) => await api
    .instance
    .url(`${API_URL}v1/client/file/upload_multiple`, true)
    .formData({
      files: data,
      context,
    })
    .post()
    .unauthorized(() => window.location.replace('/login'))
    .json()
    .then((response) => ({ data: response.data.items }))
    .catch((e) => {
      ElMessage({
        message: 'Не удалось загрузить файлы',
        type: 'error',
      });

      return {
        error: 'Не удалось загрузить файлы',
      } as any;
    }));

  return fileCreate(files);
};

export const saveImage = request
  .custom<FileUploadResponse, File>(async ({ data }) => fileUpload(data, 'userAvatar'));

export const saveImageContextImage = request
  .custom<FileUploadResponse, File>(async ({ data }) => fileUpload(data, 'image'));

export const saveImageContext = (context: Context) => request
  .custom<FileUploadResponse, File>(async ({ data }) => fileUpload(data, context));
