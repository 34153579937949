import { filtersFormController } from 'magner';
import { baseT } from 'configs/translation/base';
import { searchCities, searchBreed } from '~/utils/searchInit';
import type { Filters } from './types';
import { audienceList, adsPushStatusesList } from '~/utils/list';
import { linksList } from './requests';

const discountsFilters = filtersFormController<Filters>({
  fieldsShowAmount: 5,
  actions: [
    {
      type: 'link',
      to: { name: 'ads-push-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    city: '',
    status: '',
    link: '',
    audience: '',
    breed: '',
    title: '',
  },
  layout: [
    {
      type: 'select',
      name: 'city',
      options: [],
      props: {
        placeholder: baseT('label.city'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: searchCities,
      },
    },
    {
      type: 'input',
      name: 'title',
      props: {
        placeholder: 'Заголовок',
      },
    },
    {
      type: 'select',
      name: 'audience',
      options: audienceList,
      props: {
        placeholder: 'Тип аудитории',
        multiple: true,
        collapseTags: true,
        clearable: true,
        valueKey: 'value',
        labelKey: 'label',
      },
    },
    {
      type: 'select',
      name: 'breed',
      options: [],
      props: {
        placeholder: 'Породы питомцев',
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'title',
        remoteMethod: searchBreed,
      },
    },
    {
      type: 'select',
      name: 'link',
      options: [],
      props: {
        placeholder: 'Переход',
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'value',
        labelKey: 'label',
        remoteMethod: linksList,
      },
    },
    {
      type: 'select',
      name: 'status',
      options: adsPushStatusesList,
      props: {
        placeholder: 'Статус',
        multiple: true,
        collapseTags: true,
        clearable: true,
        valueKey: 'value',
        labelKey: 'label',
      },
    },
  ],
});

export default discountsFilters;
