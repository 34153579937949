export const API_URL = import.meta.env.VITE_APP_API_URL as string;
export const API_VERSION = import.meta.env.VITE_APP_API_VERSION as string;
export const ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT as 'demo' | 'staging' | 'prod' || 'demo';
export const PROJECT_NAME = 'petshop' as string;

// eslint-disable-next-line no-shadow
export enum ROLE {
  ADMIN = 'ROLE_ADMIN',
  MANAGER = 'ROLE_MANAGER',
  SUPERADMIN = 'ROLE_SUPER_ADMIN'
}
