<template>
  <div class="discounts-selector">
    <div>
      <el-select
        v-if="!readOnly"
        v-model="search"
        :filterable="true"
        placeholder="Поиск"
        no-data-text="Ничего не найдено"
        :load-remote-method-on-focus="true"
        :remote="true"
        :remote-method="remoteMethod"
        :loading="isLoading"
        value-key="id"
        label-key="name"
        @change="onChange"
        @focus="remoteMethod('')"
      >
        <el-option
          v-for="item in options"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>

      <div class="discounts-selector__items">
        <div 
          v-for="item, index in clients" 
          :key="item.id" 
          class="discounts-selector__item"
        >
          <div class="discounts-selector__input">
            <p>Телефон</p>

            <MagnerFormInput
              v-model="item.phone"
              :field="readOnlyInput"
            />
          </div>

          <div class="discounts-selector__input">
            <p>Имя</p>

            <MagnerFormInput
              v-model="item.name"
              :field="readOnlyInput"
            />
          </div>

          <div class="discounts-selector__input">
            <p>Фамилия</p>

            <MagnerFormInput
              v-model="item.surname"
              :field="readOnlyInput"
            />
          </div>

          <div v-if="!readOnly" class="discounts-selector__action">
            <el-button 
              :icon="Delete" 
              circle  
              @click="onDelete(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  computed,
  ref,
  onMounted,
} from 'vue';
import {
  MagnerFormInput,
} from 'magner';
import { Delete } from '@element-plus/icons-vue';
import { search as searchClients } from '~/features/users/clients/requests';
import type { Client } from '~/features/users/clients/types';
import type { Read } from '../types';

const props = defineProps({
  form: {
    type: Object as PropType<Read>,
    required: true,
  },
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  modelValue: {
    type: Array as PropType<Client[]>,
    default: () => [],
  },
});

const emit = defineEmits(['update:modelValue']);

const search = ref('');
const options = ref<Client[]>([]);
const isLoading = ref(false);

const clients = ref<Client[]>(props.modelValue || []);

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
  return props.field.readOnly;
});

const remoteMethod = async (search: string) => {
  isLoading.value = true;

  const newOptions = await searchClients({
    search,
    form: props.form,
  });
  
  options.value = newOptions.data as [] || [];

  isLoading.value = false;
};

const update = () => emit('update:modelValue', clients.value.map((item) => item.id));

const onChange = (value: string) => {
  const client = options.value.find((item) => item.id === value);

  if (client) {
    clients.value.unshift(client);
  }

  search.value = '';

  update();
};

const onDelete = (index: number) => {
  clients.value.splice(index, 1);

  update();
};

const readOnlyInput = { props: { readOnly: true } } as any;

onMounted(() => {
  update();
});
</script>

<style scoped>
.discounts-selector {
  width: 100%;
}

.discounts-selector__items {
  display: flex; 
  flex-direction: column; 
  gap: 12px;
}

.discounts-selector__item {
  display: flex; 
  align-items: flex-end;
  width: 100%; 
  gap: 12px;
}

.discounts-selector__input {
  width: calc(100% - ((32px / 3) + 6px))
}

.discounts-selector__action button {
  margin-bottom: 0px;
}

</style>
