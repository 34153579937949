import { actionCard, openModal, cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { t } from './ru';
import { read, create, update } from './requests';
import CustomTable from './components/custom-table/index.vue';
import cardEdit from './card-edit';
import type { List } from './types';

export const cardConfig = cardPageController<{ items: List[] }>({
  header: {
    title: t('table.title'),
    actions: [
      {
        type: 'action',
        props: {
          type: 'primary',
          class: 'blue-btn',
          text: baseT('form.create_new_button'),
        },
        emits: 'update-table' as 'submit',
        action: actionCard(async () => {
          try {
            await openModal({
              type: 'card',
              config: cardEdit,
              customClass: 'modal-base',
              props: {
                emptyCard: true,
                entityId: null,
              },
            });
            return false;
          }
          catch (e) {
            return false;
          }
        }),
      },
    ],
  },

  getRequest: read as any,
  // @ts-ignore
  createRequest: create,
  // @ts-ignore
  updateRequest: update,

  form: {
    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {},
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'items',
                  dataType: 'array',
                  component: () => CustomTable,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
