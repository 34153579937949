import { request } from '~/utils/request';
import { getListResponse, sortTable, filtersTable } from '~/utils/get-list';
import type {
  List, Read, Edit, Filters, 
} from './types';
import { userRoles } from '~/utils/searchInit';

const BASE = '/admin-user';

export const read = request.table<List>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sort = sortTable(
    data.sort,
    [
      'name',
      'surname',
      'roles',
      'phone',
      'email',
      'status',
    ],
  );

  const filters = filtersTable(data.filters as Filters, ['name', 'roles', 'email', 'phone', 'statuses']);

  const roles = await userRoles({});

  const result = await getListResponse<List>(`${BASE}?${pagination}${sort}${filters}`, activePage);

  return {
    data: {
      ...result.data,
      rows: result.data.rows.map((row) => ({
        ...row,
        roles: row.roles
          .map((item) => roles?.data?.find((role) => role.value === item)?.label || '')
          .filter((item) => item),
      })),
    },
  };
});

export const get = request.card<Read, Edit>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Read }>(`${BASE}/read/${data.id}`);

  const roles = await userRoles({});

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data?.data,
      roles: res.data.data.roles.filter((item) => roles?.data?.find((role) => role.value === item)),
    },
  };
});

export const create = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.post<{ data: Read }>(`${BASE}/create`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'admins' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.patch<{ data: Read }>(
      `${BASE}/update/${data.id}`,
      data.data,
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'admins' });

    return {
      data: res.data.data,
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'admins' });

    return { data: res.data?.data };
  },
);

export const updateStatus = request.card<Read, Edit>(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.patch(`${BASE}/update/${data.id}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data?.data,
    };
  },
);
