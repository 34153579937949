import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { adminsT } from './index';
import {
  get, create, update, remove, 
} from './requests';
import type { Edit } from './types';
import { userRoles } from '~/utils/searchInit';
import AdminStatusCard from './components/admin-status-card.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: adminsT('card.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'admins' }
          : { name: 'admins-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: baseT('label.userName'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.userName'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'surname',
                  label: baseT('label.surname'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.surname'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'select',
                  name: 'roles',
                  options: [],
                  label: baseT('label.role'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.role'),
                    multiple: true,
                    remote: true,
                    remoteMethod: userRoles,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'custom',
                  name: 'status',
                  label: baseT('label.status'),
                  component: () => AdminStatusCard,
                  props: {},
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },

            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'phone',
                  label: baseT('label.phone'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.phone'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'email',
                  label: baseT('label.email'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.email'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {},
          layout: [],
        },
      ],
    },
  },
});
