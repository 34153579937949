import { cardPageController } from 'magner';

import { baseT } from 'configs/translation/base';
import { discountsT } from './index';
import type { Edit } from './types';
import { searchCities, searchBreedAll } from '~/utils/searchInit';
import {
  get, create, update, remove, 
} from './requests';

import DiscountsStatusCard from './components/discounts-status-card.vue';
import DiscountType from './components/discounts-type.vue';
import DiscountsSelector from './components/discounts-selector.vue';
import DiscountsPromo from './components/discounts-promo.vue';
import DiscountsObject from './components/discounts-object.vue';
import DiscountAudience from './components/discounts-audience.vue';
import DiscountValidPeriod from './components/discounts-valid-period.vue';
import DiscountPetAge from './components/discounts-pet-age.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: discountsT('card.title'),
  },

  getRequest: get as any,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'discounts' }
          : { name: 'discounts-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          class: 'width-auto',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Code, isActive */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'id',
                  dataType: 'string',
                  props: {
                    hidden: true,
                  },
                },
                {
                  type: 'input',
                  name: 'code',
                  label: 'Название служебное',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Введите название',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'custom',
                  name: 'active',
                  label: 'Статус',
                  component: () => DiscountsStatusCard,
                  props: {},
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Caption */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'caption',
                  label: 'Название рекламное',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Введите название',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
              ],
            },
            /** City */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: 'Город',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Выберите один город',
                    valueKey: 'id',
                    labelKey: 'name',
                    remote: true,
                    remoteMethod: searchCities,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Date */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'validPeriod',
                  label: 'Срок действия',
                  props: {},
                  component: () => DiscountValidPeriod,
                  changeAction: ({ form }) => {
                    form.validPeriodDate = null;
                  },
                },
                {
                  type: 'datetime',
                  name: 'validPeriodDate',
                  dataType: 'date',
                  props: {
                    type: 'datetimerange',
                    startPlaceholder: 'От',
                    endPlaceholder: 'До',
                    format: 'YYYY-MM-DD HH:mm',
                    rangeSeparator: '–',
                    hidden: ({ state }) => state.validPeriod !== 'period',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'datetime',
                  name: 'validPeriodDate',
                  dataType: 'date',
                  props: {
                    type: 'datetimerange',
                    startPlaceholder: 'От',
                    endPlaceholder: 'До',
                    format: 'YYYY-MM-DD HH:mm',
                    rangeSeparator: '–',
                    disabled: true,
                    hidden: ({ state }) => state.validPeriod === 'period',
                  },
                },
              ],
            },
            /** Type and value discount */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'custom',
                  name: 'type',
                  label: 'Размер скидки',
                  props: {
                    required: true,
                  },
                  component: () => DiscountType,
                  changeAction: ({ form }) => {
                    form.value = null;
                  },
                },
                {
                  type: 'input',
                  name: 'value',
                  label: '\u0000',
                  dataType: 'string',
                  props: {
                    placeholder: '...%',
                    type: 'number',
                    hidden: ({ state }) => state.type !== 'percent',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                    {
                      type: 'number-abs' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'value',
                  label: '\u0000',
                  dataType: 'string',
                  props: {
                    placeholder: '... ₽',
                    type: 'number',
                    hidden: ({ state }) => state.type !== 'ruble',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                    {
                      type: 'number-abs' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
              ],
            },
            /** Release */

            /** Size discount */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'custom',
                  name: 'promoType',
                  label: 'Реализация',
                  props: {
                    required: true,
                  },
                  component: () => DiscountsPromo,
                  changeAction: ({ form }) => {
                    form.promo = null;
                  },
                },
                {
                  type: 'input',
                  name: 'promo',
                  label: '\u0000',
                  dataType: 'string',
                  props: {
                    placeholder: 'Придумайте промокод',
                    hidden: ({ state }) => state.promoType !== 'promo',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'promo',
                  label: '\u0000',
                  dataType: 'string',
                  props: {
                    hidden: ({ state }) => state.promoType !== 'app',
                    readOnly: true,
                    readOnlyFormatter: () => '–',
                  },
                },
              ],
            },
            {
              type: 'column',
              title: 'Объект скидки',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                /** Name, status */
                {
                  type: 'row',
                  props: {
                    justify: 'space-between',
                    elementsGrow: true,
                    styles: { gap: '12px', flexWrap: 'nowrap' },
                  },
                  layout: [
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'custom',
                          name: 'objectData',
                          props: {
                            required: true,
                            disabled: ({ state }) => !state.city,
                          },
                          component: () => DiscountsObject,
                          validation: [
                            {
                              type: 'empty-required-discounts-object' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              title: 'Условия применения',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                /** Name, status */
                {
                  type: 'row',
                  props: {
                    justify: 'space-between',
                    elementsGrow: true,
                    styles: { gap: '12px', flexWrap: 'nowrap' },
                  },
                  layout: [
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'minPrice',
                          label: 'Минимальная сумма заказа',
                          dataType: 'string',
                          props: {
                            type: 'number',
                            placeholder: '... ₽',
                            hidden: ({ state }) => state.needMinPrice,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'minPrice',
                          label: 'Минимальная сумма заказа',
                          dataType: 'string',
                          props: {
                            disabled: true,
                            hidden: ({ state }) => !state.needMinPrice,
                          },
                        },
                        {
                          type: 'switch',
                          name: 'needMinPrice',
                          dataType: 'boolean',
                          props: {
                            activeLabel: 'Не требуется',
                          },
                          changeAction: ({ form }) => {
                            form.minPrice = null;
                          },
                        },
                      ],
                    },
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'maxPrice',
                          label: 'Максимальная сумма заказа',
                          dataType: 'string',
                          props: {
                            type: 'number',
                            placeholder: '... ₽',
                            hidden: ({ state }) => state.needMaxPrice as boolean,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'maxPrice',
                          label: 'Максимальная сумма заказа',
                          dataType: 'string',
                          props: {
                            disabled: true,
                            hidden: ({ state }) => !state.needMaxPrice,
                          },
                        },
                        {
                          type: 'switch',
                          name: 'needMaxPrice',
                          dataType: 'boolean',
                          props: {
                            activeLabel: 'Не требуется',
                          },
                          changeAction: ({ form }) => {
                            form.maxPrice = null;
                          },
                        },
                      ],
                    },
                  ],
                },
                /** counter */
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'maxApplyCount',
                      label: 'Многократное использование, количество раз',
                      dataType: 'string',
                      props: {
                        type: 'number',
                        placeholder: 'Введите число',
                        hidden: ({ state }) => state.needMaxApplyCount,
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'blur',
                        },
                        {
                          type: 'number-abs' as 'empty',
                          trigger: 'blur',
                        },
                      ],
                    },

                    {
                      type: 'input',
                      name: 'maxApplyCount',
                      label: 'Многократное использование, количество раз',
                      dataType: 'string',
                      props: {
                        placeholder: '',
                        disabled: true,
                        hidden: ({ state }) => !state.needMaxApplyCount,
                      },
                    },
                    {
                      type: 'switch',
                      name: 'needMaxApplyCount',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Однократная скидка',
                      },
                      changeAction: ({ form }) => {
                        form.maxApplyCount = null;
                      },
                    },
                  ],
                },
                /** compatibility */
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'select',
                      name: 'compatibility',
                      options: [{ label: 'Да', value: 'all' }],
                      label: 'Совместимость с другими скидками',
                      dataType: 'string',
                      props: {
                        placeholder: 'Выберите',  
                        required: true,
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            {
              type: 'column',
              title: 'Аудитория',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'custom',
                      name: 'audience',
                      props: {
                        required: true,
                      },
                      component: () => DiscountAudience,
                      changeAction: ({ form }) => {
                        if (form.audience !== 'clients') {
                          form.clients = [];

                          return;
                        } 

                        form.breeds = [];
                        form.breedsNone = false;
                        form.petAge = null;
                        form.petAgeNone = false;
                        form.onlyNewClients = false;
                      },
                    },
                    /* Audience segment */
                    // breeds
                    {
                      type: 'select',
                      name: 'breeds',
                      options: [],
                      label: 'Порода питомцев',
                      dataType: 'string',
                      props: {
                        placeholder: 'Выберите один или несколько пород',
                        multiple: true,
                        remote: true,
                        valueKey: 'id',
                        labelKey: 'title',
                        filterable: true,
                        remoteMethod: searchBreedAll,
                        hidden: ({ state }) => state.breedsNone || state.audience !== 'segment',
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                    {
                      type: 'input',
                      name: 'breeds',
                      label: 'Порода питомцев',
                      dataType: 'string',
                      props: {
                        disabled: true,
                        hidden: ({ state }) => !state.breedsNone || state.audience !== 'segment',
                      },
                    },
                    {
                      type: 'switch',
                      name: 'breedsNone',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Любая порода',
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                      changeAction: ({ form }) => {
                        form.breeds = [];
                      },
                    },
                    // age
                    {
                      type: 'custom',
                      name: 'petAge',
                      label: 'Возраст питомцев',
                      props: { 
                        hidden: ({ state }) => state.petAgeNone || state.audience !== 'segment',
                      },
                      component: () => DiscountPetAge,
                      validation: [
                        {
                          type: 'empty-required-pet-age' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                    {
                      type: 'custom',
                      name: 'petAge',
                      label: 'Возраст питомцев',
                      props: {
                        hidden: ({ state }) => !state.petAgeNone || state.audience !== 'segment',
                        disabled: true,
                      },
                      component: () => DiscountPetAge,
                    },
                    {
                      type: 'switch',
                      name: 'petAgeNone',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Любой возраст',
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                      changeAction: ({ form }) => {
                        form.petAge = null;
                      },
                    },
                    // is new client
                    {
                      type: 'switch',
                      name: 'onlyNewClients',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Заказов еще не было',
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                    },

                    /* Clients segment */
                    {
                      type: 'custom',
                      name: 'clients',
                      component: () => DiscountsSelector,
                      props: {
                        hidden: ({ state }) => state.audience !== 'clients',
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
