import { cardPageController } from 'magner';

import { baseT } from 'configs/translation/base';
import { adsPushT } from './index';
import { Statuses, type Edit } from './types';
import { searchCities, searchBreedAll } from '~/utils/searchInit';
import {
  get, create, update, remove, 
} from './requests';

import AdsPushStatusCard from './components/ads-push-status-card.vue';
import AdsPushType from './components/ads-push-type.vue';
import AdsPushTransition from './components/ads-push-transition.vue';
import AdsPushAudience from '~/features/catalog/discounts/components/discounts-audience.vue';
import AdsPushPetAge from '~/features/catalog/discounts/components/discounts-pet-age.vue';
import AdsPushSelector from '~/features/catalog/discounts/components/discounts-selector.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: adsPushT('card.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  form: {
    actions: [

      {
        type: 'link',
        to: () => ({ name: 'ads-push' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'link',
        to: (route): any => ({ name: 'ads-push-edit', params: { route } }),
        props: {
          text: baseT('form.edit_button'),
          class: 'width-auto',
          type: 'primary',
          hidden: ({ state }) => state.status === Statuses.sent,
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'id',
                  dataType: 'string',
                  props: {
                    hidden: true,
                  },
                },
                {
                  type: 'input',
                  name: 'code',
                  label: 'Название служебное',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'custom',
                  name: 'status',
                  label: 'Статус',
                  component: () => AdsPushStatusCard,
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'type',
                  label: 'Отправка',
                  props: {
                    readOnly: true,
                  },
                  component: () => AdsPushType,
                },
                {
                  type: 'datetime',
                  name: 'dispatchTime',
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    disabled: true,
                  },
                },
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: baseT('label.city'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.name,
                  },
                },
                {
                  type: 'custom',
                  name: 'transition',
                  label: 'Переход',
                  props: {
                    readOnly: true,
                  },
                  component: () => AdsPushTransition,
                },
                {
                  type: 'input',
                  name: 'link',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    hidden: ({ state }) => state.transition === 'app',
                  },
                },
                {
                  type: 'select',
                  name: 'link',
                  options: [],
                  dataType: 'string',
                  props: {
                    hidden: ({ state }) => state.transition !== 'app',
                    readOnly: true,
                    readOnlyFormatter: (val) => val?.label,
                  },
                },
                {
                  type: 'input',
                  name: 'title',
                  label: 'Заголовок',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'input',
                  name: 'text',
                  label: 'Текст',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              title: 'Аудитория',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'custom',
                      name: 'audience',
                      props: {
                        required: true,
                        readOnly: true,
                      },
                      component: () => AdsPushAudience,
                      changeAction: ({ form }) => {
                        if (form.audience !== 'clients') {
                          form.clients = [];
  
                          return;
                        } 
  
                        form.breeds = [];
                        form.breedsNone = false;
                        form.petAge = null;
                        form.petAgeNone = false;
                        form.onlyNewClients = false;
                      },
                    },
                    /* Audience segment */
                    // breeds
                    {
                      type: 'select',
                      name: 'breeds',
                      options: [],
                      label: 'Порода питомцев',
                      dataType: 'string',
                      props: {
                        hidden: ({ state }) => state.audience !== 'segment',
                        disabled: true,
                        remote: true,
                        multiple: true,
                        valueKey: 'id',
                        labelKey: 'title',
                        remoteMethod: searchBreedAll,
                      },
                    },
                    {
                      type: 'switch',
                      name: 'breedsNone',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Любая порода',
                        hidden: ({ state }) => state.audience !== 'segment',
                        disabled: true,
                      },
                    },
                    // age
                    {
                      type: 'custom',
                      name: 'petAge',
                      label: 'Возраст питомцев',
                      props: {
                        readOnly: true,
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                      component: () => AdsPushPetAge,
                    },
                    {
                      type: 'switch',
                      name: 'petAgeNone',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Любой возраст',
                        hidden: ({ state }) => state.audience !== 'segment',
                        disabled: true,
                      },
                    },
                    // is new client
                    {
                      type: 'switch',
                      name: 'onlyNewClients',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Заказов еще не было',
                        hidden: ({ state }) => state.audience !== 'segment',
                        disabled: true,
                      },
                    },
  
                    /* Clients segment */
                    {
                      type: 'custom',
                      name: 'clients',
                      component: () => AdsPushSelector,
                      props: {
                        hidden: ({ state }) => state.audience !== 'clients',
                        readOnly: true,
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
