import {
  tableController,
  tablePageController,
} from 'magner';
import clientsFilters from 'features/users/clients/filters';
import ClientStatusTable from './components/client-status-table.vue';
import { t } from './ru';
import type { ClientList } from './types';
import { read } from './requests';

export const tableConfig = tablePageController<ClientList>({
  header: {
    title: t('table.title'),
  },
  request: read,
  table: tableController<ClientList>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: t('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: t('table.headers.firstname'),
        sortable: true,
        className: 'fw-bold',
        fixed: 'left',
        width: 100,
      },
      {
        prop: 'surname',
        label: t('table.headers.lastname'),
        sortable: true,
        className: 'fw-bold',
        fixed: 'left',
        width: 100,
      },
      {
        prop: 'cities',
        label: t('table.headers.city'),
        view: {
          type: 'text',
          // TODO Вопрос с городом
          formatter: (row) => row.split(',')[0],
        },
      },
      {
        prop: 'email',
        label: t('table.headers.email'),
        sortable: true,
      },
      {
        prop: 'phone',
        label: t('table.headers.phone'),
        sortable: true,
      },
      {
        prop: 'petBreeds',
        label: t('table.headers.petBreeds'),
        showOverflowTooltip: true,
      },
      {
        prop: 'petAges',
        label: t('table.headers.petAges'),
      },
      {
        prop: 'cities',
        label: t('table.headers.cities'),
      },
      {
        prop: 'districts',
        label: t('table.headers.districts'),
      },
      {
        prop: 'contacts',
        label: t('table.headers.contacts'),
        className: 'table-contacts',
      },
      {
        prop: 'status',
        label: t('table.headers.status'),
        view: {
          type: 'custom',
          component: () => ClientStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'client',
      params: { id: row.id },
    }),
  }),
  filters: clientsFilters,
});
