import { ft } from '~/utils/feature-translate';

export const AmmunitionsRu = {
  sidebar: {
    table_title: 'Взяли амуницию?',
  },
  table: {
    title: 'Взяли амуницию?',
    not_found: 'Результатов не найдены',
    headers: {
      name: 'Название',
    },
  },

  form: {
    name: {
      title: 'Название',
      placeholder: 'Введите название',
    },
  },
};

export const t = ft(AmmunitionsRu, 'content.ammunitions.');
