import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { walkersT } from 'features/users/walkers';
import {
  searchCities,
  searchPeculiarities,
  searchBreedDogs,
  searchBreedCats,
  clientCardSearchDistricts,
  suggustedStreets,
} from '~/utils/searchInit';
import { t } from './ru';
import { Client } from './types';
import { get, create, update } from './requests';
import ClientStatusCard from './components/client-status-card.vue';
import { AddressInterface } from '~/types';
import WalkersComments from '~/components/walkers-comments.vue';
import { petsTypes } from '~/utils/list';

export const cardConfig = cardPageController<Client>({
  header: {
    title: 'Клиент',
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,

  form: {
    actions: [
      {
        type: 'link',
        to: (route) => ({ name: 'client', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Name, Surname, ID */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: t('form.fields.name'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: t('form.fields.name'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'surname',
                  label: t('form.fields.surname'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: t('form.fields.surname'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'id',
                  label: t('form.fields.id'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.id'),
                    disabled: true,
                    hidden: true,
                  },
                },
              ],
            },
            /**   Phone, Type, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                align: 'middle',
                styles: { gap: '12px', 'flex-wrap': 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'phone',
                  label: t('form.fields.phone'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: t('form.fields.phone'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'custom',
                  name: 'status',
                  label: walkersT('form.fields.status'),
                  component: () => ClientStatusCard,
                  props: {},
                },
              ],
            },
            /**  Email, City */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                align: 'middle',
                styles: { gap: '12px' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'email',
                  label: t('form.fields.email'),
                  props: {
                    type: 'email',
                    placeholder: t('form.fields.email'),
                  },
                },
                {
                  type: 'select',
                  options: [],
                  name: 'city',
                  label: t('form.fields.city'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: t('form.fields.city'),
                    remote: true,
                    valueKey: 'id',
                    labelKey: 'name',
                    remoteMethod: searchCities,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Pets */
            {
              type: 'column',
              title: t('form.pets.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'pets',
                  dataType: 'array',
                  props: {
                    showFirst: true,
                    firstRemovable: true,
                    createFirstItemIfNew: true,
                  },
                  layout: [
                    /** Pets type */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'radio',
                          name: 'petType',
                          options: petsTypes,
                          dataType: 'string',
                          props: {
                            required: true,
                            disabled: (_: any, currentItem: any) => !!(currentItem.id),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                          changeAction: (_: any, currentItem: any) => {
                            currentItem.breed = null;
                          },
                        },
                      ],
                    },
                    /** Name, Breed */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'custom',
                          name: 'id',
                          dataType: 'number',
                          component: () => null,
                          props: {
                            hidden: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'name',
                          label: t('form.pets.fields.name'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.pets.fields.name'),
                            disabled: (data, parent?: { id: string | null }) => !!(parent && parent?.id),
                          },
                          validation: [
                            {
                              type: 'empty-required-array' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                        {
                          type: 'select',
                          options: [],
                          name: 'breed',
                          label: t('form.pets.fields.breed'),
                          props: {
                            required: true,
                            placeholder: t('form.pets.fields.breed'),
                            remote: true,
                            valueKey: 'id',
                            labelKey: 'title',
                            remoteMethod: searchBreedDogs,
                            filterable: true,
                            disabled: (data, parent?: { id: string | null }) => !!(parent && parent?.id),
                            // eslint-disable-next-line max-len
                            hidden: (_: any, currentItem: any) => currentItem.petType !== 'dog' || !currentItem.petType,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'select',
                          options: [],
                          name: 'breed',
                          label: t('form.pets.fields.breed'),
                          props: {
                            required: true,
                            placeholder: t('form.pets.fields.breed'),
                            remote: true,
                            valueKey: 'id',
                            labelKey: 'title',
                            remoteMethod: searchBreedCats,
                            filterable: true,
                            disabled: (data, parent?: { id: string | null }) => !!(parent && parent?.id),
                            // eslint-disable-next-line max-len
                            hidden: (_: any, currentItem: any) => currentItem.petType !== 'cat' || !currentItem.petType,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'custom',
                          name: 'breedId',
                          dataType: 'string',
                          component: () => null,
                          props: {
                            hidden: true,
                          },
                        },
                      ],
                    },
                    /** Age, Weight, Gender */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'datetime',
                          name: 'birthdate',
                          label: t('form.pets.fields.age'),
                          dataType: 'date',
                          props: {
                            required: true,
                            type: 'date',
                            format: 'DD.MM.YYYY',
                            placeholder: t('form.pets.fields.age'),
                            disabled: (data, parent?: { id: string | null }) => !!(parent && parent?.id),
                            disabledDate: (time: Date) => {
                              const allowedDateOfBirth = new Date();
                              // 4 месяца минимальный возраст питомца для регистрации
                              allowedDateOfBirth.setMonth(
                                allowedDateOfBirth.getMonth() - 4,
                              );
                              return (
                                time.getTime() > allowedDateOfBirth.getTime()
                              );
                            },
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'weight',
                          label: t('form.pets.fields.weight'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.pets.fields.weight'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                            {
                              type: 'number-only' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'radio',
                          name: 'sex',
                          options: [
                            {
                              label: 'Мальчик',
                              value: 'm',
                            },
                            {
                              label: 'Девочка',
                              value: 'f',
                            },
                          ],
                          label: t('form.pets.fields.gender'),
                          dataType: 'string',
                          props: {
                            required: true,
                            radioButtons: true,
                            disabled: (data, parent?: { id: string | null }) => !!(parent && parent?.id),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** Peculiarities */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'select',
                          name: 'peculiarities',
                          label: t('form.pets.fields.peculiarities'),
                          dataType: 'string',
                          options: [],
                          props: {
                            placeholder: t('form.pets.fields.peculiarities'),
                            multiple: true,
                            valueKey: 'id',
                            labelKey: 'title',
                            filterable: true,
                            remote: true,
                            remoteMethod: searchPeculiarities,
                          },
                        },
                      ],
                    },
                    /** Comment */
                    {
                      type: 'row',
                      props: {
                        align: 'center',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'textarea',
                          name: 'comment',
                          label: t('form.pets.fields.comment'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.pets.fields.comment'),
                          },
                        },

                        {
                          type: 'custom',
                          name: 'id',
                          props: {
                            hidden: (_: any, currentItem: any) => !currentItem.id,
                          },
                          component: () => WalkersComments,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
          },
          layout: [
            /** Addresses */
            {
              type: 'column',
              title: t('form.address.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'addresses',
                  dataType: 'array',
                  props: {
                    showFirst: true,
                    firstRemovable: true,
                    createFirstItemIfNew: true,
                  },
                  layout: [
                    /** City, District */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'select',
                          name: 'city',
                          options: [],
                          label: t('form.fields.city'),
                          dataType: 'string',
                          changeAction: (_, currentItem: AddressInterface) => {
                            currentItem.comment = '';
                            currentItem.district = '';
                            currentItem.doorCode = '';
                            currentItem.entrance = '';
                            currentItem.floor = '';
                            currentItem.house = '';
                            currentItem.office = '';
                            currentItem.street = '';
                          },
                          props: {
                            class: 'w-100',
                            valueKey: 'id',
                            labelKey: 'name',
                            filterable: true,
                            required: true,
                            remote: true,
                            remoteMethod: searchCities,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'select',
                          name: 'district',
                          dataType: 'string',
                          options: [],
                          label: t('form.address.fields.district'),
                          props: {
                            class: 'w-100',
                            valueKey: 'id',
                            labelKey: 'slug',
                            required: true,
                            remote: true,
                            filterable: true,
                            remoteMethod: clientCardSearchDistricts,
                            loadRemoteMethodOnFocus: true,
                            disabled: (
                              _,
                              currentItem?: { city: string | null },
                            ) => !currentItem?.city,
                          },
                          changeAction: (_, currentItem: AddressInterface) => {
                            currentItem.street = '';
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** Street, House */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'select',
                          name: 'street',
                          dataType: 'string',
                          options: [],
                          label: t('form.address.fields.street'),
                          props: {
                            class: 'w-100',
                            required: true,
                            remote: true,
                            filterable: true,
                            remoteMethod: suggustedStreets,
                            loadRemoteMethodOnFocus: true,
                            disabled: (
                              form,
                              currentItem?: {
                                city: string | null,
                                district: string,
                              },
                            ) => !currentItem?.city || !currentItem.district,
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'house',
                          label: t('form.address.fields.house'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.address.fields.house'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                      ],
                    },
                    /** Entrance, Floor, Flat, DoorCode */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'entrance',
                          label: t('form.address.fields.porch'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.porch'),
                          },
                        },
                        {
                          type: 'input',
                          name: 'floor',
                          label: t('form.address.fields.floor'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.floor'),
                          },
                        },
                        {
                          type: 'input',
                          name: 'office',
                          label: t('form.address.fields.flat'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.flat'),
                          },
                        },
                        {
                          type: 'input',
                          name: 'doorCode',
                          label: t('form.address.fields.intercom'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.intercom'),
                          },
                        },
                      ],
                    },
                    /** Comment */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        // styles: { 'gap': '12px' },
                      },
                      fields: [
                        {
                          type: 'textarea',
                          name: 'comment',
                          label: t('form.address.fields.comment'),
                          dataType: 'string',
                          props: {
                            placeholder: t('form.address.fields.comment'),
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            /** Contacts */
            {
              type: 'column',
              title: t('form.contacts.title'),
              props: {
                span: 24,
                titleType: 'heading',
                isPaper: true,
              },
              fields: [
                {
                  type: 'collection',
                  name: 'contacts',
                  dataType: 'array',
                  props: {
                    showFirst: true,
                    firstRemovable: true,
                    createFirstItemIfNew: true,
                  },
                  layout: [
                    /** Phone, Name, Surname */
                    {
                      type: 'row',
                      props: {
                        justify: 'space-between',
                        elementsGrow: true,
                        styles: { gap: '12px' },
                      },
                      fields: [
                        {
                          type: 'custom',
                          name: 'id',
                          dataType: 'number',
                          component: () => null,
                          props: {
                            hidden: true,
                          },
                        },
                        {
                          type: 'input',
                          name: 'phone',
                          label: t('form.contacts.fields.phone'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.contacts.fields.phone'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'name',
                          label: t('form.contacts.fields.firstname'),
                          // dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.contacts.fields.firstname'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'surname',
                          label: t('form.contacts.fields.lastname'),
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: t('form.contacts.fields.lastname'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'blur',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
