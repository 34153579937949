<template>
  <MagnerFormRadio
    v-model="model"
    :field="{
      props: {
        disabled: readOnly
      },
      options: adsPushTypesList,
    }"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>
    
<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  computed,
  ref,
} from 'vue';
import {
  MagnerFormRadio,
} from 'magner';
    
import { adsPushTypesList } from '~/utils/list';
    
const props = defineProps({
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  form: {
    type: Object as PropType<{ id: string }>,
    required: true,
  },
  modelValue: {
    type: String as PropType<string>,
    default: null,
  },
});
    
const emit = defineEmits(['update:modelValue']);
    
const model = ref(props.modelValue || 'birthday');
    
const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
    
  return props.field.readOnly;
});
    
if (!props.form.id) {
  emit('update:modelValue', model.value);
}
</script>
