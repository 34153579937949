<template>
  <div v-show="name === 'Москва'" style="position:relative;overflow:hidden;width: 100%; margin-top: 12px;">
    <a href="https://yandex.ru/maps/213/moscow/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Москва</a><a href="https://yandex.ru/maps/213/moscow/?ll=37.624464%2C55.777417&mode=usermaps&source=constructorLink&um=constructor%3A1ada3cef19f67ecdbb5d0f2dfb39b3a656584254ab8ef429b1ccf61b4f73772e&utm_medium=mapframe&utm_source=maps&z=12" style="color:#eee;font-size:12px;position:absolute;top:14px;">Карта Москвы с улицами и номерами домов — Яндекс Карты</a><iframe
      src="https://yandex.ru/map-widget/v1/?ll=37.624464%2C55.777417&mode=usermaps&source=constructorLink&um=constructor%3A1ada3cef19f67ecdbb5d0f2dfb39b3a656584254ab8ef429b1ccf61b4f73772e&z=12"
      frameborder="1"
      allowfullscreen="true"
      style="width: 100%; height: 592px; max-height: 80vh;"
    />
  </div>

  <div v-show="name === 'Санкт-Петербург'" style="position:relative;overflow:hidden;width: 100%; margin-top: 12px;">
    <a href="https://yandex.ru/maps/2/saint-petersburg/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Санкт‑Петербург</a><a href="https://yandex.ru/maps/2/saint-petersburg/?ll=30.233272%2C60.025724&mode=usermaps&source=constructorLink&um=constructor%3A6048d0e7a32abe570253a92a1f3c439339862abc53bf3d90e36c9608866102ff&utm_medium=mapframe&utm_source=maps&z=13" style="color:#eee;font-size:12px;position:absolute;top:14px;">Карта Санкт-Петербурга с улицами и номерами домов — Яндекс Карты</a><iframe
      src="https://yandex.ru/map-widget/v1/?ll=30.233272%2C60.025724&mode=usermaps&source=constructorLink&um=constructor%3A6048d0e7a32abe570253a92a1f3c439339862abc53bf3d90e36c9608866102ff&z=11"
      frameborder="1"
      allowfullscreen="true"
      style="width: 100%; height: 592px; max-height: 80vh;"
    />
  </div>
</template>

<script setup lang="ts">
import {
  defineProps, PropType, computed, ref,
} from 'vue';

import { cititesOptions } from '~/utils/searchInit';

const props = defineProps({
  modelValue: {
    type: Object as PropType<any>,
    required: true,
  },
}); 

const cities = ref(await cititesOptions());

const value = computed(() => props.modelValue?.id || props.modelValue);

const name = computed(() => cities.value.data.find((item) => item.id === value.value)?.name);
</script>
