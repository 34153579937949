class AudioInstance {
  inctance: HTMLAudioElement

  constructor (url: string) {
    this.inctance = new Audio(url);
  }

  play () {
    this.inctance.play();
  }
}

export default AudioInstance;
