import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { citiesT } from './index';
import {
  get, create, update, remove, 
} from './requests';
import type { Edit } from './types';

import { statuses } from '~/utils/list';
import CitiesMap from './components/cities-map.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: citiesT('card.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  form: {
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'cities' }
          : { name: 'cities-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** City, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'name',
                  label: citiesT('card.form.city.title'),
                  dataType: 'string',
                  props: {
                    readOnly: true,
                  },
                },
                {
                  type: 'select',
                  name: 'status',
                  options: statuses,
                  label: citiesT('card.form.status'),
                  dataType: 'string',
                  props: {
                    disabled: true,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Telegram */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'telegram',
                  label: citiesT('card.form.telegram.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: citiesT('card.form.telegram.placeholder'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Phone */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'supportPhone',
                  label: citiesT('card.form.phone.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: citiesT('card.form.phone.placeholder'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {},
          layout: [
            {
              type: 'column',
              title: citiesT('card.form.map'),
              props: {
                span: 24,
                xs: 24,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'id',
                  component: () => CitiesMap,
                  props: {},
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
