import { h } from 'vue';
import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { servicesT } from './index';
import {
  get, create, update, remove,
} from './requests';
import { t } from './ru';
import { searchCities, offerCategories } from '~/utils/searchInit';
import type { OfferEdit } from './types';

import ServicesStatusCard from './components/services-status-card.vue';
import ServicesMaxPets from './components/services-max-pets.vue';
import ServicesMaxInputPets from './components/services-max-input-pets.vue';

export const cardConfig = cardPageController<OfferEdit>({
  header: {
    title: servicesT('card.title'),
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'services' }
          : { name: 'services-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },
      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          class: 'width-auto',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Category, City, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'select',
                  name: 'category',
                  options: [],
                  label: baseT('label.category'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.category'),
                    remote: true,
                    remoteMethod: offerCategories,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: baseT('label.city'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.city'),
                    valueKey: 'id',
                    labelKey: 'name',
                    remote: true,
                    remoteMethod: searchCities,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'custom',
                  name: 'status',
                  label: baseT('label.status'),
                  component: () => ServicesStatusCard,
                  props: {
                    required: true,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Name, Description */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'input',
                  name: 'title',
                  label: baseT('label.name'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.text'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                }, 
                {
                  type: 'input',
                  name: 'description',
                  label: servicesT('card.form.description.title'),
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: baseT('placeholder.text'),
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Max number of pets */
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'maxNumberOfPets',
                  label: servicesT('card.form.max_number_of_pets'),
                  component: () => ServicesMaxInputPets,
                  props: {
                    required: true,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
            /** Tariff */
            {
              type: 'column',
              title: t('card.form.tariff.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'offerRates',
                  dataType: 'array',
                  props: {
                    createFirstItemIfNew: true,
                  },
                  layout: [
                    /** Time, price, walker fee */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'id',
                          dataType: 'string',
                          props: { hidden: true },
                        },
                        {
                          type: 'input',
                          name: 'time',
                          label: t('card.form.tariff.time'),
                          dataType: 'string',
                          props: {
                            type: 'number',
                            required: true,
                            placeholder: baseT('placeholder.number'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'price',
                          label: t('card.form.tariff.price'),
                          dataType: 'string',
                          props: {
                            type: 'number',
                            required: true,
                            placeholder: baseT('placeholder.price'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'walkerFee',
                          label: t('card.form.tariff.walker_fee'),
                          dataType: 'string',
                          props: {
                            type: 'number',
                            required: true,
                            placeholder: baseT('placeholder.price'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'column',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                      },
                      fields: [
                        {
                          type: 'custom',
                          name: 'id',
                          props: {},
                          component: () => h(
                            'div', { class: 'el-form-item__label el-form-item__label--separator' }, 'Доп. питомец',
                          ),
                        },
                      ],
                    },
                    /** Additional pet price, additional pet walker fee */
                    {
                      type: 'row',
                      props: {
                        align: 'bottom',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'additionalPetPrice',
                          label: t('card.form.tariff.additional_pet_price'),
                          dataType: 'string',
                          props: {
                            type: 'number',
                            required: true,
                            placeholder: baseT('placeholder.price'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                        {
                          type: 'input',
                          name: 'additionalPetWalkerFee',
                          label: t('card.form.tariff.additional_pet_walker_fee'),
                          dataType: 'string',
                          props: {
                            type: 'number',
                            required: true,
                            placeholder: baseT('placeholder.price'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** Hidden input */
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          props: {
                            hidden: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            /** Types */
            {
              type: 'column',
              title: t('card.form.type'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'offerTypes',
                  dataType: 'array',
                  props: {
                    showFirst: true,
                    createFirstItemIfNew: true,
                    firstRemovable: true,
                  },
                  layout: [
                    /** Title */
                    {
                      type: 'row',
                      props: {
                        align: 'top',
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'id',
                          dataType: 'string',
                          props: { hidden: true },
                        },
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          props: {
                            required: true,
                            placeholder: baseT('placeholder.text'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },

            /** Options */
            {
              type: 'column',
              title: t('card.form.options.title'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'collection',
                  name: 'offerOptions',
                  dataType: 'array',
                  props: {
                    showFirst: true,
                    createFirstItemIfNew: true,
                    firstRemovable: true,
                  },
                  layout: [
                    /** Title, max number of pets */
                    {
                      type: 'row',
                      props: {
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'id',
                          dataType: 'string',
                          props: { hidden: true },
                        },
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          label: servicesT('card.form.options.name'),
                          props: {
                            placeholder: baseT('placeholder.text'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },

                        {
                          type: 'custom',
                          name: 'maxNumberOfPets',
                          label: servicesT(
                            'card.form.options.max_number_of_pets',
                          ),
                          component: () => ServicesMaxPets,
                          props: {
                            required: true,
                            options: ['123'],
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** Prompt */
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'prompt',
                          dataType: 'string',
                          label: servicesT('card.form.options.prompt'),
                          props: {
                            required: true,
                            placeholder: baseT('placeholder.text'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },
                    /** Price, walker fee */
                    {
                      type: 'row',
                      props: {
                        elementsGrow: true,
                        styles: { gap: '12px', 'flex-wrap': 'nowrap' },
                      },
                      fields: [
                        {
                          type: 'input',
                          name: 'price',
                          dataType: 'string',
                          label: servicesT('card.form.options.price'),
                          props: {
                            required: true,
                            type: 'number',
                            placeholder: baseT('placeholder.price'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },

                        {
                          type: 'input',
                          name: 'walkerFee',
                          dataType: 'string',
                          label: servicesT('card.form.options.walker_fee'),
                          props: {
                            required: true,
                            type: 'number',
                            placeholder: baseT('placeholder.price'),
                          },
                          validation: [
                            {
                              type: 'empty-required' as 'empty',
                              trigger: 'change',
                            },
                          ],
                        },
                      ],
                    },

                    /** Hidden input */
                    {
                      type: 'column',
                      props: {},
                      fields: [
                        {
                          type: 'input',
                          name: 'title',
                          dataType: 'string',
                          props: {
                            hidden: true,
                          },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
