import { ApiError } from 'magner';
import type {
  City,
  Breed,
  Pecularity,
  WalkingStatus,
  ValueLabel,
  RolesSelect,
  AdminStatusSelect,
} from '~/types/common';
import { request } from '~/utils/request';
import { NotificationDirection, WalkingCancelReasons } from '~/types/common';
import { OfferRead } from '~/features/catalog/services/types';

export interface InitResponse {
  cities: City[],
  petBreeds: Breed[],
  allPetBreeds: Breed[],
  petPeculiarities: Pecularity[],
  walkingStatuses: WalkingStatus[],
  notificationTypes: ValueLabel[],
  notifyDirection: NotificationDirection | null,
  paymentTypes: ValueLabel[],
  sources: ValueLabel[],
  cancelReasons: WalkingCancelReasons[],
  offers: OfferRead[],
  deepLinks: ValueLabel[],
  userRoles: RolesSelect[],
  adminStatuses: AdminStatusSelect[],
  offerCategories: ValueLabel[],
  offerStatuses: ValueLabel[],
}

const initialData: InitResponse = {
  cities: [],
  petBreeds: [],
  allPetBreeds: [],
  petPeculiarities: [],
  walkingStatuses: [],
  paymentTypes: [],
  notificationTypes: [],
  notifyDirection: null,
  sources: [],
  cancelReasons: [],
  offers: [],
  deepLinks: [],
  userRoles: [],
  adminStatuses: [],
  offerCategories: [],
  offerStatuses: [],
};

export const init = {
  data: { ...initialData },
  async set (): Promise<boolean> {
    const res = await request.api.get<{ data: InitResponse }>('/init');
    if (res.error) {
      this.data = { ...initialData };
      return (res.error instanceof ApiError && res.error.status === 401);
    }

    this.data = {
      ...res.data?.data,
      allPetBreeds: res.data.data.petBreeds.filter((item) => item.id !== null) || [],
    };

    return false;
  },
  get () {
    return this.data;
  },
};
