import { ft } from '~/utils/feature-translate';

export const AdminsRu = {
  sidebar: {
    table_title: 'Сотрудники',
    card_title: 'Сотрудник',
  },
  table: {
    title: 'Сотрудники',
    not_found: 'Сотрудники не найдены',
    headers: {
      name: 'Имя',
      surname: 'Фамилия',
      roles: 'Роль',
      email: 'E-mail',
      phone: 'Телефон',
      status: 'Статус',
    },
  },
  filters: {
    name: 'Имя или фамилия',
    roles: 'Роль',
    email: 'E-mail',
    phone: 'Телефон',
    statuses: 'Статус',
  },
  card: {
    title: 'Сотрудник',
    form: {
    },
  },
};

export const t = ft(AdminsRu, 'users.admins.');
