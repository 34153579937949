<template>
  <el-input-number
    v-model="model"
    :min="1"
    :max="50"
    style="width: 100%; text-align: left"
    :controls="false"
    @change="$emit('update:modelValue', $event?.target?.value)"
    @blur="$emit('update:modelValue', $event?.target?.value)"
  />
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, onMounted,

} from 'vue';

const props = defineProps({
  modelValue: {
    type: Object as PropType<number>,
    required: true,
  },
}); 

const emit = defineEmits(['update:modelValue']);

const model = ref(props.modelValue || 1);

onMounted(() => emit('update:modelValue', model.value));

</script>
