<template>
  <MagnerFormRadio
    v-model="model"
    :field="{
      props: {
        disabled: readOnly
      },
      options: transitionList,
    }"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>
      
<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  computed,
  ref,
} from 'vue';
import {
  MagnerFormRadio,
} from 'magner';
      
import { transitionList } from '~/utils/list';
      
const props = defineProps({
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
      isNew: boolean,
    }>,
    required: true,
  },
  modelValue: {
    type: String as PropType<string>,
    default: null,
  },
});
      
const emit = defineEmits(['update:modelValue']);
      
const model = ref(props.modelValue || 'link');
      
const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
      
  return props.field.readOnly;
});
      
// emit('update:modelValue', model.value);
</script>
