<template>
  <div style="width: 100%">
    <yandex-map
      v-if="props.modelValue"
      apikey="23e70276-ed11-4097-ae5e-2d0a03b8f5a0"
      :settings="{
        location: {
          center: [props.modelValue.routePoints[0].longitude, props.modelValue.routePoints[0].latitude],
          zoom: 18,
        },
      }"
      width="100%"
      height="319px"
    >
      <yandex-map-default-scheme-layer />
      <yandex-map-default-features-layer />

      <yandex-map-feature
        :settings="{
          geometry: {
            type: 'LineString',
            coordinates: lineCoordinates,
          },
          style: {
            stroke: [{ color: '#FF5100', width: 2 }],
          },
        }"
      />

      <yandex-map-marker
        v-for="marker, i in props.form.activitiesOnWalking"
        :key="i"
        :settings="{ coordinates: [marker.longitude, marker.latitude] }"
        position="top left-center"
      >
        <div
          class="trace-action"
          :class="`trace-action--${marker.activityType}`"
        />
      </yandex-map-marker>

      <yandex-map-marker
        :settings="{ coordinates: [props.modelValue.routePoints[0].longitude, props.modelValue.routePoints[0].latitude] }"
        position="top left-center"
      >
        <div
          class="trace-action trace-action--start"
        />
      </yandex-map-marker>
    </yandex-map>

    <!-- activities -->
    <div class="orders-report-map__progress">
      <div 
        v-for="item in activities" 
        :key="item.value" 
        class="orders-report-map__progress-item"
      >
        <div :class="`icon-${item.value}`" />

        <span style="font-size: 10px; line-height: 14px">
          {{ item.label }} {{ activity(item.value as ActivityTypeValue) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, PropType, computed } from 'vue';
import {
  YandexMap, YandexMapDefaultSchemeLayer, YandexMapDefaultFeaturesLayer, YandexMapMarker, YandexMapFeature, 
} from 'vue-yandex-maps';

import type { Report, ReportRoute, ActivityTypeValue } from '~/features/orders/types';

const props = defineProps({
  modelValue: {
    type: Object as PropType<ReportRoute>,
    required: true,
  },
  form: {
    type: Object as PropType<Report>,
    required: true,
  },
});

const activities = computed(() => [
  {
    label: 'Поиграли',
    value: 'played',
  },
  {
    label: 'Пописали',
    value: 'peed',
  },
  {
    label: 'Покакали',
    value: 'pooped',
  },
  {
    label: 'Занятие',
    value: 'worked',
  },
]);

const rotate = () => `rotate(${Math.floor((1 + Math.random() * (180 + 1 - 1)))}deg)`;

const activity = (type: ActivityTypeValue) => {
  const activities = props.form.activitiesOnWalking.filter((item) => item.activityType.value === type);

  return activities.length ? `x ${activities.length}` : '- не было';
};

const lineCoordinates = computed(() => props.modelValue.routePoints.map((item) => [item.longitude, item.latitude]));
</script>

<style scoped>
.pin {
  cursor: pointer;
  max-width: unset;
  width: 75px;
  height: 75px;
  border-radius: 50%;
}
.orders-report-map__progress {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 36px;
}

.orders-report-map__progress-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 120px
}

.orders-report-map__progress-item > div {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.06);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: rgba(56, 56, 56, 1);
  margin-bottom: 4px;
  background-repeat: no-repeat;
  background-position: center;
}

.icon-peed {
 background-image: url(~/assets/icons/peed.svg)
}

.icon-pooped {
 background-image: url(~/assets/icons/pooped.svg)
}

.icon-played {
 background-image: url(~/assets/icons/gaming.svg)
}

.icon-worked {
 background-image: url(~/assets/icons/work.svg)
}

.trace {
  width: 12px;
  height: 12px;
  background: url(~/assets/icons/paw.svg) no-repeat center;
  background-size: contain
}

.trace-action {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50% auto;
  background-image: url(~/assets/icons/paw-peed.svg)
}

.trace-action--start {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid #FF5100;
  background-color: white;
  background-image: none;
}
</style>
