<template>
  <el-select
    v-model="selected"
    :disabled="!isCreate"
    style="width: 300px"
    @change="emit('update:modelValue', selected);"
  >
    <el-option
      v-for="item in statuses"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>
  
<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, onMounted,
} from 'vue';
import { statuses } from '~/utils/list';
import type { Status } from '~/types/common/status';
  
const props = defineProps({
  modelValue: {
    type: Object as PropType<{ id: string, value: Status.active | Status.inactive }>,
    required: true,
  },
}); 
  
const isCreate = ref(props.modelValue);
  
const emit = defineEmits(['update:modelValue']);
  
const selected = ref(props.modelValue || 'active');
  
onMounted(() => {
  emit('update:modelValue', selected.value);
});
</script>

<style>
.page-services-main-edit .input-isActive {
  max-width: 185px
}
</style>
