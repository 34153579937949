import {
  actionTable,
  filtersFormController,
  tableController,
  tablePageController,
} from 'magner';
import { Walkers, walkersT } from 'features/users/walkers/index';
import walkersFilters from 'features/users/walkers/filters';
import type { WalkersList } from './types';
import { read } from './requests';
import WalkerStatusTable from './components/walker-status-table.vue';
import { ROLE } from '~/constants';

export const tableConfig = tablePageController<WalkersList>({
  header: {
    title: walkersT('table.title'),
  },
  request: read,
  table: tableController<WalkersList>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: walkersT('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: walkersT('table.headers.name'),
        className: 'fw-bold',
        sortable: true,
      },
      {
        prop: 'surname',
        label: walkersT('table.headers.surname'),
        className: 'fw-bold',
        sortable: true,
      },
      {
        prop: 'city',
        label: 'Город',
        className: 'fw-bold',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row: any) => row?.name || '',
        },
      },
      {
        prop: 'rating',
        label: 'Рейтинг',
        className: 'fw-bold',
        sortable: true,
      },
      {
        prop: 'hasOffers',
        label: 'Услуги',
        className: 'fw-bold',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row: any) => (row ? 'Есть' : 'Нет'),
        },
      },
      {
        prop: 'telegram',
        label: 'Telegram',
      },
      {
        prop: 'email',
        label: walkersT('table.headers.email'),
      },
      {
        prop: 'phone',
        label: walkersT('table.headers.phone'),
      },
      {
        prop: 'status',
        label: walkersT('table.headers.status'),
        sortable: true,
        view: {
          type: 'custom',
          component: () => WalkerStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'walker',
      params: { id: row.id },
    }),
  }),
  filters: walkersFilters,
});
