import {
  tableController,
  tablePageController,
} from 'magner';

import { discountsT } from './index';
import discountsFilters from './filters';
import { read } from './requests';
import type {
  List, ObjectList, CompatibilityList, AudienceList,
} from './types';
import type { Price } from '~/types/common';
import { formatDate } from '~/utils/date-transform';

import DiscountsStatusTable from './components/discounts-status-table.vue';

export const tableConfig = tablePageController<List>({
  header: {
    title: discountsT('table.title'),
  },
  filters: discountsFilters as any,
  request: read,
  table: tableController<List>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: discountsT('table.not_found'),
    columns: [
      {
        prop: 'code',
        label: 'Название служебное',
        sortable: true,
      },
      {
        prop: 'caption',
        label: 'Название рекламное',
        sortable: true,
      },
      {
        prop: 'cityCaption',
        label: 'Город прогулки',
        sortable: true,
      },
      {
        prop: 'validPeriodCaption',
        label: 'Срок действия',
        sortable: true,
        view: {
          type: 'text',
          formatter: (cell, row) => {
            if (row.startTime && row.endTime) {
              return `${formatDate(row.startTime)} – ${formatDate(row.endTime)}`;
            }

            return cell as string;
          },
        },
      },
      {
        prop: 'object',
        label: 'Объект скидки',
        sortable: true,
        view: {
          type: 'html',
          formatter: (cell, row) => {
            if ((cell as ObjectList).value === 'check') {
              return (cell as ObjectList).label;
            }

            return `${(cell as ObjectList).label} <br /> ${row.objectCaption}`;
          },
        },
      },
      {
        prop: 'value',
        label: 'Размер скидки',
        sortable: true,
      },
      {
        prop: 'promo',
        label: 'Реализация',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => (row as string || 'В приложении'),
        },
      },
      {
        prop: 'minPrice',
        label: 'Минимальная сумма заказа',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => (row ? `${(row as Price).inRubles} ₽` : 'Не требуется'),
        },
      },
      {
        prop: 'maxPrice',
        label: 'Максимальная сумма заказа',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => (row ? `${(row as Price).inRubles} ₽` : 'Не требуется'),
        },
      },
      {
        prop: 'maxApplyCount',
        label: 'Многократное использование',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => row as string || 'Нет',
        },
      },
      {
        prop: 'compatibility',
        label: 'Совместимость с другими скидками',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => (row as CompatibilityList).label,
        },
      },
      {
        prop: 'audience',
        label: 'Тип аудитории',
        view: {
          type: 'text',
          formatter: (row) => (row as AudienceList).label,
        },
      },
      {
        prop: 'breeds',
        label: 'Породы питомцев',
        view: {
          type: 'html',
          formatter: (row) => (row as string[]).join(', <br />'),
        },
      },
      {
        prop: 'petAge',
        label: 'Возраст питомцев',
        sortable: true,
      },
      {
        prop: 'onlyNewClients',
        label: 'Заказов еще не было',
        sortable: true,
        view: {
          type: 'text',
          formatter: (row) => (row ? 'Да' : 'Нет'),
        },
      },
      {
        prop: 'clients',
        label: 'Конкретный клиент',
        view: {
          type: 'html',
          formatter: (row) => (row as string[]).join('<br />'),
        },
      },
      {
        prop: 'active',
        label: 'Статус',
        sortable: true,
        fixed: 'right',
        view: {
          type: 'custom',
          component: () => DiscountsStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'discounts-view',
      params: { id: row.id },
    }),
  }),
});
