import { openModal } from 'magner';
import cancelWalkingConfig from 'features/orders/modals/cancel-walking';
import { WalkingStatuses } from '~/types/common';

export const getStatusSelectColorClass = (value: WalkingStatuses) => {
  if (value === 'ready_for_walking' || value === 'completed' || value === 'walking') {
    return 'status-green';
  }
  if (value === 'waiting_for_payment') {
    return 'status-red';
  }
  if (value === 'canceled') {
    return 'status-border-red';
  }
  if (value === 'looking_for_walker') {
    return 'status-orange';
  }

  return '';
};

export const openCancelWalkingModal = (async ({ data }: { data: { id: string } }) => {
  await openModal<any>({
    type: 'card',
    config: cancelWalkingConfig,
    props: {
      emptyCard: true,
      entityId: data.id,
    },
  });

  return false;
});
