import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { orderRu } from '../index';
import { create, update } from '../requests';

import OrderReportNotFound from './components/orders-report-not-found.vue';

export const cardConfig = cardPageController<any>({
  header: {
    title: orderRu('form.title'),
    tabs: [
      {
        label: 'Карточка',
        link: (route) => ({ name: 'order', params: { id: route.params.id } }),
        active: false,
      },
      {
        label: 'Чат',
        link: (route) => ({
          name: 'chats-view',
          params: { id: route.params.id },
        }),
        active: false,
      },
      {
        label: 'Отчет',
        link: (route) => ({
          name: 'order-report',
          params: { id: route.params.id },
        }),
        active: true,
      },
    ],
  },

  getRequest: () => false as any,
  updateRequest: update as any,
  createRequest: create as any,

  form: {
    actions: [
      {
        type: 'link',
        to: (route): any => ({ name: 'orders' }),
        props: {
          text: baseT('form.back_button'),
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'column',
      props: {},
      fields: [
        {
          type: 'custom',
          name: 'id',
          component: () => OrderReportNotFound,
          props: {},
        },
      ],
    },
  },
});
