import { request } from '~/utils/request';
import { getListResponse, sortTable } from '~/utils/get-list';
import type {
  List, Read, Edit, District, UpdateStatus,
} from './types';

const BASE = '/city';

export const read = request.table<List>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sort = sortTable(
    data.sort,
    [
      'name',
      'telegram',
      'supportPhone',
      'status',
    ],
  );

  return getListResponse(`${BASE}?${pagination}${sort}`, activePage);
});

export const get = request.card<Read, Edit>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Read }>(`${BASE}/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data?.data,
    },
  };
});

export const create = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.post<{ data: Read }>(`${BASE}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'cities' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.patch<{ data: Read }>(
      `${BASE}/${data.id}`,
      data.data,
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'cities' });

    return {
      data: res.data.data,
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'cities' });

    return { data: res.data?.data };
  },
);

export const updateStatus = request.card<Read, UpdateStatus>(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.patch(`${BASE}/${data.id}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data?.data,
    };
  },
);

/* District */
export const getDistrict = request.card<District, District>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: District }>(`${BASE}/${data.id}/district`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return {
    data: {
      ...res.data?.data,
    },
  };
});

// eslint-disable-next-line max-len, vue/max-len
export const importDistrict = request.custom<District, { geoDataFileId: string, cityId: string }>(async ({ api, data, parseError }) => {
  const res = await api.post('/district/import', data);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return { data: res.data?.data };
});

export const removeDistrict = request.card(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.delete(`/district/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return { data: res.data?.data };
  },
);
