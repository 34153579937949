import { filtersFormController } from 'magner';
import { baseT } from 'configs/translation/base';
import { t } from 'features/users/clients/ru';
import { servicesT } from './index';
import { searchCities } from '~/utils/searchInit';
import type { Filters } from './types';
import { linksList } from './requests';

const servicesFilters = filtersFormController<Filters>({
  saveToLocalStorage: true,
  actions: [
    {
      type: 'link',
      to: { name: 'services-main-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    title: '',
    cities: '',
    links: '',
  },
  layout: [
    {
      type: 'input',
      name: 'title',
      props: {
        placeholder: servicesT('filters.title'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'cities',
      options: [],
      props: {
        placeholder: t('filters.city'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: searchCities,
      },
    },
    {
      type: 'select',
      name: 'links',
      options: [],
      props: {
        placeholder: 'Переход',
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'value',
        labelKey: 'label',
        remoteMethod: linksList,
      },
    },
  ],
});

export default servicesFilters;
