import { ft } from '~/utils/feature-translate';

export const ServicesRu = {
  sidebar: {
    table_title: 'Услуги на главной',
    card_title: 'Услуга на главной',
  },
  table: {
    title: 'Услуги на главной',
    not_found: 'Услуг на главной не найдены',
    headers: {
      title: 'Название рекламное',
      sort: 'Порядок сортировки',
      city: 'Город',
      transition: 'Переход',
    },
  },
  filters: {
    title: 'Название',
    city: 'Город',
    transition: 'Переход',
  },

  card: {
    title: 'Услуга на главной',

    form: {
      name: {
        title: 'Название рекламное',
        placeholder: 'Введите название',
        hint: 'Отобразится в приложении для клиентов',
      },
      sort: {
        title: 'Порядок сортировки',
        placeholder: 'Введите номер',
      },
      city: {
        title: 'Город',
        placeholder: 'Выберите город',
      },
      transition: {
        title: 'Переход',
        link: 'По ссылке',
        app: 'Внутри приложения',
      },
      link: {
        placeholder: 'https://...',
      },
      app: {
        placeholder: 'Выберите услугу или экран для перехода',
      },
      image: {
        title: 'Иконка',
      },
    },
  },
};

export const t = ft(ServicesRu, 'content.services.');
