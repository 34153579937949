import { User } from 'features/users/types';
import { request } from '~/utils/request';
import type { NullOrString } from '~/types';
import { init } from '~/utils/get-init';
import { ValueLabel } from '~/types/common';
import initNotification from '~/utils/notification';

type ProxyFunc<BEFORE, AFTER> = (data: BEFORE) => AFTER;

interface UserLogin extends User {
  login: string,
  avatarHigh: NullOrString,
  avatarMedium: NullOrString,
  avatarThumb: NullOrString,
  roles: string,
  mainRole: ValueLabel,
}

export interface Response {
  data: UserLogin,
  authenticated: {
    token: string,
    refreshToken: string,
  },
}

export interface Proxy {
  refreshToken: string,
  role: string,
  token: string,
  user: UserLogin,
}

export const dataToProxy: ProxyFunc<Response, Proxy> = (data) => ({
  token: data.authenticated.token,
  refreshToken: data.authenticated.refreshToken,
  role: data.data.mainRole.value,
  user: data.data,
});

const loginRequest = request.profile(async ({
  data, api, parseError, lstorage,
}) => {
  const bodyData = {
    login: data.login.trim(),
    password: data.password,
  };

  const res = await api.post<Response>('/auth/login', bodyData);

  if (res.error) {
    const e = parseError(res.error);
    lstorage.delete('token');
    lstorage.delete('id');
    lstorage.delete('role');
    api.instance.defaults({ headers: {} }, false);

    return {
      error: {
        message: Object.keys(e.fields).length ? '' : (e.message || 'Не удалось авторизоваться'),
        fields: e.fields,
      },
    };
  }

  const proxied = dataToProxy(res.data);

  lstorage.put('token', proxied.token);
  lstorage.put('id', proxied.user.id);
  lstorage.put('role', proxied.role);
  lstorage.put('refreshToken', proxied.refreshToken);

  request.api.instance.defaults({
    headers: {
      Authorization: `Bearer ${proxied.token}`,
    },
  });

  await init.set();
  initNotification();

  return {
    data: {
      user: proxied.user,
      role: proxied.role,
    },
  };
});

export default loginRequest;
