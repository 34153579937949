<!-- eslint-disable max-len -->
<!-- eslint-disable vue/max-len -->
<template>
  <div style="min-width: 180px !important;">
    <el-select
      :model-value="selected"
      :class="className"
      :disabled="
        selected !== WalkerStatuses.active 
          && selected !== WalkerStatuses.removing_requested
          && selected !== WalkerStatuses.blocked
      "
      @change="changeHandler"
      @click.prevent
    >
      <el-option
        v-for="item in selectItems"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="isOptionDisabled(item.value)"
      />
    </el-select>
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps, PropType, ref, watchEffect, computed,
} from 'vue';
import { WalkersList, WalkerStatuses } from 'features/users/walkers/types';
import { magnerConfirm } from 'magner';
import { tableStatusUpdate } from 'features/users/walkers/requests';
import { statuses } from 'features/users/walkers/filters';
import { roleChecker } from '~/utils/role-checker';
import { ROLE } from '~/constants';

const props = defineProps({
  row: {
    type: Object as PropType<WalkersList>,
    required: true,
  },
});

const selected = ref(props?.row?.status?.value);

const selectItems = computed(() => {
  if (selected.value === WalkerStatuses.active || selected.value === WalkerStatuses.blocked) {
    return statuses.filter((item) => item.value === WalkerStatuses.active || item.value === WalkerStatuses.blocked);
  }

  if (selected.value === WalkerStatuses.removing_requested) {
    return [
      statuses.find((item) => item.value === WalkerStatuses.removing_requested),
      statuses.find((item) => item.value === WalkerStatuses.active),
      statuses.find((item) => item.value === WalkerStatuses.removed),
    ];
  }

  return statuses;
});

const className = computed(() => {
  if (selected.value === 'active') {
    return 'status-green';
  }

  if (selected.value === WalkerStatuses.removing_requested) {
    return 'status-orange';
  }

  if (selected.value === WalkerStatuses.blocked) {
    return 'status-red';
  }

  return '';
});

const isOptionDisabled = (value: WalkerStatuses) => {
  if (!roleChecker(ROLE.SUPERADMIN) && !roleChecker(ROLE.ADMIN) && !roleChecker(ROLE.MANAGER)) {
    return true;
  }

  if (value === WalkerStatuses.removing_requested) {
    return true;
  }

  return false;
};

const changeHandler = (value: WalkerStatuses) => {
  console.log(value);
  if (value === WalkerStatuses.blocked) {
    magnerConfirm({
      message: 'Вы уверены что хотите заблокировать выгульщика?',
      title: 'Внимание',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      type: 'warning',
    }).then(() => {
      selected.value = value;
      tableStatusUpdate({ id: props.row.id, status: value } as any);
    }).catch(() => {
      selected.value = props.row.status.value;
    });
  }
  else {
    selected.value = value;
    tableStatusUpdate({ id: props.row.id, status: value } as any);
  }
};

watchEffect(() => {
  selected.value = props.row.status.value;
});

</script>
