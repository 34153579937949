import { request } from '~/utils/request';

export const changePass = request.custom(async ({ data, api, parseError }) => {
  const res = await api.patch('/auth/change-password', { email: data.data?.email });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  return res;
});
