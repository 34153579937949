import { baseRu } from 'configs/translation/base';
import { loginRu } from 'features/login/ru';
import { OrderRu } from 'features/orders/ru';
import { ClientRu } from 'features/users/clients/ru';
import { WalkersT } from 'features/users/walkers/ru';
import { AdminsRu } from 'features/users/admins/ru';
import { ServicesRu } from 'features/catalog/services/ru';
import { CitiesRu } from 'features/settings/cities/ru';
import { BannersRu } from 'features/content/banners/ru';
import { ServicesRu as ServicesMainRu } from 'features/content/services/ru';
import { DiscountsRu } from '~/features/catalog/discounts/ru';
import { AmmunitionsRu } from '~/features/content/ammunitions/ru';
import { ChatsRu } from '~/features/chat/ru';
import { AdsPushRu } from '~/features/ads-push/ru';
 
const ruCustom = {
  base: baseRu,
  login: loginRu,

  orders: OrderRu,

  users: {
    clients: ClientRu,
    walkers: WalkersT,
    admins: AdminsRu,
  },

  catalog: {
    discounts: DiscountsRu,
    services: ServicesRu,
  },

  settings: {
    cities: CitiesRu,
  },

  content: {
    banners: BannersRu,
    services: ServicesMainRu,
    ammunitions: AmmunitionsRu,
  },

  chats: ChatsRu,

  ads_push: AdsPushRu,
};

export type CustomTranslationSchema = typeof ruCustom;
export default ruCustom;
