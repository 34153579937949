import { City } from '~/types/common/places';

export enum WalkingCancelReasons {
  clientCanceled = 'client_canceled',
  walkerCanceled = 'walker_canceled',
  walkerNotFound = 'walker_not_found',
  other = 'other',
}

export interface WalkingCancelReason {
  comment: string | null,
  id: string,
  reason: {
    value: WalkingCancelReasons,
    label: string,
  },
}

export enum WalkingStatuses {
  waitingPayment = 'waiting_for_payment',
  lookingWalker = 'looking_for_walker',
  readyWalking = 'ready_for_walking',
  walking = 'walking',
  completed = 'completed',
  canceled = 'canceled',
}

export interface WalkingStatus {
  icon: string,
  label: string,
  value: WalkingStatuses,
}

export interface WalkingOption {
  id: string, // uuid
  title: string,
  price: number,
  maxNumberOfPets: number | null,
  disabled?: boolean, // for front
}

export interface WalkingType {
  id: string, // uuid
  title: string,
  price: number,
  city: City,
  options: WalkingOption[],
  additionalPetPrice: {
    inRubles: number,
  },
}

export interface WalkingTypeToOrderFrontend {
  type: WalkingType['id'],
  typeData: WalkingType,
  addition: string[],
  additionData: WalkingType['options'],
  userComment: string | null,
  manualDiscount: string,
  offer: string,
  offerType: string,
  offerTitle: string,
  typeTime: string,
  offerTypeTitle: string,
  additionTitles: string[],
  offerReadonly: any,
}
