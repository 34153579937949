import { tableController, tablePageController } from 'magner';

import { adminsT } from './index';
import { read } from './requests';
import type { List } from './types';
import filters from './filters';
import { type ValueLabel } from '~/types/common';

import AdminStatusTable from './components/admin-status-table.vue';

export const tableConfig = tablePageController<List>({
  header: {
    title: adminsT('table.title'),
  },
  request: read,
  filters: filters as any,
  table: tableController<List>({
    scrollbarAlwaysOn: true,
    emptyText: adminsT('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: adminsT('table.headers.name'),
        sortable: true,
      },
      {
        prop: 'surname',
        label: adminsT('table.headers.surname'),
        sortable: true,
      },
      {
        prop: 'roles',
        label: adminsT('table.headers.roles'),
        sortable: true,
        view: {
          type: 'html',
          formatter: (row) => (row as string[]).join('<br />'),
        },
      },
      {
        prop: 'email',
        label: adminsT('table.headers.email'),
        sortable: true,
      },
      {
        prop: 'phone',
        label: adminsT('table.headers.phone'),
        sortable: true,
      },
      {
        prop: 'status',
        label: adminsT('table.headers.status'),
        fixed: 'right',
        sortable: true,
        view: {
          type: 'custom',
          component: () => AdminStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'admins-view',
      params: { id: row.id },
    }),
  }),
});
