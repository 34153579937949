import { tableController, tablePageController } from 'magner';

import { citiesT } from './index';
import { read } from './requests';
import type { List } from './types';

import CitiesStatusTable from './components/cities-status-table.vue';

export const tableConfig = tablePageController<List>({
  header: {
    title: citiesT('table.title'),
  },
  request: read,
  filters: {
    layout: [],
    filtersData: {},
    sort: {},
    pagination: {
      items: 25,
      page: 1,
    },
  },
  table: tableController<List>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: citiesT('table.not_found'),
    columns: [
      {
        prop: 'name',
        label: citiesT('table.headers.name'),
        sortable: true,
      },
      {
        prop: 'telegram',
        label: citiesT('table.headers.telegram'),
        sortable: true,
      },
      {
        prop: 'supportPhone',
        label: citiesT('table.headers.phone'),
        sortable: true,
      },
      {
        prop: 'status',
        label: citiesT('table.headers.status'),
        sortable: true,
        fixed: 'right',
        view: {
          type: 'custom',
          component: () => CitiesStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'cities-view',
      params: { id: row.id },
    }),
  }),
});
