import { cancelWalking } from 'features/orders/requests';
import { cardPageController } from 'magner';
import { t } from 'features/orders/ru';
import { searchReasons } from '~/utils/searchInit';
import { WalkingCancelReasons } from '~/types/common';

interface CancelWalkingConfig {
  reason: WalkingCancelReasons,
  comment: string | null,
  trigger: boolean,
}

const cancelWalkingConfig = cardPageController<CancelWalkingConfig>({
  createRequest: cancelWalking,

  successMessage: {
    create: t('cancel_walking.success_notify'),
  },

  header: {
    title: 'Подтвердите отмену',
  },
  form: {
    actions: [
      {
        type: 'action',
        emits: 'submit',
        props: {
          type: 'danger',
          text: 'Отменить заказ',
          class: 'modal-cancel',
        },
      },
    ],
    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 24,
            xs: 24,
          },
          fields: [
            {
              type: 'select',
              name: 'reason',
              changeAction: ({ form }) => {
                form.reason === WalkingCancelReasons.other ? form.trigger = true : form.trigger = false;
              },
              options: [],
              props: {
                placeholder: 'Выберите причину отмены',
                remote: true,
                remoteMethod: searchReasons,
                labelKey: 'label',
                valueKey: 'value',
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'change',
              }],
            },
            {
              type: 'textarea',
              name: 'comment',
              props: {
                placeholder: 'Опишите причину',
                hidden: ({ state }) => !state.trigger,
              },
              validation: [{
                type: 'empty-required' as 'empty',
                trigger: 'blur',
              }],
            },
            {
              type: 'custom',
              name: 'trigger',
              component: () => null,
              props: {
                hidden: true,
              },
            },
          ],
        },
      ],
    },
  },
});

export default cancelWalkingConfig;
