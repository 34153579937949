<template>
  <div class="discount-object">
    <el-switch
      v-model="isAll"
      :disabled="!props.form.city || readOnly"
      active-text="На весь чек"
      @change="onAll"
    />

    <div class="discount-object__item" :class="{ 'is-success': offer }">
      <p>Услуга</p>
      <el-select
        v-if="!readOnly"
        v-model="offer"
        :disabled="!props.form.city || isAll || readOnly"
        :placeholder="!props.form.city ? 'Сначала выберите город' : 'Выберите одну услугу'"
        @change="onOffer"
      >
        <el-option
          v-for="item in offers"
          :key="item.id"
          :label="item.title"
          :value="item.id"
          :disabled="item.status.value === 'inactive'"
        />
      </el-select>

      <MagnerFormInput
        v-else
        :model-value="offers.find((item) => item.id === offer)?.title || '–'"
        :field="readOnlyInput"
      />
    </div>

    <el-radio-group v-model="type">
      <el-radio 
        v-for="item, index in typeOptions" 
        :key="index" 
        :label="item.value"
        :disabled="!offer || readOnly || isAllOffer || isAll"
        @change="
          option = [];
          tariff = [];
          update()
        "
      >
        {{ item.label}}
      </el-radio>
    </el-radio-group>

    <div v-if="type === 'tariff'" class="discount-object__item">
      <el-select
        v-if="!readOnly"
        v-model="tariff"
        collapse-tags
        :disabled="!offer || isNoTariff || option.length || isNoOption || readOnly || isAllOffer || isAll"
        :placeholder="
          isAllOffer 
            ? 'Выбрано «На всю услугу»' 
            : !offer 
              ? 'Сначала выберите услугу' 
              : 'Выберите один или несколько тарифов'"
        multiple
        @change="
          option = [];
          isNoOption = false;
          update()
        "
      >
        <div class="select-checkbox-all">
          <el-checkbox
            v-model="checkTariff.all"
            :indeterminate="checkTariff.indeterminate"
            @change="tariffCheckAll"
          > 
            Все
          </el-checkbox>
        </div>

        <el-option
          v-for="item in offersTariff"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        />
      </el-select>

      <MagnerFormInput
        v-else
        :model-value="offersAllTariff?.filter((item) => tariff.includes(item.id)).map(item => item.title).join(', ') || '–'"
        :field="readOnlyInput"
      />

      <!-- <div class="discount-object__switch">
        <el-switch
          v-model="isNoTariff"
          :disabled="!offer || option.length || isNoOption"
          active-text="Без скидок на тариф"
          @change="
            tariff = []; 
            option = [];
            isNoOption = false;
            update()
          "
        />
      </div> -->
    </div>

    <div v-else class="discount-object__item">
      <el-select
        v-if="!readOnly"
        v-model="option"
        collapse-tags
        :disabled="!offer || isNoOption || tariff.length || isNoTariff || readOnly || isAllOffer || isAll"
        :placeholder="
          isAllOffer 
            ? 'Выбрано «На всю услугу»' 
            : !offer 
              ? 'Сначала выберите услугу' 
              : 'Выберите один или несколько опций'"
        multiple
        @change="
          tariff = [];
          isNoTariff = false;
          update()
        "
      >
        <div class="select-checkbox-all">
          <el-checkbox
            v-model="checkOption.all"
            :indeterminate="checkOption.indeterminate"
            @change="optionCheckAll"
          > 
            Все
          </el-checkbox>
        </div>

        <el-option
          v-for="item in offersOptions"
          :key="item.id"
          :label="item.title"
          :value="item.id"
        />
      </el-select>

      <MagnerFormInput
        v-else
        :model-value="offersAllOptions?.filter((item) => option.includes(item.id)).map(item => item.title).join(', ') || '–'"
        :field="readOnlyInput"
      />

      <!-- <div class="discount-object__switch">
        <el-switch
          v-model="isNoOption"
          :disabled="!offer || tariff.length || isNoTariff"
          active-text="Без скидок на опции"
          @change="
            option = [];
            tariff = [];
            isNoTariff = false;
            update()
          "
        />
      </div> -->
    </div>

    <el-switch
      v-model="isAllOffer"
      :disabled="!props.form.city || readOnly || isAll || !offer"
      active-text="На всю услугу"
      @change="onAllOffer"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  computed,
  ref,
  reactive,
  watch,
} from 'vue';

import { MagnerFormInput, MagnerFormRadio } from 'magner';
import type { CheckboxValueType } from 'element-plus';
import { searchOffers } from '~/utils/searchInit';
import type { OfferRead } from '~/features/catalog/services/types';

import type { Edit, ObjectData } from '../types';

const props = defineProps({
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  form: {
    type: Object as PropType<Edit>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<ObjectData>,
    default: null,
  },
});

const checkTariff = reactive({
  all: false,
  indeterminate: false,
});

const checkOption = reactive({
  all: false,
  indeterminate: false,
});

const emit = defineEmits(['update:modelValue']);

// Data
const type = ref(props.modelValue?.option?.length ? 'option' : 'tariff');

const isAll = ref(props.modelValue?.isAll || false);
const isAllOffer = ref(props.modelValue?.isAllOffer || false);

const offer = ref(props.modelValue?.offer || null);

const tariff = ref(props.modelValue?.tariff || []);
const isNoTariff = ref(false);

const option = ref(props.modelValue?.option || []);
const isNoOption = ref(false);

const sendData = computed(() => ({
  isAll: isAll.value,
  offer: offer.value,
  tariff: tariff.value,
  option: option.value,
  isNoOption: isNoOption.value,
  isNoTariff: isNoTariff.value,
  isAllOffer: isAllOffer.value,
  type: type.value,
}));

const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }

  return props.field.readOnly;
});

// Offers
const offersAll = ref<{ data: OfferRead[] }>(await searchOffers());
const offers = computed(() => offersAll.value.data.filter((item) => item.city.id === ((props.form.city as any)?.id || props.form.city)));

const offersTariff = computed(() => [
  ...(offers.value.find((item) => item.id === offer.value)?.offerRates.map((item) => ({
    title: `${item.time} минут`,
    id: item.id,
  })) || []),
]);

const offersOptions = computed(() => offers.value.find((item) => item.id === offer.value)?.offerOptions);

const offersAllTariff = computed(() => offersAll.value.data.map((item) => item.offerRates).flat().map((item) => ({
  title: `${item.time} минут`,
  id: item.id,
})));
const offersAllOptions = computed(() => offersAll.value.data.map((item) => item.offerOptions).flat());

// Methods
const update = () => emit('update:modelValue', sendData.value);

const onAll = () => {
  type.value = 'tariff';
  offer.value = null;
  tariff.value = [];
  isNoTariff.value = false;
  option.value = [];
  isNoOption.value = false;
  isAllOffer.value = false;

  update();
};

const onOffer = () => {
  type.value = 'tariff';
  tariff.value = [];
  isNoTariff.value = false;
  option.value = [];
  isNoOption.value = false;

  update();
};

const onAllOffer = () => {
  type.value = 'tariff';
  tariff.value = [];
  isNoTariff.value = false;
  option.value = [];
  isNoOption.value = false;

  update();
};

const init = () => {
  if (!props.form.id) {
    return;
  }

  if (tariff.value.length || option.value.length) {
    const offerId = offersAll.value.data.find((item) => {
      const isOptions = item.offerOptions.some((itemOption) => option.value.includes(itemOption.id));
      const isTariff = item.offerRates.some((itemTariff) => tariff.value.includes(itemTariff.id));

      return isOptions || isTariff;
    });

    if (offerId) {
      offer.value = offerId.id;
    }
  }

  if (tariff.value.length) {
    // eslint-disable-next-line no-unused-expressions
    tariff.value.length === offersTariff.value.length ? checkTariff.all = true : checkTariff.indeterminate = true;
  }

  if (option.value.length) {
    // eslint-disable-next-line no-unused-expressions
    option.value.length === offersOptions.value?.length ? checkOption.all = true : checkOption.indeterminate = true;
  }
};

/* Check all tariff */
watch(tariff, (val) => {
  if (val.length === 0) {
    checkTariff.all = false;
    checkTariff.indeterminate = false;
  }
  else if (val.length === offersTariff.value.length) {
    checkTariff.all = true;
    checkTariff.indeterminate = false;
  }
  else {
    checkTariff.indeterminate = true;
  }

  update();
});

const tariffCheckAll = (val: CheckboxValueType) => {
  checkTariff.indeterminate = false;
  if (val) {
    tariff.value = offersTariff.value.map((_) => _.id);
  }
  else {
    tariff.value = [];
  }

  update();
};

/* Check all option */
watch(option, (val) => {
  if (val.length === 0) {
    checkOption.all = false;
    checkOption.indeterminate = false;
  }
  else if (val.length === offersOptions.value?.length) {
    checkOption.all = true;
    checkOption.indeterminate = false;
  }
  else {
    checkOption.indeterminate = true;
  }

  update();
});

const optionCheckAll = (val: CheckboxValueType) => {
  checkOption.indeterminate = false;
  if (val) {
    option.value = offersOptions.value?.map((_) => _.id) || [];
  }
  else {
    option.value = [];
  }

  update();
};

/* Other */
watch(() => props.form.city, () => {
  type.value = 'tariff';
  isAll.value = false;
  offer.value = null;
  tariff.value = [];
  isNoTariff.value = false;
  option.value = [];
  isNoOption.value = false;

  update();
});

init();
update();

const readOnlyInput = { props: { readOnly: true } } as any;

const typeOptions = [
  { label: 'Тариф', value: 'tariff' }, 
  { label: 'Опция', value: 'option' },
];
</script>

<style>
.discount-object {
  width: 100%;
}

.discount-object__switch {
  margin-top: 8px;
}

.discount-object__item {
  width: 100%;
}

.discount-object__item:not(:last-child) {
  margin-bottom: 24px;
}

.input-objectData .is-success .el-input__wrapper {
  box-shadow: 0 0 0 1px var(--el-input-border-color, var(--el-border-color)) inset !important;
}

.custom-header .el-checkbox {
    display: flex;
    height: unset;
}
</style>
