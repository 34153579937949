import { request } from '~/utils/request';
import { getListResponse, sortTable, filtersTable } from '~/utils/get-list';
import { deepLinks } from '~/utils/searchInit';
import { toDateISOString, toDateLocaleTimeZone } from '~/utils/date-transform';
import type {
  List, Read, Edit, Filters,
} from './types';

const BASE = '/banner';

const sendData = (data: Edit) => {
  const result = { ...data };

  if (Array.isArray(result.imageId) && result.imageId.length) {
    result.imageId = result.imageId[0];
  }

  if (result?.date?.length) {
    result.startTime = result?.date?.[0] ? toDateISOString(result?.date?.[0]) : '';
    result.endTime = result?.date?.[1] ? toDateISOString(result?.date?.[1]) : '';
  }

  delete result.date;
  delete result.transition;

  return result;
};

export const read = request.table<List>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;
  /* Filters */
  const filtersData = { ...data.filters };

  if (filtersData.date) {
    filtersData.startTimeFrom = toDateISOString(filtersData.date[0]);
    filtersData.endTimeFrom = toDateISOString(filtersData.date[1]);

    delete filtersData.date;
  }

  /* Sort */
  const sortData = data.sort;

  if (sortData.activeTimeRange) {
    sortData.startTime = sortData.activeTimeRange;
    sortData.endTime = sortData.activeTimeRange;

    delete sortData.activeTimeRange;
  }

  const sort = sortTable(
    sortData,
    [
      'title',
      'sort',
      'city',
      'startTime',
      'endTime',
      'link',
    ],
  );

  const filters = filtersTable(
    filtersData as Filters,
    ['title', 'cities', 'startTimeFrom', 'endTimeFrom', 'links'],
  );

  const result = await getListResponse<List>(`${BASE}?${pagination}${filters}${sort}`, activePage);

  const { data: deppLinksResult } = await deepLinks({});

  return {
    data: {
      ...result.data,
      rows: result.data.rows.map((row) => ({
        ...row,
        link: deppLinksResult?.find((item) => item.value === row.link)?.label || row.link,
      })),
    },
  };
});

export const get = request.card<Edit, Read>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Read }>(`${BASE}/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const { data: deppLinksResult } = await deepLinks({});

  const startTime = toDateLocaleTimeZone(res.data.data.activeTimeRange.start);
  const endTime = toDateLocaleTimeZone(res.data.data.activeTimeRange.end);

  return {
    data: {
      ...res.data.data,
      cityId: res.data.data.city.id,
      imageId: res.data.data.image.downloadUrl,
      startTime,
      endTime,
      date: [startTime, endTime],
      transition: deppLinksResult?.some((item) => item.value === res.data.data.link) ? 'app' : 'link',
      // eslint-disable-next-line max-len
      link: deppLinksResult?.find((item) => item.value === res.data.data.link) || res.data.data.link as string,
    },
  };
});

export const create = request.card<Read, Edit>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.post<{ data: Read }>(`${BASE}`, sendData(data.data));

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'banners' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<Read, Edit>(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.patch<{ data: Read }>(
      `${BASE}/${data.id}`,
      sendData(data.data),
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'banners' });

    return {
      data: res.data.data,
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router,
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'banners' });

    return { data: res.data?.data };
  },
);

export const linksList = request.custom<string[], string[]>(async ({ api }) => {
  const res = await api.get(`${BASE}/links-list`);
  const deepLinksList = await deepLinks({});

  return {
    data: res.data.data.items.filter((item: string) => item).map((item: string) => ({
      label: deepLinksList?.data?.find((deep) => deep.value === item)?.label || item,
      value: item,
    })),
  };
});
