const getDatetimeDefaultTimezone = (): Date => {
  const dateString = new Date().toLocaleString('ru-RU', { timeZone: 'Europe/Moscow' });
  const date = dateString.split(', ')[0]; // '19.02.2023'
  const [day, months, year] = date.split('.');

  const time = dateString.split(', ')[1]; // '12:46:16'
  const [hour, minute] = time.split(':');

  return new Date(+year, (+months - 1), +day, +hour, +minute);
};

export default getDatetimeDefaultTimezone;
