import { filtersFormController } from 'magner';
import { baseT } from 'configs/translation/base';
import { t } from 'features/users/clients/ru';
import { walkersT } from 'features/users/walkers/index';
import { type WalkersList, WalkerStatuses } from './types';
import { searchCities } from '~/utils/searchInit';

export const statuses = [
  {
    label: 'Активный',
    value: WalkerStatuses.active,
  },
  {
    label: 'Неактивный',
    value: WalkerStatuses.inactive,
  },
  {
    label: 'Модерация',
    value: WalkerStatuses.moderation,
  },
  {
    label: 'Регистрация',
    value: WalkerStatuses.registration,
  },
  {
    label: 'Отклонен',
    value: WalkerStatuses.declined,
  },
  {
    label: 'Заблокирован',
    value: WalkerStatuses.blocked,
  },
  {
    label: 'Запрос на удаление',
    value: WalkerStatuses.removing_requested,
  },
  {
    label: 'Удален',
    value: WalkerStatuses.removed,
  },
];
const walkersFilters = filtersFormController<WalkersList>({
  saveToLocalStorage: true,
  fieldsShowAmount: 5,
  actions: [
    {
      type: 'link',
      to: { name: 'walker-edit', params: { id: 'new' } },
      props: {
        type: 'primary',
        class: 'blue-btn',
        text: baseT('form.create_new_button'),
        // TODO Неработает иконка на кнопке
        // icon: () => import('assets/icons/add.svg'),
      },
    },
  ],
  submitEvent: 'input',
  sort: {},
  pagination: {
    items: 25,
    page: 1,
  },
  filtersData: {
    name: '',
    cities: '',
    status: '',
    phone: '',
    email: '',
    telegram: '',
    rating: '',
  },
  layout: [
    {
      type: 'input',
      name: 'name',
      props: {
        placeholder: walkersT('filters.name'),
        inputDelay: 250,
      },
    },
    {
      type: 'select',
      name: 'cities' as 'city',
      options: [],
      props: {
        placeholder: t('filters.city'),
        multiple: true,
        collapseTags: true,
        clearable: true,
        remote: true,
        valueKey: 'id',
        labelKey: 'name',
        remoteMethod: searchCities,
      },
    },
    {
      type: 'select',
      name: 'status',
      props: {
        placeholder: walkersT('filters.status'),
      },
      options: statuses,
    },
    {
      type: 'select',
      name: 'rating',
      props: {
        placeholder: 'Рейтинг',
      },
      options: [
        {
          label: '3.5+',
          value: 3.5,
        },
        {
          label: '4+',
          value: 4,
        },
        {
          label: '4.5',
          value: 4.5,
        },
      ],
    },
    {
      type: 'input',
      name: 'telegram',
      props: {
        placeholder: walkersT('filters.telegram'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'email',
      props: {
        placeholder: walkersT('filters.email'),
        inputDelay: 250,
      },
    },
    {
      type: 'input',
      name: 'phone',
      props: {
        placeholder: walkersT('filters.phone'),
        inputDelay: 250,
      },
    },
  ],
});

export default walkersFilters;
