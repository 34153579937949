import { ref, computed } from 'vue';
import type { Ref } from 'vue';

type BlockSort = { [key: string]: any };

export const sortTableRequest = <T extends BlockSort>(items: T[]) => {
  const rows: Ref<T[]> = ref([]);

  const list = computed(() => rows.value.map((item: T, i: number) => ({ ...item, sort: i + 1 })));

  const findIndex = (id: string): number => list.value.findIndex((item: T) => item.id === id);

  const setupSort = async (list: T[]) => {
    rows.value = list;
  };

  const updateList = async (list: T[], callback: Function) => {
    const result = list
      .sort((a: T, b: T) => (a.sort > b.sort ? 1 : -1))
      .map((item, i) => ({ ...item, sort: i + 1 }));

    await callback({ id: 0, isNew: false, data: result });

    rows.value = result as any;
  };

  const rowUp = async (
    row: T,
    callback: Function,
    callbackUpdated?: Function,
  ) => {
    let result = [];
    if (callbackUpdated) {
      const resultList = await callbackUpdated(rows.value);

      // eslint-disable-next-line max-len, vue/max-len
      result = await resultList
        .sort((a: T, b: T) => (a.sort > b.sort ? 1 : -1))
        .map((item: any, i: number) => ({ ...item, sort: i + 1 }));
    }

    const data = callbackUpdated ? result : list.value.slice(0);

    const index = findIndex(row.id);

    if (index === 0) {
      return;
    }

    const prevValue = list.value[index - 1];
    const prevIndexValue = findIndex(prevValue.id);

    data[index].sort = prevValue.sort;
    data[prevIndexValue].sort = list.value[index].sort;

    await updateList(data, callback);
  };

  const rowDown = async (
    row: T,
    callback: Function,
    callbackUpdated?: Function,
  ) => {
    let result = [];
    if (callbackUpdated) {
      const resultList = await callbackUpdated(rows.value);

      // eslint-disable-next-line max-len, vue/max-len
      result = await resultList
        .sort((a: T, b: T) => (a.sort > b.sort ? 1 : -1))
        .map((item: any, i: number) => ({ ...item, sort: i + 1 }));
    }

    const data = callbackUpdated ? result : list.value.slice(0);

    const index = findIndex(row.id);

    if (index === list.value.length - 1) {
      return;
    }

    const nextValue = list.value[index + 1];
    const nextIndexValue = findIndex(nextValue.id);

    data[index].sort = nextValue.sort;
    data[nextIndexValue].sort = list.value[index].sort;

    await updateList(data, callback);
  };

  rows.value = items;

  return {
    rows,
    list,
    findIndex,
    updateList,
    rowUp,
    rowDown,
    setupSort,
  };
};
