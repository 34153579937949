<template>
  <div style="width: 100%; gap: 12px; display: flex; flex-direction: column">
    <div
      v-for="item,key in items"
      :key="key"
      class="el-form-item asterisk-left generic-form_item generic-form_item-input input-offers readonly"
      style="width: 100%"
    >
      <div class="el-form-item__content">
        <div class="gray-block readonly-block">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, PropType, computed } from 'vue';
import type { WorkingSchedule } from '../types';
import { DAYS } from '~/utils/date-transform';

const props = defineProps({
  modelValue: {
    type: Object as PropType<WorkingSchedule[]>,
    required: true,
  },
});

const items = computed(() => {
  if (!props.modelValue?.length) {
    return ['–'];
  }
  
  return props.modelValue.map((item) => {
    const result = item.timeSlots
      .reduce((res, time) => {
        res.days.push(DAYS[time.weekDay]);
        res.range.push(`${time.startTime}:00–${time.endTime}:00`);

        return res;
      }, {
        days: [] as string[],
        range: [] as string[],
      });

    return `${result.days.join(', ')}, ${result.range.join(', ')}`;
  });
});
</script>

<style lang="postcss" scoped>
.walker-rating-card {
  font-size: 23px; 
  font-weight: 700; 
  margin-top: 4px
}
</style>

<!-- val.map((item) => {
                      const result = item.timeSlots
                        .reduce((res, time) => {
                          res.days.push(DAYS[time.weekDay]);
                          res.range.push(`${time.startTime}:00–${time.endTime}:00`);

                          return res;
                        },
                        {
                          days: [] as string[],
                          range: [] as string[],
                        });
                      
                      return `${result.days.join(', ')}, ${result.range.join(', ')}`;
                    }) -->
