/* eslint-disable max-len */
import { cardPageController, action } from 'magner';
import { baseT } from 'configs/translation/base';
import { walkersT } from 'features/users/walkers/index';
import WalkerStatusCard from 'features/users/walkers/components/walker-status-card.vue';
import WalkerRatingCard from 'features/users/walkers/components/walker-rating-card.vue';
import WalkerWorkingSchedule from '../components/walker-working-schedule.vue';
import {
  get, create, update, updateStatus, 
} from '../requests';
import { Walkers, WalkerStatuses } from '../types';
import { ValueLabel } from '~/types/common';
import { t } from '../ru';
import { saveImage } from '~/features/common/file-request';
import { searchCities } from '~/utils/searchInit';

export const cardConfig = cardPageController<Walkers>({
  header: {
    title: 'Выгульщик',
    newTitle: 'Новый выгульщик',
    tag: {
      name: 'tag',
      props: {
        type: 'warning',
        size: 'large',
      },
    },
  },

  getRequest: get,
  updateRequest: update,
  createRequest: create,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: () => ({ name: 'walkers' }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
          hidden: ({ isNew }) => !isNew,
        },
      },
      {
        type: 'link',
        to: (route) => ({ name: 'walker', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
          hidden: ({ isNew }) => isNew,
        },
      },
      // {
      //   type: 'action',
      //   emits: 'remove-request' as 'submit',
      //   props: {
      //     text: baseT('form.remove_button'),
      //     type: 'danger',
      //     class: 'width-auto',
      //     hidden: ({ isNew }) => isNew,
      //   },
      //   action: action(async ({ data }) => {
      //     try {
      //       // @ts-ignore
      //       alert('Удаление');
      //     } catch (e) {
      //       console.log(e);
      //     }
      //   }),
      // },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.create_button'),
          type: 'primary',
          class: 'width-auto',
          hidden: ({ isNew, state }) => !isNew
            || state.status.value === WalkerStatuses.moderation
            || state.status.value === WalkerStatuses.registration
            || state.status.value === WalkerStatuses.declined,
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
          hidden: ({ isNew, state }) => isNew
            || state.status.value === WalkerStatuses.moderation
            || state.status.value === WalkerStatuses.registration
            || state.status.value === WalkerStatuses.declined,
        },
      },
      {
        type: 'action',
        emits: 'registration-reject' as 'submit',
        props: {
          text: baseT('form.reject'),
          type: 'danger',
          class: 'width-auto',
          plain: true,
          hidden: ({ state }) => state.status.value !== WalkerStatuses.registration,
        },
        action: action(async ({ data }) => {
          try {
            await updateStatus({
              id: data.id,
              data: { status: WalkerStatuses.declined },
              isNew: false,
            });
          }
          catch (e) {
            console.log(e);
          }
        }),
      },
      {
        type: 'action',
        emits: 'approve' as 'submit',
        props: {
          text: baseT('form.approve'),
          type: 'success',
          class: 'width-auto',
          hidden: ({ state }) => state.status.value !== WalkerStatuses.registration
            && state.status.value !== WalkerStatuses.moderation,
        },
        action: action(async ({ data }) => {
          try {
            await update({
              id: data.id,
              data: { 
                ...data.form,
                status: WalkerStatuses.active,
              },
              isNew: false,
            });
          }
          catch (e) {
            console.log(e);
          }
        }),
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            /** Name, id, rating */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'name',
                      label: walkersT('form.fields.name.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT('form.fields.name.placeholder'),
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'blur',
                        },
                      ],
                    },
                    {
                      type: 'input',
                      name: 'id',
                      label: walkersT('form.fields.id'),
                      dataType: 'string',
                      props: {
                        placeholder: walkersT('form.fields.id'),
                        disabled: true,
                        hidden: true,
                      },
                    },
                    {
                      type: 'input',
                      name: 'profileDataOnModeration',
                      dataType: 'string',
                      props: {
                        hidden: true,
                      },
                    },
                  ],
                },
                {
                  type: 'column',
                  props: { styles: { maxWidth: '150px' } },
                  fields: [
                    {
                      type: 'custom',
                      name: 'rating',
                      label: walkersT('form.fields.rating'),
                      component: () => WalkerRatingCard,
                      props: {},
                    },
                  ],
                },
              ],
            },
            /** Surname, Birthday */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'surname',
                      label: walkersT('form.fields.surname.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT(
                          'form.fields.surname.placeholder',
                        ),
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'blur',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'column',
                  props: { styles: { maxWidth: '150px' } },
                  fields: [
                    {
                      type: 'datetime',
                      name: 'birthdate',
                      label: t('form.fields.birthdate.title'),
                      dataType: 'date',
                      props: {
                        type: 'date',
                        format: 'DD.MM.YYYY',
                        valueFormat: 'YYYY-MM-DD',
                        placeholder: t('form.fields.birthdate.placeholder'),
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'birthdate')),
                      },
                      validation: [
                        {
                          type: 'birthday-adult' as 'empty',
                          trigger: 'blur',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            /** City, Status */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'select',
                      name: 'city',
                      options: [],
                      label: t('form.fields.city.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: t('form.fields.city.placeholder'),
                        valueKey: 'id',
                        labelKey: 'name',
                        remote: true,
                        remoteMethod: searchCities,
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'custom',
                      name: 'status',
                      label: walkersT('form.fields.status'),
                      component: () => WalkerStatusCard,
                      props: {
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'status')),
                      },
                    },
                  ],
                },
              ],
            },
            /** Email, Phone */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: { styles: { maxWidth: '190px' } },
                  fields: [
                    {
                      type: 'input',
                      name: 'phone',
                      label: walkersT('form.fields.phone.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        type: 'tel',
                        placeholder: walkersT('form.fields.phone.placeholder'),
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'blur',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'email',
                      label: walkersT('form.fields.email.title'),
                      dataType: 'string',
                      props: {
                        placeholder: walkersT('form.fields.email.placeholder'),
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'email')),
                      },
                    },
                  ],
                },
              ],
            },
            /** Telegram, Vkontakte */
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'telegram',
                      label: walkersT('form.fields.telegram.title'),
                      dataType: 'string',
                      props: {
                        required: true,
                        placeholder: walkersT('form.fields.telegram.placeholder'),
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'blur',
                        },
                      ],
                    },
                  ],
                },
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'input',
                      name: 'vk',
                      label: walkersT('form.fields.vk.title'),
                      dataType: 'string',
                      props: {
                        placeholder: walkersT('form.fields.vk.placeholder'),
                        changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'vk')),
                      },
                    },
                  ],
                },
              ],
            },
            /** Offers */
            {
              type: 'column',
              title: t('form.fields.offers'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
                hidden: ({ isNew }) => isNew,
              },
              fields: [
                {
                  type: 'input',
                  name: 'offerCategories',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: (val) => val.map((item: ValueLabel) => item.label).join(', ') || '–',
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'offerCategories')),
                  },
                },
              ],
            },
            /** Working schedule */
            {
              type: 'column',
              title: t('form.fields.working_schedule'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'custom',
                  name: 'workingSchedule',
                  component: () => WalkerWorkingSchedule,
                  props: {},
                },
              ],
            },
          ],
        },

        /** Right column */

        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
          },
          layout: [
            /** Photo */
            {
              type: 'column',
              title: t('form.fields.photo'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'dropzone',
                  name: 'photo' as 'photoId',
                  props: {
                    removable: true,
                    valueKey: 'id',
                    srcKey: 'downloadUrl',
                    saveToBackend: saveImage,
                    inputAtts: {
                      accept: 'image/png, image/jpeg, image/webp',
                    },
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'photo')),
                  },
                },
              ],
            },
            /** About */
            {
              type: 'column',
              title: t('form.fields.about'),
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              fields: [
                {
                  type: 'textarea',
                  name: 'motivation',
                  label: t('form.fields.motivation.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.motivation.placeholder'),
                    rows: 3,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'motivation')),
                  },
                },
                {
                  type: 'textarea',
                  name: 'experience',
                  label: t('form.fields.experience.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.experience.placeholder'),
                    rows: 3,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'experience')),
                  },
                },
                {
                  type: 'textarea',
                  name: 'pets',
                  label: t('form.fields.pets_walker.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.pets_walker.placeholder'),
                    rows: 3,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'pets')),
                  },
                },

                {
                  type: 'textarea',
                  name: 'interests',
                  label: t('form.fields.interests.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.interests.placeholder'),
                    rows: 3,
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'interests')),
                  },
                },

                {
                  type: 'input',
                  name: 'video',
                  label: t('form.fields.video_link.title'),
                  dataType: 'string',
                  props: {
                    placeholder: t('form.fields.video_link.placeholder'),
                    changed: (({ state }) => state.status.value === WalkerStatuses.moderation && Object.hasOwn(state.profileDataOnModeration, 'video')),
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
