import { request } from '~/utils/request';

const logoutRequest = request.custom(async ({ router, lstorage, api }) => {
  await api.delete('/auth/logout');
  api.instance.defaults({ headers: {} }, false);
  lstorage.delete('token');
  lstorage.delete('id');
  lstorage.delete('role');
  lstorage.delete('refreshToken');

  return {
    data: null,
  };
});

export default logoutRequest;
