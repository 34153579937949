<!-- eslint-disable max-len -->
<!-- eslint-disable vue/max-len -->
<template>
  <el-select
    v-model="selected"
    :disabled="isDisabled"
    @change="changeHandler"
  >
    <el-option
      v-for="item in selectItems"
      :key="item.value"
      :label="item.label"
      :value="item.value"
      :disabled="isOptionDisabled(item.value)"
    />
  </el-select>
</template>

<script lang="ts" setup>
import {
  defineEmits, defineProps, PropType, ref, computed,
} from 'vue';
import { WalkerStatuses } from 'features/users/walkers/types';
import { magnerConfirm } from 'magner';
import { statuses } from 'features/users/walkers/filters';
import { roleChecker } from '~/utils/role-checker';
import { ROLE } from '~/constants';

const props = defineProps({
  modelValue: {
    type: Object as PropType<{ label: string, value: WalkerStatuses }>,
    required: true,
  },
}); 

const isCreate = ref(props.modelValue);

const isRemovingRequested = ref(props.modelValue?.value === WalkerStatuses.removing_requested);

const emit = defineEmits(['update:modelValue']);

const selected = ref(props.modelValue?.value ?? WalkerStatuses.active);

const isDisabled = computed(() => {
  if (isRemovingRequested.value) {
    return false;
  }

  if (!isCreate.value) {
    return true;
  }

  return selected.value !== WalkerStatuses.active 
    && selected.value !== WalkerStatuses.blocked 
    && selected.value !== WalkerStatuses.removing_requested;
});

const selectItems = computed(() => {
  if (isRemovingRequested.value) {
    return [
      statuses.find((item) => item.value === WalkerStatuses.removing_requested),
      statuses.find((item) => item.value === WalkerStatuses.active),
      statuses.find((item) => item.value === WalkerStatuses.removed),
    ];
  }

  if (selected.value === WalkerStatuses.active || selected.value === WalkerStatuses.blocked) {
    return statuses.filter((item) => item.value === WalkerStatuses.active || item.value === WalkerStatuses.blocked);
  }

  return statuses;
});

const isOptionDisabled = (value: WalkerStatuses) => {
  if (!roleChecker(ROLE.SUPERADMIN) && !roleChecker(ROLE.ADMIN) && !roleChecker(ROLE.MANAGER)) {
    return true;
  }

  if (value === WalkerStatuses.removing_requested) {
    return true;
  }

  return false;
};

const changeHandler = (value: WalkerStatuses) => {
  if (value === WalkerStatuses.blocked) {
    magnerConfirm({
      message: 'Вы уверены что хотите заблокировать выгульщика?',
      title: 'Внимание',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
      type: 'warning',
    }).then(() => {
      selected.value = value;
      emit('update:modelValue', value);
    }).catch(() => {
      selected.value = props.modelValue?.value ?? WalkerStatuses.active;
    });
  }
  else {
    emit('update:modelValue', value);
  }
};

// В момент создание нового выгульщика
if (!props.modelValue?.value) {
  changeHandler(WalkerStatuses.active);
}
</script>
