/* eslint-disable max-len */
/* eslint-disable vue/max-len */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('UTC');

export const MONTH = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
  'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

export const DAYS = ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'];

export const roundToTwoSigns = (num: number|string): string => {
  if (!num) {
    return '00';
  }

  if (num.toString().length === 1) {
    return `0${num}`;
  }

  return num.toString();
};

export const toDateISOString = (date: string) => {
  if (!date) {
    return '';
  }

  return new Date(date).toISOString();
};

export const toDateLocaleTimeZone = (date: string | Date) => {
  if (!date) {
    return '';
  }

  const dateDayJs = new Date(dayjs.tz(date, 'UTC').tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss'));

  return `${dateDayJs.getFullYear()}-${roundToTwoSigns(dateDayJs.getMonth() + 1)}-${roundToTwoSigns(dateDayJs.getDate())}T${dateDayJs.toLocaleTimeString('ru-RU')}`;
};

export const formatTime = (date: string|Date): string => {
  const d = new Date(toDateLocaleTimeZone(date));

  return `${roundToTwoSigns(d.getHours())}:${roundToTwoSigns(d.getMinutes())}`;
};

export const formatDate = (date: string|Date): string => {
  const d = new Date(toDateLocaleTimeZone(date));

  return `${roundToTwoSigns(d.getDate())}.${roundToTwoSigns(d.getMonth() + 1)}.${d.getFullYear()} `;
};

export const toDateLocale = (date: string) => {
  if (!date) {
    return '';
  }

  const config = { year: 'numeric', month: 'numeric', day: 'numeric' };

  const result = new Date(toDateLocaleTimeZone(date)).toLocaleDateString('ru-RU', config as any);

  return result;
};

export const toDateTimeLocale = (date: string) => `${toDateLocale(date)} ${formatTime(date)}`;
