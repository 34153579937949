import { cardPageController } from 'magner';
import { baseT } from 'configs/translation/base';
import { chatsT } from './index';
import ChatCard from './components/index.vue';
import {
  get, create, update, remove, 
} from './requests';

export const cardConfig = cardPageController<any>({
  header: {
    // title: 'Чат',
  },

  getRequest: () => ({ data: [] }) as any,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  form: {
    actions: [],

    layout: {
      type: 'row',
      props: {},
      layout: [
        {
          type: 'column',
          props: {
            span: 24,
            xs: 24,
          },
          fields: [
            {
              type: 'custom',
              name: 'chat',
              props: {},
              component: () => ChatCard,
            },
          ],
        },
      ],
    },
  },
});
