import { magnerMessage } from 'magner';
import { request } from '~/utils/request';
import type {
  Client,
  ClientCreate,
  ClientList,
  ClientUpdate,
} from './types';
import { getListResponse } from '~/utils/get-list';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import {
  AddressInterface,
  ContactInterface,
  PetInterface,
} from '~/types';
import { filterURLBuilder } from '~/utils/filter-builder';
import { City, District } from '~/types/common';

const BASE_URL = '/user';

export const read = request.table<ClientList>(async ({ data, api }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sortName = data.sort.name ? `&sort[0][id]=name&sort[0][value]=${data.sort.name}` : '';
  const sortSurname = data.sort.surname ? `&sort[1][id]=surname&sort[1][value]=${data.sort.surname}` : '';
  const sortPhone = data.sort.phone ? `&sort[2][id]=phone&sort[2][value]=${data.sort.phone}` : '';
  const sortEmail = data.sort.email ? `&sort[2][id]=email&sort[2][value]=${data.sort.email}` : '';

  const name = data.filters.name ? `&filters[0][id]=name&filters[0][value]=${data.filters.name}` : '';
  const phone = data.filters.phone ? `&filters[1][id]=phone&filters[1][value]=${encodeURIComponent(data.filters.phone)}` : '';
  const city = data.filters.city.length ? filterURLBuilder(data, 2, 'city') : '';
  const email = data.filters.email ? `&filters[4][id]=email&filters[4][value]=${data.filters.email}` : '';
  const statuses = data.filters.statuses ? filterURLBuilder(data, 5, 'statuses') : '';
  const petAgeFrom = data.filters.petAgeFrom ? `&filters[6][id]=petAgeFrom&filters[6][value]=${Number(data.filters.petAgeFrom) * 12}` : '';
  const petAgeTo = data.filters.petAgeTo ? `&filters[8][id]=petAgeTo&filters[8][value]=${Number(data.filters.petAgeTo) * 12}` : '';
  const petBreeds = data.filters.petBreeds.length ? filterURLBuilder(data, 7, 'petBreeds') : '';
  const districts = data.filters.districts.length ? filterURLBuilder(data, 9, 'districts') : '';

  const res = await getListResponse<ClientList>(`${BASE_URL}?${pagination}${sortName}${sortSurname}${sortPhone}${sortEmail}${name}${email}${city}${districts}${statuses}${phone}${petBreeds}${petAgeFrom}${petAgeTo}`, activePage);

  res.data.rows?.map((client: ClientList) => {
    client.petBreeds = Object.values(client.petBreeds).length !== 0 ? Object.values(client.petBreeds).map((breed) => breed?.title).join(', ') as string : '-';
  });

  return res;
});

export const search = request.custom<ClientList[]>(async ({ data }) => {
  const searchText = data.search.trim();
  const pagination = 'limit=10&page=1';
  const nameFilter = searchText ? `&filters[0][id]=nameOrPhone&filters[0][value]=${encodeURIComponent(searchText)}` : '';

  const res = await getListResponse<ClientList>(`${BASE_URL}?${pagination}${nameFilter}`);
  const clients = res.data.rows?.filter((client) => client.status.value === 'active');

  return { data: clients };
});

export const get = request.card<Client, Client>(async ({
  api, data, router, parseError,
}) => {
  const res = await api.get<{ data: Client }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  res.data?.data.pets.forEach((pet) => {
    // For card
    pet.peculiaritiesReadOnly = JSON.parse(JSON.stringify(pet.peculiarities));
    // For edit-card
    pet.peculiarities = pet.peculiarities.map((a) => a.id);

    pet.petType = pet.petType?.value;
  });

  res.data?.data.addresses.forEach((address) => {
    // For read only card
    address.cityReadOnly = address.city as City;
    address.districtReadOnly = address.district as District;

    // For edit-card
    if (typeof address.city === 'object') {
      address.city = address.city?.id;
    }
    if (typeof address.district === 'object') {
      address.district = address.district?.id;
    }
  });

  return {
    data: {
      ...res.data?.data,
    },
  };
});

export const simpleGet = request.card<Client>(async ({
  api, data, parseError,
}) => {
  const res = await api.get<{ data: Client }>(`${BASE_URL}/read/${data.id}`);

  if (res.error) return { error: parseError(res.error) };

  return { data: res.data?.data };
});

export const create = request.card<Client, ClientCreate>(async ({
  api, data, parseError, router,
}) => {
  delete data?.data.id;
  filterRequestStrings(data.data);

  data.data?.contacts?.forEach((contact: ContactInterface) => filterRequestStrings(contact));
  data.data?.addresses?.forEach((address: AddressInterface) => filterRequestStrings(address));
  data.data?.pets?.forEach((pet: PetInterface) => {
    filterRequestStrings(pet);

    if (typeof pet?.breed === 'object') {
      filterRequestStrings(pet.breed);
      pet.breedId = pet.breed?.id;
    }
    else {
      pet.breedId = pet.breed;
    }
    if (pet.peculiarities === null) {
      pet.peculiarities = [];
    }
  });

  const res = await api.post<{ data: Client }>(`${BASE_URL}/create`, {
    ...data.data,
    city: typeof data.data?.city === 'object' ? data.data.city?.id : data.data.city,
    status: typeof data.data?.status === 'object' ? data.data.status?.id : data.data.status,
  });

  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'clients' });

  return { data: res.data?.data };
});

export const update = request.card<Client, ClientUpdate>(async ({
  api, data, parseError, router,
}) => {
  filterRequestStrings(data.data);

  data.data?.contacts?.forEach((contact) => filterRequestStrings(contact));
  data.data?.addresses?.forEach((address) => filterRequestStrings(address));
  data.data?.pets?.forEach((pet) => {
    filterRequestStrings(pet);

    if (!pet.peculiarities) {
      pet.peculiarities = [];
    }

    if (typeof pet?.breed === 'object') {
      filterRequestStrings(pet.breed);
      pet.breedId = pet.breed?.id;
    }
    else {
      pet.breedId = pet.breed;
    }
  });

  const { contacts, newContacts } = data.data?.contacts?.reduce?.((acc, curr) => {
    if (!curr.id) {
      filterRequestStrings(curr);
      acc.newContacts.push((curr));
    }
    else {
      acc.contacts.push(curr);
    }
    return acc;
  }, { newContacts: [] as ContactInterface[], contacts: [] as ContactInterface[] })
  || { contacts: [], newContacts: [] };

  const { addresses, newAddresses } = data.data?.addresses?.reduce?.((acc, curr) => {
    if (!curr.id) {
      filterRequestStrings(curr);
      acc.newAddresses.push((curr));
    }
    else {
      acc.addresses.push(curr);
    }
    return acc;
  }, { newAddresses: [] as AddressInterface[], addresses: [] as AddressInterface[] })
  || { addresses: [], newAddresses: [] };

  const { pets, newPets } = data.data?.pets?.reduce?.((acc, curr) => {
    if (!curr.id) {
      filterRequestStrings(curr);
      acc.newPets.push((curr));
    }
    else {
      acc.pets.push(curr);
    }
    return acc;
  }, { newPets: [] as PetInterface[], pets: [] as PetInterface[] })
  || { pets: [], newPets: [] };

  const res = await api.patch<{ data: Client }>(`${BASE_URL}/update/${data.id}`, {
    ...data.data,
    contacts,
    newContacts,
    addresses,
    newAddresses,
    pets,
    newPets,
    city: typeof data.data?.city === 'object' ? data.data.city?.id : data.data.city,
    status: typeof data.data?.status === 'object' ? data.data.status?.id : data.data.status,
  });

  if (res.error) {
    magnerMessage({
      type: 'error',
      message: Object.values(parseError(res.error).fields).join(', '),
    });
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'clients' });

  return { data: res.data?.data };
});

export const tableStatusUpdate = request.card<Client, ClientCreate>(async ({
  api, data, parseError, router,
}) => {
  const res = await api.patch<{ data: Client }>(`${BASE_URL}/update/${data.id}`, data);

  if (res.error) {
    magnerMessage({
      type: 'error',
      message: 'Ошибка',
    });
    return { error: parseError(res.error) };
  }

  magnerMessage({
    type: 'success',
    message: 'Статус сохранен',
  });

  return { data: res.data?.data };
});
