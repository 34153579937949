import { tableController, tablePageController } from 'magner';

import { servicesT } from './index';
import servicesFilters from './filters';
import { read } from './requests';
import type { List } from './types';
import DiscountsStatusTable from './components/discounts-status-table.vue';

export const tableConfig = tablePageController<List>({
  header: {
    title: servicesT('table.title'),
  },
  filters: servicesFilters as any,
  request: read,
  table: tableController<List>({
    tableLayout: 'auto',
    scrollbarAlwaysOn: true,
    emptyText: servicesT('table.not_found'),
    columns: [
      {
        prop: 'title',
        label: servicesT('table.headers.title'),
        sortable: true,
      },
      {
        prop: 'sort',
        label: servicesT('table.headers.sort'),
        sortable: true,
      },
      {
        prop: 'city',
        label: servicesT('table.headers.city'),
        sortable: true,
      },
      {
        prop: 'link',
        label: servicesT('table.headers.transition'),
        sortable: true,
      },
      {
        prop: 'isActive',
        label: 'Статус',
        fixed: 'right',
        view: {
          type: 'custom',
          component: () => DiscountsStatusTable,
        },
      },
    ],
    rowLink: (row) => ({
      name: 'services-main-view',
      params: { id: row.id },
    }),
  }),
});
