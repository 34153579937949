<template>
  <div 
    class="discounts-pet-age" 
    :class="{ 'discounts-pet-age--readonly': readOnly }"
  >
    От
    <el-input 
      v-if="!readOnly" 
      v-model="min" 
      type="number"
      :disabled="props.field.disabled"
      placeholder="0" 
      @change="update"
    />
    <MagnerFormInput 
      v-else
      v-model="min" 
      :field="readOnlyInput" 
    />
    до
    <el-input 
      v-if="!readOnly" 
      v-model="max" 
      type="number"
      :disabled="props.field.disabled"
      placeholder="36" 
      @change="update"
    />
    <MagnerFormInput 
      v-else
      v-model="max" 
      :field="readOnlyInput" 
    />
    месяцев
  </div>
</template>
    
<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  computed,
  ref,
} from 'vue';
import {
  MagnerFormInput,
} from 'magner';

import type { Edit } from '../types';
    
const props = defineProps({
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  form: {
    type: Object as PropType<Edit>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<{ min: number, max: number } | ''>,
    default: null,
  },
});
    
const emit = defineEmits(['update:modelValue']);
    
const min = ref(props.modelValue === null || props.modelValue === '' ? '' : (props.modelValue?.min || 0));
const max = ref(props.modelValue?.max || '');
    
const readOnly = computed(() => {
  if (typeof props.field.readOnly === 'function') {
    return props.field.readOnly();
  }
    
  return props.field.readOnly;
});

const update = () => {
  emit('update:modelValue', {
    min: +min.value,
    max: +max.value,
  });
};

const readOnlyInput = { props: { readOnly: true } } as any;
</script>

<style>
.discounts-pet-age {
  display: flex;
  align-items: center;
  width: 100%;
}

.discounts-pet-age--readonly {
  width: auto
}

.discounts-pet-age .el-input {
  max-width: 38px;
  margin: 0 12px;
}

.discounts-pet-age .el-input input {
  text-align: center;
}

.discounts-pet-age .readonly-block {
  min-width: 38px;

  margin: 0 12px;
}
</style>
