import { tableController, tablePageController } from 'magner';

import { chatsT } from './index';
import filters from './filters';
import { type List, read } from './requests';
import { formatDate } from '~/utils/date-transform';

export const tableConfig = tablePageController<List>({
  header: {
    title: chatsT('table.title'),
  },
  filters,
  request: read,
  table: tableController<List>({
    tableLayout: 'fixed',
    scrollbarAlwaysOn: true,
    emptyText: chatsT('table.not_found'),
    columns: [
      {
        prop: 'from',
        label: chatsT('table.headers.from'),
        width: 250,
      },

      {
        prop: 'to',
        label: chatsT('table.headers.to'),
        width: 150,
      },
      {
        prop: 'type',
        label: chatsT('table.headers.type'),
        width: 250,
        view: {
          type: 'text',
          formatter: (row: any) => row?.label || '',
        },
      },
      {
        prop: 'lastMessage',
        label: chatsT('table.headers.lastMessage'),
      },
      {
        prop: 'createdAt',
        label: chatsT('table.headers.createdAt'),
        sortable: true,
        width: 140,
        view: {
          type: 'text',
          formatter: (row: any) => formatDate(row),
        },
      },
    ],
    rowLink: (row) => ({
      name: 'chats-view',
      params: { id: row.id },
      query: { type: row.type.value },
    }),
  }),
});
