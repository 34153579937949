<template>
  <div style="min-width: 160px !important;">
    <el-select
      v-model="model"
      :class="className"
      :disabled="isMe"
      @click.prevent
      @change="onChange"
    >
      <el-option
        v-for="item in items.data"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>
      
<script lang="ts" setup>
import {
  defineProps, PropType, ref, computed, watch, onMounted,
} from 'vue';
    
import { profileId } from 'configs/development/profile';
import type { List } from '../types';
import { updateStatus } from '../requests';
import { AdminStatus } from '~/types/common';
import { adminStatuses } from '~/utils/searchInit';
    
const props = defineProps({
  row: {
    type: Object as PropType<List>,
    required: true,
  },
});
      
const user = ref(await profileId({}));
const model = ref(props.row.status.value);
      
const items = ref(await adminStatuses({}));
      
const isMe = computed(() => user.value.data === props.row.id);
const className = computed(() => {
  if (isMe.value) {
    return '';
  }

  return model.value === AdminStatus.ACTIVE ? 'status-green' : 'status-red';
});
    
const onChange = async (value: AdminStatus) => {
  await updateStatus({ id: props.row.id, data: { status: value } });
};
    
watch(
  () => props.row.status, 
  () => {
    model.value = props.row.status.value;
  },
);
</script>
