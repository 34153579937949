export enum Status {
  active = 'active',
  inactive = 'inactive',
  moderation = 'moderation',
  registration = 'registration',
  declined = 'declined',
  blocked = 'blocked',
  removing_requested = 'removing_requested',
  removed = 'removed',
}
