import { request as magnerRequest, ApiError, magnerNotify } from 'magner';
import refreshRequest from 'features/common/refresh';
import { API_URL, API_VERSION, PROJECT_NAME } from '~/constants';

interface BackendError {
  message: string,
  type: string,
  errors?: {
    field: string,
    message: string,
  }[],
}

const baseUrl = new URL(`${API_VERSION}/admin`, API_URL);
const request = magnerRequest({
  baseUrl: baseUrl.href,

  interceptError: async (e) => {
    const is401Error = e.response?.status === 401;
    const isLoginPage = window.location.pathname.includes('login');

    if (is401Error && !isLoginPage) {
      const magnerData = JSON.parse(localStorage.getItem('magner-petshop') as string);
      const refreshToken = magnerData.refreshToken;

      if (refreshToken) {
        const res = await refreshRequest(baseUrl.href, refreshToken);

        if (res?.data) {
          magnerData.token = res.data.token;
          magnerData.refreshToken = res.data.refreshToken;
          localStorage.setItem('magner-petshop', JSON.stringify(magnerData));

          window.location.reload();
        }
        else {
          localStorage.removeItem('magner-petshop');
          request.api.instance.defaults({ headers: {} }, false);
          window.location.replace('/login');
        }
      }
    }

    if (!is401Error && !isLoginPage) {
      const resErrors = JSON.parse(e?.text ?? '{}');

      const title = resErrors?.result?.errors?.root?.join(', ') || 'Ошибка';

      const fields = resErrors?.result?.errors?.fields || {};
      const additions = fields?.additions || {};
      const errorFields = { ...fields, ...additions };

      if (resErrors?.data?.msg) {
        errorFields.customError = resErrors.data.msg;
      }
      if (resErrors?.result?.message) {
        errorFields.customError = resErrors.result.message;
      }

      if (e.response?.url?.includes('/admin/walking/report') && e.response?.status === 403) {
        return;
      }

      magnerNotify({
        type: 'error',
        title,
        message: Object.values(errorFields).join('<br>') || resErrors?.message || 'Ошибка сервера',
        stringIsHtml: true,
        showClose: true,
        duration: 7500,
      });
    }
  },

  parseError: (err: ApiError<BackendError> | Error) => {
    if (err instanceof ApiError) {
      const fields = (err?.data?.errors || []).reduce((accum, current) => {
        accum[current.field] = current.message;
        return accum;
      }, {} as Record<string, string>);

      return {
        message: err?.data.message,
        fields,
      };
    }

    return {
      message: err.message || 'Ошибка',
      fields: {},
    };
  },

  /**
   * Function parses an object of form { items: 5, filters: { params: [1, 2] } } to the URL of type
   * ?items=5&filters[0][id]=params&filters[0][value]=1&filters[0][value]=2
   */
  dataToUrl: (data) => {
    const params: string[] = [];

    Object.entries(data).forEach(([key, val]) => {
      if (!val) {
        return;
      }

      /** If value is just a string or a number, return like items=15&page=1 */
      if (typeof val === 'string') {
        params.push(`${key}=${val}`);
      }
      else if (typeof val === 'number' || typeof val === 'boolean') {
        params.push(`${key}=${val.toString()}`);
      }
      else if (typeof val === 'object') {
        /**
         * In case of value as an object, make such a get-uri construction:
         * filters[0][id]=clinicId&filters[0][value]=15
         * */
        let index = 0;

        Object.entries(val).forEach(([nestedKey, nestedVal]) => {
          if (!nestedVal) {
            return;
          }

          params.push(`${key}[${index}][id]=${nestedKey}`);
          if (typeof nestedVal === 'string') {
            params.push(`${key}[${index}][value]=${nestedVal}`);
          }
          else if (typeof nestedVal === 'number' || typeof val === 'boolean') {
            params.push(`${key}[${index}][value]=${(nestedVal as number|boolean).toString()}`);
          }
          else if (Array.isArray(nestedVal)) {
            nestedVal.forEach((nestedValue) => {
              params.push(`${key}[${index}][value]=${nestedValue.toString()}`);
            });
          }
          index += 1;
        });
      }
    });

    return `?${params.join('&')}`;
  },

  /**
   * Function parses an URL queries of form { items: "5", filters[0][id]="params", filters[0][value]=[1,2] }
   * made by Vue Router that parses the query string for us. If it sees equal keys, then makes an array of their values.
   * Returns the object of type { items: 5, filters: { params: [1, 2] } }
   */
  urlToData: (query = {}) => {
    const data: Record<string, any> = {};

    /** Vue-Router  */
    Object.entries(query).forEach(([key, value]) => {
      const matched = key.match(/^([^[]+)\[([0-9]+)]\[id]/);
      const isValue = new RegExp(/^[^[]+\[[0-9]+]\[value]/).test(key);
      if (!isValue) {
        if (matched) {
          if (!data[matched[1]]) data[matched[1]] = {};
          const paramValueSearch = `${matched[1]}[${matched[2]}][value]`;
          const paramValue = query[paramValueSearch];
          if (paramValue) {
            data[matched[1]][value] = paramValue;
          }
        }
        else {
          data[key] = value;
        }
      }
    });

    return data;
  },
});

const headers: Record<string, string> = {};

try {
  const ls = JSON.parse(localStorage.getItem(`magner-${PROJECT_NAME}`) || '');
  if (ls.token) {
    headers.Authorization = `Bearer ${ls.token as string}`;
  }
}
catch (_) {
  console.info('No authorization token found for the session');
}
request.api.instance.defaults({ headers }, false);

export { request };
