<template>
  <div v-html="walker" />
</template>

<script lang="ts" setup>
import {
  computed,
  defineProps,
  PropType,
} from 'vue';
import { OrderList } from 'features/orders/types';
import WalkingStatusTag from 'features/orders/components/walking-status-tag.vue';

const props = defineProps({
  row: {
    type: Object as PropType<OrderList>,
    required: true,
  },
});

const walker = computed(() => {
  if (!props.row.walker?.phone) return 'Не назначен';
  const name = props.row.walker?.name;
  const phone = props.row.walker?.phone;
  return `${name ? `${name}<br>` : ''} ${phone ? `${phone}` : ''}`;
});
</script>
