<template>
  <el-checkbox-group
    :model-value="props.modelValue.activities"
    class="orders-report-after-walks"
  >
    <el-checkbox
      v-for="item, i in items"
      :key="i"
      :label="item.value"
      class="orders-report-after-walks__checkbox"
    >
      {{ item.label }}
    </el-checkbox>
  </el-checkbox-group>
</template>

<script lang="ts" setup>
import { defineProps, PropType } from 'vue';

import type { ActivityTypeValue } from '~/features/orders/types';

const props = defineProps({
  modelValue: {
    type: Object as PropType<{ activities: ActivityTypeValue[] }>,
    required: true,
  },
}); 

const items = [
  {
    label: 'Пописали',
    value: 'peed',
  },
  {
    label: 'Покакали',
    value: 'pooped',
  },
  {
    label: 'Поиграли',
    value: 'played',
  },
  {
    label: 'Провели тренировку',
    value: 'worked',
  },
  {
    label: 'Покормили',
    value: 'fed',
  },
  {
    label: 'Помыли',
    value: 'wash',
  },
];
</script>

<style>
.orders-report-after-walks {
  display:flex; 
  flex-direction: column;
}  

.orders-report-after-walks__checkbox {
  pointer-events: none;
}
</style>
