import { request } from '~/utils/request';
import { getListResponse, sortTable, filtersTable } from '~/utils/get-list';
import { Status } from '~/types/common/status';
import { ValueLabel } from '~/types/common';
import type {
  List, Read, Edit, Filters, 
} from './types';
import { deepLinks } from '~/utils/searchInit';

const BASE = '/walking/banner';

const sendData = (data: Edit, isCreate = false) => {
  const result = { ...data };

  result.isActive = !isCreate ? (result.isActive as Status) === Status.active : true;

  result.link = result.transition?.linkText || '';
  result.customPrice = Number(result.transition?.price) || 0;

  result.offerRateId = result.transition?.tariff || '';
  result.offerOptionId = result.transition?.option || null;
  result.asap = result.transition?.asap || false;

  if (Array.isArray(result.imageId) && !result?.imageId?.includes('https://')) {
    result.imageId = result.imageId[0] || null;
  }
  else {
    delete result.imageId;
  }

  if (result.offerRateId) {
    result.link = null;
    delete result.customPrice;
  }

  if (result.link) {
    result.offerRateId = null;
    delete result.offerOptionId;
  }

  delete result.transition;

  return result;
};

export const read = request.table<List>(async ({ data }) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  const sort = sortTable(
    data.sort,
    [
      'title',
      'sort',
      'city',
    ],
  );

  const filters = filtersTable(
    data.filters as Filters, 
    ['title', 'cities', 'links'],
  );

  const result = await getListResponse<List>(`${BASE}?${pagination}${sort}${filters}`, activePage);

  const { data: deppLinksResult } = await deepLinks({});

  return {
    data: {
      ...result.data,
      rows: result.data.rows.map((row) => ({
        ...row,
        link: deppLinksResult?.find((item) => item.value === row.link)?.label || row.link || 'Внутри приложения',
      })),
    },
  };
});

export const get = request.card<Edit, Read>(async ({ api, data, parseError }) => {
  const res = await api.get<{ data: Read }>(`${BASE}/${data.id}`);

  if (res.error) {
    return { error: parseError(res.error) };
  }

  const result: Edit = { 
    ...res.data?.data,
    isActive: res.data.data.isActive ? 'active' : 'inactive',
    imageId: res.data.data.image?.downloadUrl,
    cityId: res.data.data.city?.id,
    offerRateId: res.data.data.rate?.id,
    offerOptionId: res.data.data.option?.id,
    transition: {
      isLink: !res.data.data.offer?.id,
      linkText: res.data.data.link,
      price: res.data.data?.customPrice?.inRubles,
      offer: res.data.data.offer?.id,
      tariff: res.data.data.rate?.id,
      option: res.data.data.option?.id,
      isNoOption: !res.data.data.option?.id,
      asap: res.data.data.asap,
    },
  };

  return {
    data: {
      ...result,
    },
  };
});

export const create = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.post<{ data: Read }>(`${BASE}`, sendData(data.data));

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'services-main' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.patch<{ data: Read }>(
      `${BASE}/${data.id}`,
      sendData(data.data),
    );

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'services-main' });

    return {
      data: res.data.data,
    };
  },
);

export const remove = request.card(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.delete(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    await router.push({ name: 'services-main' });

    return { data: res.data?.data };
  },
);

export const updateActive = request.card<Read, Edit>(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.patch(`${BASE}/${data.id}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data?.data,
    };
  },
);

export const linksList = request.custom<string[], string[]>(async ({ api }) => {
  const res = await api.get(`${BASE}/links-list`);
  const deepLinksList = await deepLinks({});

  return { 
    data: res.data.data.items.filter((item: string) => item).map((item: string) => ({ 
      label: deepLinksList?.data?.find((deep) => deep.value === item)?.label || item,
      value: item, 
    })),
  };
});
