import { cardPageController, actionCard } from 'magner';

import { baseT } from 'configs/translation/base';
import { adsPushT } from './index';
import { Statuses, type Edit } from './types';
import { searchCities, searchBreedAll, deepLinks } from '~/utils/searchInit';
import {
  getEdit, create, update, remove,
} from './requests';

import AdsPushStatusCard from './components/ads-push-status-card.vue';
import AdsPushType from './components/ads-push-type.vue';
import AdsPushTransition from './components/ads-push-transition.vue';
import AdsPushAudience from '~/features/catalog/discounts/components/discounts-audience.vue';
import AdsPushPetAge from '~/features/catalog/discounts/components/discounts-pet-age.vue';
import AdsPushSelector from '~/features/catalog/discounts/components/discounts-selector.vue';

export const cardConfig = cardPageController<Edit>({
  header: {
    title: adsPushT('card.title'),
  },

  getRequest: getEdit,
  updateRequest: update,
  createRequest: create,
  deleteRequest: remove,

  confirmDelete: true,

  form: {
    fullDataOnUpdate: true,
    actions: [
      {
        type: 'link',
        to: (route): any => (route.params.id === 'new'
          ? { name: 'ads-push' }
          : { name: 'ads-push-view', params: { route } }),
        props: {
          text: baseT('form.cancel_button'),
          class: 'width-auto',
        },
      },

      {
        type: 'action',
        emits: 'remove',
        props: {
          type: 'danger',
          class: 'width-auto',
          text: baseT('form.remove_button'),
        },
      },
      {
        type: 'action',
        emits: 'submit',
        props: {
          text: baseT('form.submit_button'),
          type: 'primary',
          class: 'width-auto',
        },
      },
    ],

    layout: {
      type: 'row',
      props: {},
      layout: [
        /** Left column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-right': '12px' },
          },
          layout: [
            {
              type: 'row',
              props: {
                justify: 'space-between',
                elementsGrow: true,
                styles: { gap: '12px', flexWrap: 'nowrap' },
              },
              fields: [
                {
                  type: 'input',
                  name: 'id',
                  dataType: 'string',
                  props: {
                    hidden: true,
                  },
                },
                {
                  type: 'input',
                  name: 'code',
                  label: 'Название служебное',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Введите название',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'blur',
                    },
                  ],
                },
                {
                  type: 'custom',
                  name: 'status',
                  label: 'Статус',
                  component: () => AdsPushStatusCard,
                  props: {
                    readOnly: ({ isNew }) => isNew,
                  },
                  changeAction: ({ form }) => {
                    if (form.status === Statuses.sent) {
                      form.dispatchTime = null;
                    }
                  },
                },
              ],
            },
            {
              type: 'column',
              props: {},
              fields: [
                {
                  type: 'custom',
                  name: 'type',
                  label: 'Отправка',
                  props: {
                    required: true,
                    readOnly: ({ isNew }) => !isNew,
                  },
                  component: () => AdsPushType,
                  changeAction: ({ form }) => {
                    form.dispatchTime = null;
                  },
                },
                {
                  type: 'datetime',
                  name: 'dispatchTime',
                  dataType: 'date',
                  props: {
                    type: 'datetime',
                    format: 'DD.MM.YYYY HH:mm',
                    valueFormat: 'YYYY-MM-DD HH:mm',
                    hidden: ({ state }) => state.type === 'birthday' || state.status === Statuses.sent,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'dispatchTime',
                  dataType: 'string',
                  props: {
                    readOnly: true,
                    readOnlyFormatter: () => '–',
                    hidden: ({ state }) => !(state.type === 'birthday' || state.status === Statuses.sent),
                  },
                },
                
                {
                  type: 'select',
                  name: 'city',
                  options: [],
                  label: 'Город',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Выберите один город',
                    valueKey: 'id',
                    labelKey: 'name',
                    remote: true,
                    remoteMethod: searchCities,
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'custom',
                  name: 'transition',
                  label: 'Переход',
                  props: {
                    required: true,
                  },
                  component: () => AdsPushTransition,
                  changeAction: ({ form }) => {
                    form.link = null;
                  },
                },
                {
                  type: 'input',
                  name: 'link',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Введите ссылку',
                    hidden: ({ state }) => state.transition === 'app',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'select',
                  name: 'link',
                  options: [],
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Выберите услугу или экран для перехода',
                    valueKey: 'value',
                    labelKey: 'label',
                    remote: true,
                    remoteMethod: deepLinks,
                    hidden: ({ state }) => state.transition !== 'app',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'title',
                  label: 'Заголовок',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Введите заголовок, который увидят пользователи',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
                {
                  type: 'input',
                  name: 'text',
                  label: 'Текст',
                  dataType: 'string',
                  props: {
                    required: true,
                    placeholder: 'Введите текст, который увидят пользователи под заголовком',
                  },
                  validation: [
                    {
                      type: 'empty-required' as 'empty',
                      trigger: 'change',
                    },
                  ],
                },
              ],
            },
          ],
        },

        /** Right column */
        {
          type: 'column',
          props: {
            span: 12,
            xs: 24,
            styles: { 'padding-left': '12px' },
          },
          layout: [
            {
              type: 'column',
              title: 'Аудитория',
              props: {
                span: 24,
                isPaper: true,
                titleType: 'heading',
              },
              layout: [
                {
                  type: 'column',
                  props: {},
                  fields: [
                    {
                      type: 'custom',
                      name: 'audience',
                      props: {
                        required: true,
                      },
                      component: () => AdsPushAudience,
                      changeAction: ({ form }) => {
                        if (form.audience !== 'clients') {
                          form.clients = [];

                          return;
                        } 

                        form.breeds = [];
                        form.breedsNone = false;
                        form.petAge = null;
                        form.petAgeNone = false;
                        form.onlyNewClients = false;
                      },
                    },
                    /* Audience segment */
                    // breeds
                    {
                      type: 'select',
                      name: 'breeds',
                      options: [],
                      label: 'Порода питомцев',
                      dataType: 'string',
                      props: {
                        placeholder: 'Выберите один или несколько пород',
                        multiple: true,
                        remote: true,
                        valueKey: 'id',
                        labelKey: 'title',
                        filterable: true,
                        remoteMethod: searchBreedAll,
                        hidden: ({ state }) => state.breedsNone || state.audience !== 'segment',
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                    {
                      type: 'input',
                      name: 'breeds',
                      label: 'Порода питомцев',
                      dataType: 'string',
                      props: {
                        disabled: true,
                        hidden: ({ state }) => !state.breedsNone || state.audience !== 'segment',
                      },
                    },
                    {
                      type: 'switch',
                      name: 'breedsNone',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Любая порода',
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                      changeAction: ({ form }) => {
                        form.breeds = [];
                      },
                    },
                    // age
                    {
                      type: 'custom',
                      name: 'petAge',
                      label: 'Возраст питомцев',
                      props: { 
                        hidden: ({ state }) => state.petAgeNone || state.audience !== 'segment',
                      },
                      component: () => AdsPushPetAge,
                      validation: [
                        {
                          type: 'empty-required-pet-age' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                    {
                      type: 'custom',
                      name: 'petAge',
                      label: 'Возраст питомцев',
                      props: {
                        hidden: ({ state }) => !state.petAgeNone || state.audience !== 'segment',
                        disabled: true,
                      },
                      component: () => AdsPushPetAge,
                    },
                    {
                      type: 'switch',
                      name: 'petAgeNone',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Любой возраст',
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                      changeAction: ({ form }) => {
                        form.petAge = null;
                      },
                    },
                    // is new client
                    {
                      type: 'switch',
                      name: 'onlyNewClients',
                      dataType: 'boolean',
                      props: {
                        activeLabel: 'Заказов еще не было',
                        hidden: ({ state }) => state.audience !== 'segment',
                      },
                    },

                    /* Clients segment */
                    {
                      type: 'custom',
                      name: 'clients',
                      component: () => AdsPushSelector,
                      props: {
                        hidden: ({ state }) => state.audience !== 'clients',
                      },
                      validation: [
                        {
                          type: 'empty-required' as 'empty',
                          trigger: 'change',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
});
