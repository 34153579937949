import type { OrderBacked, OrderCreateToBackend, OrderFrontend } from 'features/orders/types';
import { OrderUpdateToBackend } from 'features/orders/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PetCreateDto, WalkingStatuses } from '~/types/common';
import { filterRequestStrings } from '~/utils/filter-request-strings';
import { roundToTwoSigns } from '~/utils/date-transform';
import { walkingCardStatus } from '~/utils/walking-card-status';
import { init } from '~/utils/get-init';
import { getAddressData } from '~/utils/searchInit';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault('UTC');

// 1) Преобразования данных заказа с бэка на фронт в нужном виде
export const orderBackendToFrontend = (data: OrderBacked): OrderFrontend => {
  /**
   * временные метки приходят с бэка в UTC. Надо их конвертнуть в таймзону браузера
   */
  const startDateTimeDayJs = dayjs.tz(data.startTime, 'UTC').tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm:ss');
  const startDateTime = new Date(startDateTimeDayJs);

  // if (data.city.minutesOffset) {
  //   startDateTime.setMinutes(startDateTime.getMinutes() + data.city.minutesOffset);
  // }

  return {
    ...data,
    // DATE, TIME
    startTime: `${startDateTime.getFullYear()}-${roundToTwoSigns(
      startDateTime.getMonth() + 1,
    )}-${roundToTwoSigns(
      startDateTime.getDate(),
    )}T${startDateTime.toLocaleTimeString('ru-RU')}`,
    time: startDateTime,
    // Asap,
    asap: data.asap ? 1 : 2,
    // USER
    user: {
      type: data.user?.id ? 'existing' : 'new',
      existingData: data,
      existingEntity: data.user?.id ? data.user : null,
      existingId: data.user?.id,
      isManual: false,
    },
    userName: data.user?.name,
    userSurname: data.user?.surname,
    userPhone: data.user?.phone,
    userCity: data.userAddress?.city?.name,
    userDistrict: data.userAddress?.district?.slug,
    userStreet: data.userAddress?.street,
    userHouse: data.userAddress?.house,
    userEntrance: data.userAddress?.entrance,
    userFloor: data.userAddress?.floor,
    userOffice: data.userAddress?.office,
    userDoorCode: data.userAddress?.doorCode,
    userAddressComment: data.userAddress?.comment,

    // WALKER
    walker: {
      type: data.walker?.id ? 'existing' : 'new',
      existingData: data,
      existingEntity: data.walker?.id ? data.walker : null,
      existingId: data.walker?.id,
      isManual: false,
    },
    walkerSurname: data.walker?.surname,
    walkerPhone: data.walker?.phone,
    walkerName: data.walker?.name,

    // CONTACT
    contact: {
      type: data.userContact?.id ? 'existing' : 'new',
      existingData: data,
      existingEntity: data.userContact?.id ? data.userContact : null,
      existingId: data.userContact?.id,
      isManual: false,
    },
    contactName: data.userContact?.name,
    contactSurname: data.userContact?.surname,
    contactPhone: data.userContact?.phone,

    // ADDRESS
    addressExisting: {
      type: data.userAddress?.id ? 'existing' : 'new',
      existingId: data.userAddress?.id,
    },
    city: data.city?.id,
    district: '',
    street: '',
    house: '',
    entrance: '',
    floor: '',
    office: '',
    doorCode: '',
    addressComment: '',

    walkingType: {
      offer: data.offer as any,
      offerTitle: data.offer.title as any,
      type: data.offer?.offerRate?.id,
      typeTime: data.offer?.offerRate?.time,
      addition: data.offer?.offerOptions.map((option) => option.id),
      additionData: data.offer?.offerOptions.map((item) => ({
        ...(item as any),
        price: item.price.inRubles,
        walkerFee: item.walkerFee.inRubles,
      })),
      additionTitles: data.offer.offerOptions.map((item) => item.title),
      typeData: {
        ...(data.offer?.offerRate as any),
        price: data.offer?.offerRate.price.inRubles,
        walkerFee: data.offer?.offerRate.walkerFee.inRubles,
      },
      userComment: data.userComment,
      manualDiscount: data.manualDiscount,
      offerType: data.offer?.offerType?.id,
      offerTypeTitle: data.offer?.offerType?.title,
      offerReadonly: data.offer,
    },

    // PETS
    pets: data.pets.map((petItem) => ({
      ...petItem,
      petType: petItem.petType?.value || 'dog',
    })),
    petsEdit: data.pets.map((petItem) => ({
      pet: {
        type: petItem.id ? 'existing' : 'new',
        existingId: petItem.id,
      },
    })),

    rating: {
      rating: data.rating,
      comment: data.ratingComment,
      features: data.features,
    },
    status: data.status.value,
  };
};

// 2) Преобразования данных для бэка при СОЗДАНИИ заказа
export const formToBackendCreateOrderAdapter = async (form: OrderFrontend): OrderCreateToBackend => {
  filterRequestStrings(form);

  const status = walkingCardStatus.value;

  const activeCity = init.get().cities.find((cityItem) => cityItem.id === form.city);
  const startDate = new Date(form.startTime);
  const walkTime = new Date(form.time);
  const concatenatedTime = new Date(`${startDate.getFullYear()}-${roundToTwoSigns(startDate.getMonth() + 1)}-${roundToTwoSigns(startDate.getDate())}T${walkTime.toLocaleTimeString('ru-RU')}`);

  const bodyStartTime = new Date(dayjs.tz(concatenatedTime, dayjs.tz.guess()).tz('UTC').format('YYYY-MM-DDTHH:mm:ss'));

  // if (activeCity?.minutesOffset) {
  //   bodyStartTime.setMinutes(bodyStartTime.getMinutes() - activeCity.minutesOffset);
  // }

  const convertDate = `${bodyStartTime.getFullYear()}-${roundToTwoSigns(bodyStartTime.getMonth() + 1)}-${roundToTwoSigns(bodyStartTime.getDate())}T${bodyStartTime.toLocaleTimeString('ru-RU')}`;

  const userId = form.user?.type === 'existing' ? form.user.existingId : null;
  const user = form.user?.type === 'new' ? form.user.newEntity : null;

  const walkerId = form.walker?.type === 'existing' ? form.walker.existingId : null;
  const walker = form.walker?.type === 'new' ? form.walker.newEntity : null;

  const userContactId = form.contact?.type === 'existing' ? form.contact.existingId : null;
  const userContact = form.contact?.type === 'new' ? form.contact.newEntity : null;

  const addressId = form.addressExisting?.type === 'existing' ? form.addressExisting.existingId : null;
  const addressCoords = (!form.addressExisting?.type || form.addressExisting?.type === 'new') ? await getAddressData({ form }) : null;
  const address = (!form.addressExisting?.type || form.addressExisting?.type === 'new') ? {
    id: null,
    city: form.city,
    district: form.district,
    street: form.street,
    house: form.house,
    entrance: form.entrance,
    doorCode: form.doorCode,
    floor: form.floor,
    office: form.office,
    comment: form.addressComment,
    latitude: addressCoords?.data?.geo_lat,
    longitude: addressCoords?.data?.geo_lon,
  } : null;

  const { petIds, pets } = form.petsEdit?.reduce?.((accum, current) => {
    if (current.pet.type === 'new' && current.pet.newEntity) {
      filterRequestStrings(current.pet.newEntity);
      accum.pets.push(current.pet.newEntity);
    }
    else {
      accum.petIds.push(current.pet.existingId);
    }
    return accum;
  }, { petIds: [] as string[], pets: [] as PetCreateDto[] });

  const sendData = {
    externalId: form.externalId || null,
    status,
    startTime: convertDate,
    asap: form.asap === 1,
    source: form.source,
    paymentType: form.paymentType?.value ?? form.paymentType,
    notificationType: form.notificationType?.value ?? form.notificationType,
    offerRate: form.walkingType?.type || null,
    offerOptions: form.walkingType?.addition || [],
    manualDiscount: form.walkingType?.manualDiscount,
    userComment: form.walkingType.userComment || null,
    offer: form.walkingType.offer,

    userId,
    user,

    walkerId,

    userContact,
    userContactId,

    petIds,
    pets,

    addressId,
    address,
  };

  if (form.walkingType.offerType) {
    sendData.offerType = form.walkingType.offerType;
  }

  if (walker && Object.values(walker).some((item: string) => item)) {
    sendData.walker = walker;
  }

  return sendData;
};

export const formToBackendUpdateOrderAdapter = async (form: OrderFrontend)
  : Partial<OrderUpdateToBackend> => {
  filterRequestStrings(form);
  const status = walkingCardStatus.value;

  const activeCity = init.get().cities.find((cityItem) => cityItem.id === form.city);
  const startDate = new Date(form.startTime);
  const walkTime = new Date(form.time);
  const concatenatedTime = new Date(`${startDate.getFullYear()}-${roundToTwoSigns(startDate.getMonth() + 1)}-${roundToTwoSigns(startDate.getDate())}T${walkTime.toLocaleTimeString('ru-RU')}`);

  const bodyStartTime = new Date(dayjs.tz(concatenatedTime, dayjs.tz.guess()).tz('UTC').format('YYYY-MM-DDTHH:mm:ss'));

  // if (activeCity?.minutesOffset) {
  //   bodyStartTime.setMinutes(bodyStartTime.getMinutes() - activeCity.minutesOffset);
  // }

  const convertDate = `${bodyStartTime.getFullYear()}-${roundToTwoSigns(bodyStartTime.getMonth() + 1)}-${roundToTwoSigns(bodyStartTime.getDate())}T${bodyStartTime.toLocaleTimeString('ru-RU')}`;

  const { newPets, pets } = form.petsEdit?.reduce?.((accum, current) => {
    if (current.pet.type === 'new' && current.pet.newEntity) {
      filterRequestStrings(current.pet.newEntity);
      accum.newPets.push(current.pet.newEntity);
    }
    else {
      accum.pets.push(current.pet.existingId);
    }
    return accum;
  }, { pets: [] as string[], newPets: [] as PetCreateDto[] });

  const walker = form.walker?.existingId || null;

  const userContact = form.contact?.type === 'existing' ? form.contact.existingId : null;
  const newUserContact = form.contact?.type === 'new' ? form.contact.newEntity : null;

  const address = form.addressExisting?.type === 'existing' ? form.addressExisting.existingId : null;
  const addressCoords = form.addressExisting?.type === 'new' ? await getAddressData({ form }) : null;

  const newAddress = form.addressExisting?.type === 'new' ? {
    city: form.city,
    district: form.district,
    street: form.street,
    house: form.house,
    entrance: form.entrance,
    doorCode: form.doorCode,
    floor: form.floor,
    office: form.office,
    comment: form.addressComment,
    latitude: addressCoords?.data?.geo_lat,
    longitude: addressCoords?.data?.geo_lon,
  } : null;

  const body: Partial<OrderUpdateToBackend> = {
    status,
  };

  // Ожидаем оплату
  if (status === WalkingStatuses.waitingPayment) {
    body.startTime = convertDate;
    body.asap = form.asap === 1;
    body.city = form.city;
    body.paymentType = form.paymentType?.value ?? form.paymentType;
    body.notificationType = form.notificationType?.value ?? form.notificationType,
    body.externalId = form.externalId;

    body.offerRate = form.walkingType?.type;
    body.offerOptions = form.walkingType?.addition || [];
    body.offer = form.walkingType.offer;

    body.manualDiscount = form.walkingType?.manualDiscount;

    body.userComment = form.walkingType?.userComment || null;

    body.newPets = newPets;
    body.pets = pets;

    if (walker) body.walker = walker;

    if (address) body.address = address;
    if (newAddress) body.newAddress = newAddress;

    if (userContact) body.userContact = userContact;
    if (newUserContact) body.newUserContact = newUserContact;

    if (form.walkingType.offerType) {
      body.offerType = form.walkingType.offerType;
    }
  }

  // Статусы "Ищем выгульщика" или "Готовы к прогулке"
  if (status === WalkingStatuses.lookingWalker || status === WalkingStatuses.readyWalking) {
    body.startTime = convertDate;
    body.asap = form.asap === 1;
    body.notificationType = form.notificationType?.value ?? form.notificationType;
    body.externalId = form.externalId;

    body.newPets = newPets;
    body.pets = pets;

    if (walker) body.walker = walker;

    if (userContact) body.userContact = userContact;
    if (newUserContact) body.newUserContact = newUserContact;

    if (address) body.address = address;
    if (newAddress) body.newAddress = newAddress;
  }

  // Статус "Гуляем"
  if (status === WalkingStatuses.walking) {
    if (walker) body.walker = walker;
  }

  return body;
};
