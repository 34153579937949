import { request } from '~/utils/request';
import { getListResponse, sortTable, filtersTable } from '~/utils/get-list';
import { Status } from '~/types/common/status';
import type {
  List, Filters, Read, Edit, UpdateActive, ReadTransformed,
} from './types';
import { toDateISOString, toDateLocaleTimeZone } from '~/utils/date-transform';

const BASE = '/discount';

const sendDataEdit = (data: Edit, isCreate = false) => {
  const sendData = { ...data };

  sendData.active = !isCreate ? (sendData.active as Status) === Status.active : true;

  if (sendData.validPeriod === 'period') {
    sendData.startTime = toDateISOString(sendData.validPeriodDate[0]);
    sendData.endTime = toDateISOString(sendData.validPeriodDate[1]);
  }

  if (sendData.objectData.isAll) {
    sendData.object = 'check';
    sendData.offerOptions = [];
    sendData.offerRates = [];
  }
  else {
    sendData.offer = sendData.objectData.offer;

    if (!sendData.objectData.option.length && !sendData.objectData.tariff.length) {
      sendData.object = 'offer';
    }

    if (sendData.objectData.option.length) {
      sendData.offerOptions = sendData.objectData.option;
      sendData.object = 'options';
    }

    if (sendData.objectData.tariff.length) {
      sendData.offerRates = sendData.objectData.tariff;
      sendData.object = 'rates';
    }
  }

  sendData.minPetAge = sendData.petAge?.min || 0;
  sendData.maxPetAge = sendData.petAge?.max || 0;

  if (sendData.city?.id) {
    sendData.city = sendData.city.id;
  }

  sendData.minPrice = sendData.minPrice || null;
  sendData.maxPrice = sendData.maxPrice || null;
  sendData.maxApplyCount = sendData.maxApplyCount || null;
  /* removed helpers */
  delete sendData.validPeriodDate;
  delete sendData.promoType;
  delete sendData.objectData;

  delete sendData.needMinPrice;
  delete sendData.needMaxPrice;
  delete sendData.needMaxApplyCount;
  delete sendData.breedsNone;
  delete sendData.petAge;
  delete sendData.petAgeNone;

  return sendData;
};

const getDataRead = (data: Read) => {
  const readData: ReadTransformed = {
    id: data.id,
    code: data.code,
    caption: data.caption,
    city: data.city,
    validPeriod: data.validPeriod.value,
    value: data.value,
    type: data.type?.value,
    compatibility: data.compatibility?.value || data.compatibility,
    audience: data.audience.value,
    promo: data.promo,
    promoType: data.promo ? 'promo' : 'app',
    minPrice: data.minPrice?.inRubles,
    maxPrice: data.maxPrice?.inRubles,
    needMinPrice: Boolean(!data.minPrice?.inRubles),
    needMaxPrice: Boolean(!data.maxPrice?.inRubles),
    maxApplyCount: data.maxApplyCount,
    needMaxApplyCount: Boolean(!data.maxApplyCount),
    breeds: data.breeds.map((item: any) => item.id),
    breedsNone: Boolean(!data.breeds.length),
    petAgeNone: Boolean(!data.minPetAge && !data.maxPetAge),
    active: data.active ? 'active' : 'inactive',
    onlyNewClients: data.onlyNewClients,
    object: data.object.value,
    createdAt: data.createdAt,
    clients: data.clients,
    objectData: {
      isAll: data.object.value === 'check',
      isAllOffer: data.object.value === 'offer',
      offer: data.offer?.id,
      option: data.offerOptions?.map((item) => item.id),
      tariff: data.offerRates?.map((item) => item.id),
    },
  };

  if (data.validPeriod?.value === 'period') {
    readData.validPeriodDate = [toDateLocaleTimeZone(data.startTime), toDateLocaleTimeZone(data.endTime)];
  }

  readData.petAge = data.minPetAge >= 0 && data.maxPetAge ? { min: data.minPetAge, max: data.maxPetAge } : null;

  return readData;
};

export const read = request.table<List>(async ({ data } : any) => {
  const activePage = data.pagination?.page || 1;
  const pagination = `limit=${data.pagination.items || 25}&page=${activePage}`;

  /* Filters */
  const filtersData = data.filters;

  if (filtersData.phone) {
    filtersData.phone = filtersData.phone.replace(/\D/g, '');
  }

  const filters = filtersTable(
    filtersData as Filters, 
    ['city', 'promo', 'object', 'audience', 'breed', 'phone', 'active'],
  );

  /* Sort */
  const sortData = data.sort;

  if (sortData.cityCaption) {
    sortData.city = sortData.cityCaption;
    delete sortData.cityCaption;
  }

  if (sortData.validPeriodCaption) {
    sortData.validPeriod = sortData.validPeriodCaption;
    delete sortData.validPeriodCaption;
  }

  if (sortData.petAge) {
    sortData.minPetAge = sortData.petAge;
    delete sortData.petAge;
  }

  const sort = sortTable(
    sortData,
    [
      'code',
      'caption',
      'active',
      'city',
      'validPeriod',
      'object',
      'value',
      'promo',
      'minPrice',
      'maxPrice',
      'maxApplyCount',
      'compatibility',
      'onlyNewClients',
      'minPetAge',
    ],
  );

  return getListResponse<List>(
    `${BASE}?${pagination}${filters}${sort}`,
    activePage,
  );
});

export const get = request.card<ReadTransformed, ReadTransformed>(
  async ({ api, data, parseError }) => {
    const res = await api.get<{ data: Read }>(`${BASE}/${data.id}`);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: getDataRead(res.data?.data),
    };
  },
);

export const create = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.post<{ data: Read }>(`${BASE}`, sendDataEdit(data.data));
        
    if (res.error) {
      return { error: parseError(res.error) };
    }
        
    await router.push({ name: 'discounts' });

    return {
      data: res.data.data,
    };
  },
);

export const update = request.card<Read, Edit>(
  async ({
    api, data, parseError, router, 
  }) => {
    const res = await api.patch<{ data: Read }>(`${BASE}/${data.id}`, sendDataEdit(data.data));

    if (res.error) {
      return { error: parseError(res.error) };
    }
        
    await router.push({ name: 'discounts' });

    return {
      data: res.data.data,
    };
  },
);

export const updateActive = request.card<Read, UpdateActive>(
  async ({
    api, data, parseError,
  }) => {
    const res = await api.patch(`${BASE}/${data.id}`, data.data);

    if (res.error) {
      return { error: parseError(res.error) };
    }

    return {
      data: res.data?.data,
    };
  },
);

export const remove = request.card(async (
  {
    api,
    data,
    parseError,
    router,
  },
) => {
  const res = await api.delete(`${BASE}/${data.id}`);
  
  if (res.error) {
    return { error: parseError(res.error) };
  }

  await router.push({ name: 'discounts' });

  return { data: res.data?.data };
});
