import { ft } from '~/utils/feature-translate';

export const WalkersT = {
  sidebar: {
    table_title: 'Выгульщики',
    card_title: 'Выгульщик',
  },
  table: {
    title: 'Выгульщики',
    not_found: 'Выгульщики не найдены',
    remove_many: 'Удалить выбранное',
    addNew: 'Добавить',
    headers: {
      id: 'ID',
      name: 'Имя',
      surname: 'Фамилия',
      city: 'Город',
      email: 'E-mail',
      phone: 'Телефон',
      status: 'Статус',
    },
  },
  filters: {
    id: 'id',
    name: 'Имя или фамилия',
    surname: 'Фамилия',
    phone: 'Телефон',
    status: 'Статус',
    email: 'Email',
    telegram: 'Телеграм',
  },
  form: {
    title: 'Новый выгульщик',
    fields: {
      id: 'ID',
      phone: {
        title: 'Телефон',
        placeholder: 'Телефон',
      },
      name: {
        title: 'Имя',
        placeholder: 'Введите имя',
      },
      rating: 'Рейтинг',
      surname: {
        title: 'Фамилия',
        placeholder: 'Введите фамилию',
      },
      status: 'Статус',
      roles: 'Тип аккаунта',
      email: {
        title: 'E-mail',
        placeholder: 'Введите E-mail',
      },
      birthdate: {
        title: 'Дата рождения',
        placeholder: '...',
      },
      city: {
        title: 'Город',
        placeholder: 'Выбрать',
      },
      telegram: {
        title: 'Telegram',
        placeholder: 'Введите значение',
      },
      vk: {
        title: 'Vkontakte',
        placeholder: 'vk.com/...',
      },
      pets: 'Питомцы',
      offers: 'Услуги',
      schedule: 'График работы',
      working_areas: 'Рабочие зоны',
      photo: 'Фотография',
      about: 'Рассказ о себе',
      working_schedule: 'Время работы',
      motivation: {
        title: 'Почему появилось желание стать выгульщиком',
        placeholder: 'Напишите что-нибудь воодушевляющее',
      },
      experience: {
        title: 'Опыт работы',
        placeholder: 'Прогулки и догситтинг',
      },
      pets_walker: {
        title: 'Питомцы выгульщика',
        placeholder: 'Есть ли собственные хвостики?',
      },
      interests: {
        title: 'Интересы',
        placeholder: 'Напишите что-нибудь воодушевляющее',
      },
      video_link: {
        title: 'Ссылка на видео о себе',
        placeholder: 'http....',
      },
    },
    pets: {
      title: 'Питомцы',
      fields: {
        name: 'Имя',
        breed: 'Порода',
        age: 'Возраст',
        weight: 'Вес',
        gender: 'Пол',
        peculiarities: 'Особенности',
        comment: 'Комментарий',
      },
    },
    address: {
      title: 'Адреса',
      fields: {
        city: 'Город',
        district: 'Район',
        street: 'Улица',
        house: 'Дом',
        porch: 'Подъезд',
        floor: 'Этаж',
        flat: 'Квартира/офис',
        intercom: 'Домофон',
        comment: 'Комментарий',
      },
    },
    contacts: {
      title: 'Контакты',
      fields: {
        phone: 'Телефон',
        firstname: 'Имя',
        lastname: 'Фамилия',
      },
    },
    status: {
      active: 'Активный',
      inactive: 'Неактивный',
    },
  },
};

export const t = ft(WalkersT, 'users.walkers.');
