<template>
  <div v-if="props.modelValue.isManual" class="flex flex-col w-full pt-10 walker-manual-table">
    <div class="flex items-center mb-10">
      <h2 class="custom-label w-auto">Подходящие выгульщики</h2>

      <div>
        <el-button
          type="primary"
          plain
          class="mb-0 ml-4"
          :loading="isLoading"
          @click="updateTable"
        >
          Обновить список
        </el-button>
      </div>
    </div>  

    <el-table :data="rows.data" style="width: 100%">
      <el-table-column prop="name" label="Имя" />
      <el-table-column prop="surname" label="Фамилия" />
      <el-table-column prop="rating" label="Рейтинг" />
      <el-table-column prop="phone" label="Телефон" />

      <el-table-column prop="telegram" label="Телеграм">
        <template #default="{ row }">
          @{{ row.telegram }}
        </template>
      </el-table-column>

      <el-table-column align="right">
        <template #default="scope">
          <el-button type="success" @click="updateExisting(scope?.row?.id)">
            Назначить на заказ
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>  
</template>
    
<script lang="ts" setup>
import {
  defineProps,
  defineEmits,
  PropType,
  reactive,
  ref,
} from 'vue';
import { walkerGet } from 'features/users/walkers';
import { useRoute } from 'vue-router';
import { selectWalkers } from '../requests';
import { DictionaryItem } from '~/types';
  
const props = defineProps({
  form: {
    type: Object as PropType<Record<string, any>>,
    required: true,
  },
  field: {
    type: Object as PropType<{
      disabled: boolean,
      readOnly: any,
    }>,
    required: true,
  },
  modelValue: {
    type: [String, Number, Array, Object] as PropType<DictionaryItem>,
    default: null,
  },
});
  
const emit = defineEmits(['update:modelValue']);

const route = useRoute();

const rows = ref(await selectWalkers({ id: route.params.id }));
const isLoading = ref(false);

const user = reactive(props.modelValue);

const updateValue = () => {
  emit('update:modelValue', user);
};

const updateExisting = async (id: string) => {
  const fullUser = await walkerGet({ id, isNew: false, data: {} as any });
  
  if (fullUser?.error) {
    user.existingEntity = null;
    emit('update:modelValue', user);
    return;
  }
  
  if (Array.isArray(fullUser?.data)) {
    user.existingEntity = fullUser?.data.find((user) => user.id === id);
  }
  else {
    user.existingEntity = fullUser?.data;
  }

  user.existingId = id;
  
  updateValue();
};

const updateTable = async () => {
  isLoading.value = true;
  rows.value = await selectWalkers({ id: route.params.id });
  isLoading.value = false;
};
</script>
